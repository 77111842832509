import React, { useState } from 'react'
import { Hub } from 'aws-amplify'
import { Card, Button, Backdrop, CircularProgress } from '@mui/material'

export interface ILoginForm {
  handleLogin: () => Promise<void>
}

// eslint-disable-next-line func-style
const LoginForm: React.FC<ILoginForm> = (props) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  function updateProcessing(processing: boolean) {
    setIsProcessing(processing)
  }

  Hub.listen('auth', ({ payload: { event } }) => {
    updateProcessing(true)
    if (event === 'cognitoHostedUI') {
      updateProcessing(false)
    }
  })

  async function handleLogin() {
    updateProcessing(true)
    await props.handleLogin()
  }

  return (
    <>
      <Backdrop open={isProcessing}>
        <CircularProgress />
      </Backdrop>
      <Card className='uva-auth-card'>
        <p>Please sign in with your UV Angel email</p>
        <Button onClick={handleLogin} variant='contained'>
          Sign In
        </Button>
      </Card>
    </>
  )
}

export default LoginForm
