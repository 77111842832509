/* eslint no-magic-numbers: 0 */
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { differenceInSeconds, subSeconds } from 'date-fns'
import React, { useEffect } from 'react'
import { AppContext } from '../../../../app/App.context'
import { RoomPicker } from '../../../../components/room-picker/room-picker.component'
import { PathogenPicker } from '../../../../components/pathogen-picker/pathogen-picker.component'
import { UvaActivity, UvaRoom } from '../../../../services/api.models'

export interface LabReportFormProps {
  rooms: UvaRoom[]
  triggerReport: (
    reportData: {
      roomId: string
      activityId: string
      deviceIds?: string[]
      remediationDevices?: number
      remediationDevicesCadrPerDevice?: number
      algorithmsToCompute?: string[]
      interval: number
      startTime: Date
      endTime: Date
      pathogenId: string
      cadrVentAch: number
      maxQuantaDensity: number
      infectionRate: number
      isCustomInterval: boolean
    } | null,
  ) => void
  error?: string
  processing?: boolean
}

interface uva_report_period {
  id: number
  label: string
  seconds: number
}

const ONE_MINUTE_IN_SECONDS = 60
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS * 60
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS * 24
const ONE_WEEK_IN_SECONDS = ONE_DAY_IN_SECONDS * 7

const DEFAULT_CADR_PER_DEVICE = 50

const CUSTOM_REPORT_PERIOD_ID = 100

const REPORT_PERIODS: uva_report_period[] = [
  {
    id: 1,
    label: 'Last week',
    seconds: ONE_WEEK_IN_SECONDS,
  },
  {
    id: 2,
    label: 'Last 3 days',
    seconds: 3 * ONE_DAY_IN_SECONDS,
  },
  {
    id: 3,
    label: 'Last 24 hours',
    seconds: ONE_DAY_IN_SECONDS,
  },
  {
    id: 4,
    label: 'Last 12 hours',
    seconds: ONE_HOUR_IN_SECONDS * 12,
  },
  {
    id: 5,
    label: 'Last 6 hours',
    seconds: ONE_HOUR_IN_SECONDS * 6,
  },
  {
    id: 6,
    label: 'Last 3 hours',
    seconds: ONE_HOUR_IN_SECONDS * 3,
  },
  {
    id: 7,
    label: 'Last 1 hour',
    seconds: ONE_HOUR_IN_SECONDS,
  },
  {
    id: 8,
    label: 'Last 30 minutes',
    // eslint-disable-next-line no-magic-numbers
    seconds: ONE_MINUTE_IN_SECONDS * 30,
  },
  {
    id: CUSTOM_REPORT_PERIOD_ID,
    label: 'Custom',
    seconds: 0,
  },
]

interface UvaDevice {
  id: string
  deviceType: 'Air 1.75' | 'Air 2.0'
  serialNumber: string
}

const devicesPerRoom: Record<string, UvaDevice[]> = {}
const possibleAlgorithmsToCompute: string[] = ['pssq_occupancy', 'pssq_co2', 'pssq_co2+db']

// eslint-disable-next-line func-style
export const LabReportForm: React.FC<LabReportFormProps> = ({processing, error, triggerReport, rooms}) => {
  const appContext = React.useContext(AppContext)
  const [state, setState] = React.useState<{
    roomId: string
    activityId: string
    pathogenId: string
    deviceIds: string[]
    remediationDevices?: number
    remediationDevicesCadrPerDevice?: number
    algorithmsToCompute: string[]
    calculationInterval: number | null
    cadrVentAch: string
    period: number | null
    startTime: Date | null
    endTime: Date | null
    maxQuantaDensity: string
    infectionRate: string
    error: string
  }>({
    roomId: '',
    activityId: '',
    pathogenId: '',
    deviceIds: [],
    remediationDevices: 0,
    remediationDevicesCadrPerDevice: DEFAULT_CADR_PER_DEVICE,
    algorithmsToCompute: [],
    cadrVentAch: '9',
    calculationInterval: 60,
    period: 4,
    startTime: null,
    endTime: null,
    maxQuantaDensity: '0.004',
    infectionRate: '100',
    error: '',
  })

  useEffect(() => {
    // copy error into state to be able to clear it by pressing Cancel button
    if (error) {
      setState({ ...state, error: error })
    }
  }, [error])

  function onCancel() {
    setState({
      roomId: '',
      activityId: '',
      pathogenId: '',
      deviceIds: [],
      algorithmsToCompute: [],
      cadrVentAch: '',
      calculationInterval: null,
      period: null,
      startTime: null,
      endTime: null,
      maxQuantaDensity: '',
      infectionRate: '',
      error: '',
    })
    triggerReport(null)
  }

  function onRun() {
    if (!state.roomId) {
      setState({ ...state, error: 'Select room' })
      return
    }

    if (!state.activityId) {
      setState({ ...state, error: 'Select room to inherently select activity' })
      return
    }

    if (!state.pathogenId) {
      setState({ ...state, error: 'Select pathogen' })
      return
    }

    if (!state.cadrVentAch) {
      setState({ ...state, error: 'Select CADR - Ventilation' })
      return
    }

    if (!state.maxQuantaDensity) {
      setState({ ...state, error: 'Provide value for maximum quanta density' })
      return
    }

    if (!state.infectionRate) {
      setState({ ...state, error: 'Provide value for infection rate' })
      return
    }

    if (!state.period) {
      setState({ ...state, error: 'Select report period' })
      return
    }

    if (state.calculationInterval === null) {
      setState({ ...state, error: 'Select calculation interval' })
      return
    }

    if (state.period === CUSTOM_REPORT_PERIOD_ID && !state.startTime) {
      setState({ ...state, error: 'Start time is invalid' })
      return
    }

    if (state.period === CUSTOM_REPORT_PERIOD_ID && !state.endTime) {
      setState({ ...state, error: 'End time is invalid' })
      return
    }

    setState({ ...state, error: '' })

    const selectedPeriod = REPORT_PERIODS.find((p) => p.id === state.period) ?? REPORT_PERIODS[0]

    triggerReport({
      roomId: state.roomId,
      activityId: state.activityId,
      interval: +state.calculationInterval,
      deviceIds: state.deviceIds,
      remediationDevices: state.remediationDevices,
      remediationDevicesCadrPerDevice: state.remediationDevicesCadrPerDevice,
      algorithmsToCompute: state.algorithmsToCompute,
      cadrVentAch: +state.cadrVentAch,
      startTime:
        state.period === CUSTOM_REPORT_PERIOD_ID && state.startTime
          ? state.startTime
          : subSeconds(new Date(), selectedPeriod?.seconds),
      endTime:
        state.period === CUSTOM_REPORT_PERIOD_ID && state.endTime ? state.endTime : new Date(),
      pathogenId: state.pathogenId,
      maxQuantaDensity: +state.maxQuantaDensity,
      infectionRate: +state.infectionRate,
      isCustomInterval: state.period === CUSTOM_REPORT_PERIOD_ID,
    })
  }

  function handleMaxQuantaDensityChange(value: string) {
    const maxQuanta = +value

    if (Number.isNaN(maxQuanta) || maxQuanta < 0) {
      setState({
        ...state,
        error: 'Invalid maximum quanta density value',
      })
      return
    }

    setState({
      ...state,
      maxQuantaDensity: value,
      error: '',
    })
  }

  function handleCadrVentChange(value: string) {
    const cadrVentAchValue = +value

    if (Number.isNaN(cadrVentAchValue) || cadrVentAchValue < 0) {
      setState({
        ...state,
        error: 'Invalid Ventilation CADR',
      })
      return
    }

    setState({
      ...state,
      cadrVentAch: value,
      error: '',
    })
  }

  function handleInfectionRateChange(value: string) {
    const ir = +value

    if (Number.isNaN(ir) || ir < 0 || ir > 100) {
      setState({
        ...state,
        error: 'Invalid infection rate value',
      })
      return
    }

    setState({
      ...state,
      infectionRate: value,
      error: '',
    })
  }

  function onRoomChange(roomId: string) {
    const room = rooms.find((r) => r.id === roomId)
    devicesPerRoom[roomId] =
      room?.deviceSerialNumbers?.map(
        (d) =>
          ({
            id: d,
            serialNumber: d,
          } as UvaDevice),
      ) || []
    const updatedState = {
      ...state,
      roomId,
      deviceIds: room?.deviceSerialNumbers || [],
      remediationDevices: room?.deviceSerialNumbers?.length || 0,
      remediationDevicesCadrPerDevice: DEFAULT_CADR_PER_DEVICE,
    }
    if (!state.activityId) {
      console.log('setting default activity for room since not already set')
      const defaultActivity = appContext.activities.find((a) => a.id === room?.typicalActivity)
      updatedState.activityId = defaultActivity?.id || ''
    }
    setState(updatedState)
  }

  function onActivityChange(event) {
    const activityId = event.target?.value
    // const activity = appContext.activities.find((a) => a.id === activityId)
    setState({
      ...state,
      activityId,
    })
  }

  function onPeriodChange(newValue: number) {
    const period = REPORT_PERIODS.find((p) => p.id === newValue)
    const clearCalculationInterval =
      state.calculationInterval && period && state.calculationInterval > period.seconds
    setState({
      ...state,
      period: newValue,
      calculationInterval: clearCalculationInterval ? null : state.calculationInterval,
    })
  }

  let selectedPeriodSeconds: number = Number.MAX_SAFE_INTEGER
  if (state.period === CUSTOM_REPORT_PERIOD_ID) {
    if (state.startTime && state.endTime) {
      selectedPeriodSeconds = differenceInSeconds(state.endTime, state.startTime)
    }
  } else {
    selectedPeriodSeconds =
      REPORT_PERIODS.find((p) => p.id === state.period)?.seconds ?? Number.MAX_SAFE_INTEGER
  }

  return (
    <Card className='uva-form-card'>
      <CardHeader title='Configuration Settings'></CardHeader>
      <CardContent>
        <FormControl variant='filled' fullWidth className='uva-form-control'>
          <RoomPicker value={state.roomId} onChange={onRoomChange} rooms={rooms} />
        </FormControl>
        {state.roomId ? (
          <FormControl variant='filled' fullWidth className='uva-form-control'>
            <InputLabel id='device-label'>Use data from the following devices</InputLabel>
            <Select<string[]>
              variant='filled'
              labelId='device-label'
              label='Devices (optional)'
              id='device-picker'
              multiple
              value={state.deviceIds}
              onChange={(event) =>
                setState({ ...state, deviceIds: event.target.value as string[] })
              }
            >
              {devicesPerRoom[state.roomId]?.map((device) => (
                <MenuItem value={device.id} key={'DeviceItem' + device.id}>
                  <Checkbox checked={state.deviceIds.includes(device.serialNumber)} />
                  {`${device.deviceType || ''} (${device.serialNumber})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <></>
        )}
        <FormControl variant='filled' className='uva-form-control' fullWidth>
          <PathogenPicker
            value={state.pathogenId}
            onChange={(value) => setState((prev) => ({ ...prev, pathogenId: value }))}
          />
        </FormControl>
        <FormControl variant='filled' fullWidth className='uva-form-control'>
          <InputLabel id='device-label'>Compute PSSQ Algorithms (optional)</InputLabel>
          <Select<string[]>
            variant='filled'
            labelId='pssq-label'
            label='Compute PSSQ Algorithms (optional)'
            id='pssq-picker'
            multiple
            value={state.algorithmsToCompute}
            onChange={(event) =>
              setState({ ...state, algorithmsToCompute: event.target.value as string[] })
            }
          >
            {possibleAlgorithmsToCompute.map((algo, index) => (
              <MenuItem value={algo} key={'algoItem' + index}>
                <Checkbox checked={state.algorithmsToCompute.includes(algo)} /> {algo}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {state.algorithmsToCompute?.includes('pssq_occupancy') ? (
          <FormControl variant='filled' fullWidth className='uva-form-control'>
            <InputLabel id='room-label'>{'Activity'}</InputLabel>
            <Select
              id='activity-filter'
              labelId='room-label'
              variant='filled'
              label={'Activity'}
              onChange={onActivityChange}
              className='uva-form-control'
              value={state.activityId}
            >
              {appContext?.activities.map((activity) => (
                <MenuItem value={activity.id} key={activity.id}>
                  {activity.primary} - {activity.secondary} ({activity.enhancementRate})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        {state.algorithmsToCompute?.includes('pssq_co2') ||
        state.algorithmsToCompute?.includes('pssq_co2+db') ? (
            <>
              <FormControl fullWidth className='uva-form-control'>
                <TextField
                  label='Ventilation CADR'
                  className='uva-form-control'
                  variant='filled'
                  fullWidth
                  value={state.cadrVentAch}
                  onChange={(event) => setState({ ...state, cadrVentAch: event.target.value })}
                  onBlur={(event) => handleCadrVentChange(event.target.value)}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>ach</InputAdornment>,
                  }}
                />
              </FormControl>
              <FormControl fullWidth className='uva-form-control'>
                <TextField
                  label='Infection Rate'
                  className='uva-form-control'
                  variant='filled'
                  value={state.infectionRate}
                  onChange={(event) => setState({ ...state, infectionRate: event.target.value })}
                  onBlur={(event) => handleInfectionRateChange(event.target.value)}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                  }}
                />
              </FormControl>
              <FormControl fullWidth className='uva-form-control'>
                <TextField
                  label='# Remediation Devices'
                  className='uva-form-control'
                  variant='filled'
                  value={state.remediationDevices}
                  inputMode='numeric'
                  onChange={(event) =>
                    setState({
                      ...state,
                      remediationDevices: parseInt(event.target.value ? event.target.value : '0'),
                    })
                  }
                />
              </FormControl>
              <FormControl fullWidth className='uva-form-control'>
                <TextField
                  label='CADR per Remediation Device'
                  className='uva-form-control'
                  variant='filled'
                  value={state.remediationDevicesCadrPerDevice}
                  inputMode='numeric'
                  onChange={(event) =>
                    setState({
                      ...state,
                      remediationDevicesCadrPerDevice: parseInt(
                        event.target.value ? event.target.value : '0',
                      ),
                    })
                  }
                />
              </FormControl>
            </>
          ) : null}
        <FormControl fullWidth className='uva-form-control'>
          <TextField
            label='Maximum Quanta Density'
            className='uva-form-control'
            variant='filled'
            fullWidth
            value={state.maxQuantaDensity}
            onChange={(event) => setState({ ...state, maxQuantaDensity: event.target.value })}
            onBlur={(event) => handleMaxQuantaDensityChange(event.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position='end'>quanta/ft³</InputAdornment>,
            }}
          />
        </FormControl>

        <FormControl variant='filled' fullWidth className='uva-form-control'>
          <InputLabel id='period-label'>Report Period</InputLabel>
          <Select
            variant='filled'
            labelId='period-label'
            label='Report Period'
            value={state.period ?? ''}
            onChange={(event: SelectChangeEvent<number>) => onPeriodChange(+event.target.value)}
          >
            {REPORT_PERIODS.map((period: uva_report_period) => (
              <MenuItem value={period.id} key={'Period' + period.id}>
                {period.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {state.period === CUSTOM_REPORT_PERIOD_ID && (
          <FormControl fullWidth className='uva-form-control'>
            <div className='uva-date-range-control' id='uvaLabReportStartStop'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label='Start Time'
                  value={state.startTime}
                  renderInput={(params: TextFieldProps) => <TextField {...params} />}
                  className='uva-form-control uva-calendar-control'
                  onChange={(newValue: Date | null) => {
                    setState({ ...state, startTime: newValue })
                  }}
                />
              </LocalizationProvider>
              <span className='uva-date-time-separator'>to</span>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label='End Time'
                  className='uva-form-control uva-calendar-control'
                  value={state.endTime}
                  renderInput={(params: TextFieldProps) => <TextField {...params} />}
                  onChange={(newValue: Date | null) => {
                    setState({ ...state, endTime: newValue })
                  }}
                />
              </LocalizationProvider>
            </div>
          </FormControl>
        )}

        <FormControl variant='filled' fullWidth className='uva-form-control'>
          <InputLabel id='interval-label'>Calculation Interval</InputLabel>
          <Select
            variant='filled'
            labelId='interval-label'
            value={state.calculationInterval ?? ''}
            id='interval-picker'
            onChange={(event: SelectChangeEvent<number>) =>
              setState({ ...state, calculationInterval: +event.target.value })
            }
          >
            <MenuItem value={10} key='Interval1'>
              10 seconds
            </MenuItem>
            <MenuItem value={30} key='Interval2'>
              30 seconds
            </MenuItem>
            <MenuItem value={60} key='Interval3'>
              1 minute
            </MenuItem>
            {selectedPeriodSeconds < 300 && (
              <ListSubheader>Unavailable for selected period</ListSubheader>
            )}
            <MenuItem value={300} key='Interval4' disabled={selectedPeriodSeconds < 300}>
              5 minutes
            </MenuItem>
            {selectedPeriodSeconds < 900 && selectedPeriodSeconds >= 300 && (
              <ListSubheader>Unavailable for selected period</ListSubheader>
            )}
            <MenuItem value={900} key='Interval5' disabled={selectedPeriodSeconds < 900}>
              15 minutes
            </MenuItem>
            {selectedPeriodSeconds < 1800 && selectedPeriodSeconds >= 900 && (
              <ListSubheader>Unavailable for selected period</ListSubheader>
            )}
            <MenuItem value={1800} key='Interval6' disabled={selectedPeriodSeconds < 1800}>
              30 minutes
            </MenuItem>
            {selectedPeriodSeconds < 3600 && selectedPeriodSeconds >= 1800 && (
              <ListSubheader>Unavailable for selected period</ListSubheader>
            )}
            <MenuItem value={3600} key='Interval7' disabled={selectedPeriodSeconds < 3600}>
              1 hour
            </MenuItem>
            {selectedPeriodSeconds < 10800 && selectedPeriodSeconds >= 3600 && (
              <ListSubheader>Unavailable for selected period</ListSubheader>
            )}
            <MenuItem value={10800} key='Interval8' disabled={selectedPeriodSeconds < 10800}>
              3 hours
            </MenuItem>
            {selectedPeriodSeconds < 3600 * 6 && selectedPeriodSeconds >= 10800 && (
              <ListSubheader>Unavailable for selected period</ListSubheader>
            )}
            <MenuItem value={3600 * 6} key='Interval9' disabled={selectedPeriodSeconds < 3600 * 6}>
              6 hours
            </MenuItem>
            {selectedPeriodSeconds < 3600 * 24 && selectedPeriodSeconds >= 3600 * 6 && (
              <ListSubheader>Unavailable for selected period</ListSubheader>
            )}
            <MenuItem
              value={3600 * 24}
              key='Interval10'
              disabled={selectedPeriodSeconds < 3600 * 24}
            >
              1 day
            </MenuItem>
          </Select>
        </FormControl>

        {state.error && <Alert severity='error'>{state.error}</Alert>}
        <div className='uva-form-actions'>
          <Button variant='contained' color='secondary' onClick={onCancel}>
            Cancel
          </Button>
          <LoadingButton variant='contained' onClick={onRun} loading={processing}>
            Run Report
          </LoadingButton>
        </div>
      </CardContent>
    </Card>
  )
}

export default LabReportForm
