import { createTheme, PaletteMode } from '@mui/material'

export const uvaPalette = {
  primary: {
    main: '#0093c8',
  },
  error: {
    main: '#ff6d62'
  },
  secondary: {
    main: '#444', // FIXME: Need a better secondary color
  },
}

export const uvaTypography = {
  fontFamily: ['avenir', 'sans-serif'].join(','),
}

export function createUvaTheme(paletteMode: PaletteMode = 'light') {
  return createTheme({
    typography: uvaTypography,
    palette: {
      ...uvaPalette,
      mode: paletteMode,
    },
    components: {
      MuiCard: {
        defaultProps: {
          elevation: 4,
          style: {
            borderRadius: '10px'
          }
        }
      },
      MuiPaper: {
        defaultProps: {
          elevation: 4
        }
      }
    }
  })
}

export const uvaTheme = createUvaTheme()