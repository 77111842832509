/* eslint indent: 0 */
// Prettier/eslint fighting over indenting ternary at line 303
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Typography,
} from '@mui/material'
import { differenceInMilliseconds, format, subMilliseconds } from 'date-fns'
import FileSaver from 'file-saver'
import React, { PropsWithChildren } from 'react'
import { AppContext } from '../../app/App.context'
import PssqClient from '../../services/pssq.service'
import LabReportForm, {
  LabReportFormProps,
} from './components/lab-report-form/lab-report-form.component'
import LabReportGraph from './components/lab-report-graph/lab-report-graph.component'
import {
  LabReportResultsTable,
  LabReportRow,
  TableColDef,
} from './components/lab-report-results-table/lab-report-results-table.component'
import { UvaRoom } from '../../services/api.models'

const QUANTA_OCCUPANCY_SERIES_ID = 'quantaOccupancy'
const QUANTA_CO2_SERIES_ID = 'quantaCO2'
const QUANTA_CO2_HISTORY_SERIES_ID = 'quantaCO2History'
const QUANTA_CO2_VOLUME_HISTORY_SERIES_ID = 'quantaCO2dBHistory'
const CADR_SERIES_ID = 'cadr'
const OCCUPANCY_SERIES_ID = 'occupancy'
const AUTOREFRESH_INTERVAL = 30000
const DEFAULT_MAX_QUANTA = 3
const DEFAULT_TABLE_RECORD_COUNT = 18

interface LabReportState {
  loading: boolean
  data?: Awaited<ReturnType<typeof PssqClient.getLabReport>>
  reportParams?: Parameters<LabReportFormProps['triggerReport']>[0]
  error?: string
  selectedMetrics: string[]
  refreshIntervalId?: number
}

export interface LabReportProps {
  rooms: UvaRoom[]
}

// eslint-disable-next-line func-style
const LabReportPage: React.FC<PropsWithChildren<LabReportProps>> = ({rooms}) => {
  const appContext = React.useContext(AppContext)

  const [state, setReactState] = React.useState<LabReportState>({
    loading: false,
    data: undefined,
    selectedMetrics: [],
  })
  const stateRef = React.useRef<LabReportState>(state)

  function setState(
    newStateOrFunction: LabReportState | ((oldState: LabReportState) => LabReportState),
  ) {
    setReactState((prev) => {
      let newState: LabReportState
      if (typeof newStateOrFunction === 'function') {
        newState = newStateOrFunction(prev)
      } else {
        newState = newStateOrFunction
      }
      stateRef.current = newState
      return newState
    })
  }

  function exportData() {
    let csvString = ''
    const room = rooms.find((r) => r.id === state.reportParams?.roomId)
    const pathogen = appContext.pathogens.find((p) => p.id === state.reportParams?.pathogenId)

    // room info
    csvString +=
      'Room,' +
      Object.entries(room ?? {})
        .map(([name, value]) => `${name}=${value}`)
        .join(',') +
      '\n'
    // pathogen info
    csvString +=
      'Pathogen,' +
      Object.entries(pathogen ?? {})
        .map(([name, value]) => `${name}=${value}`)
        .join(',') +
      '\n'
    // Selected devices
    csvString += 'Selected devices,' + state.reportParams?.deviceIds?.join(',') + '\n'
    // Maximum quanta density
    csvString += `Maximum quanta density,${state.reportParams?.maxQuantaDensity}\n`
    // Infection rate
    csvString += `Infection rate,${state.reportParams?.infectionRate}\n`
    // Report Period
    csvString += `Report period,${state.reportParams?.startTime.toISOString()},${state.reportParams?.endTime.toISOString()}\n`
    // Calculation interval
    csvString += `Calculation Interval,${state.reportParams?.interval}\n`

    // data header
    const headerColumns = LAB_REPORT_COLUMNS_ALL
    csvString += headerColumns.map((c) => c.name).join(',') + '\n'
    state.data?.forEach((row) => {
      const tabularizedData = mapToTableRow(row, true)
      csvString += tabularizedData.map((v) => v.value).join(',') + '\n'
    })

    const blob = new Blob([csvString], { type: 'text/csv' })
    const name = `simulation_${format(new Date(), 'yyyyMMdd_HHmmss')}.csv`
    FileSaver.saveAs(blob, name)
  }

  // eslint-disable-next-line func-style
  const onTriggerLabReport: LabReportFormProps['triggerReport'] = (report) => {
    if (report === null) {
      stopAutoRefresh()
      setState((prev) => ({
        ...prev,
        data: undefined,
      }))
      return
    }
    if (report.isCustomInterval) {
      stopAutoRefresh()
    }

    setState((prev) => ({
      ...prev,
      reportParams: { ...report },
      error: '',
      loading: true,
    }))

    PssqClient.getLabReport({
      roomId: report.roomId,
      startTime: report.startTime,
      endTime: report.endTime,
      interval: report.interval,
      dataDeviceSerialNumbers: report.deviceIds,
      pssqOptions: {
        activityId: report.activityId,
        pathogenId: report.pathogenId || '',
        assumedInfectionRate: (report.infectionRate || 0) / 100,
        targetQuantaDensity: report.maxQuantaDensity || 0,
        cadrVentAch: report.cadrVentAch,
        algorithmsToCompute: report.algorithmsToCompute,
        airPathogenRemediationDevices: {
          numDevices: report.remediationDevices || 0,
          cadrPerDevice: report.remediationDevicesCadrPerDevice || 0,
        },
      },
    })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          loading: false,
          data: res,
        }))
      })
      .catch((error) => {
        stopAutoRefresh()
        setState((prev) => ({
          ...prev,
          loading: false,
          data: undefined,
          error: error.message ?? error.statusText ?? JSON.stringify(error),
        }))
      })
  }

  function selectMetrics(value: string[]) {
    const MAX_VALUE_LENGTH = 2 // Better variable name?
    setState({
      ...state,
      selectedMetrics: value.length > MAX_VALUE_LENGTH ? value.slice(-MAX_VALUE_LENGTH) : value,
    })
  }

  function stopAutoRefresh() {
    if (stateRef.current.refreshIntervalId) {
      window.clearInterval(stateRef.current.refreshIntervalId)
      setState((prev) => ({
        ...prev,
        refreshIntervalId: undefined,
      }))
    }
  }

  function toggleAutoRefresh() {
    if (stateRef.current.refreshIntervalId) {
      stopAutoRefresh()
    } else {
      const intervalId = window.setInterval(() => {
        if (stateRef.current?.reportParams) {
          const reportPeriod = differenceInMilliseconds(
            stateRef.current.reportParams.endTime,
            stateRef.current.reportParams.startTime,
          )
          onTriggerLabReport({
            ...stateRef.current.reportParams,
            startTime: subMilliseconds(new Date(), reportPeriod),
            endTime: new Date(),
          })
        }
      }, AUTOREFRESH_INTERVAL)
      setState((prev) => ({
        ...prev,
        refreshIntervalId: intervalId,
      }))
    }
  }

  const graphSeries = state.data ? mapToGraphSeries(state.data, true) : []

  const showBigSpinner = state.loading && !state.refreshIntervalId

  return (
    <div id='UvaLabReportPage'>
      <h1 className='uva-page-title'>Health and Risk Modeling</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} xl={3} maxWidth={450}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            {appContext.loading ? (
              <Skeleton className='uva-skeleton uva-skeleton-paragraph' variant='rectangular' />
            ) : (
              <>
                <p>
                  This tool exposes some of the underlying datapoints that Continuum uses to compute
                  risk leveraging the PSSQ v3 algorithm. Use it to view historical data and compare
                  it against alternative scenarios. Add / remove additional UVA Clean Air devices or
                  change your risk tolerance thresholds and compare the impact against your current
                  configurations!
                </p>
                <LabReportForm
                  triggerReport={onTriggerLabReport}
                  processing={state.loading}
                  error={state.error}
                  rooms={rooms}
                ></LabReportForm>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={9} xl={9}>
          {!showBigSpinner && !state.data ? (
            <>
              <Paper elevation={2} sx={{ padding: 10 }}>
                <Typography variant='h4' align='center' gutterBottom>
                  Run report to generate metrics
                </Typography>
              </Paper>
            </>
          ) : null}
          {showBigSpinner ? (
            <Skeleton className='uva-skeleton uva-skeleton-graph' variant='rectangular' />
          ) : (
            state.data && (
              <>
                <Card sx={{ marginBottom: 5 }}>
                  <CardContent>
                    <FormControl variant='filled' fullWidth className='uva-form-control'>
                      <InputLabel id='metrics-label'>Toggle Environmental Metrics</InputLabel>
                      <Select<string[]>
                        variant='filled'
                        labelId='metrics-label'
                        label='Toggle Environmental Metrics'
                        id='metrics-picker'
                        multiple
                        value={state.selectedMetrics}
                        onChange={(event) => selectMetrics(event.target.value as string[])}
                      >
                        {SELECTABLE_METRICS.map((metric) => (
                          <MenuItem value={metric.id} key={'MetricItem' + metric.id}>
                            <Checkbox checked={state.selectedMetrics.includes(metric.id)} />
                            {metric.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {!state.reportParams?.isCustomInterval ? (
                      <FormControl variant='filled' fullWidth className='uva-form-control'>
                        <FormControlLabel
                          labelPlacement='start'
                          control={
                            state.loading ? (
                              <Skeleton className='uva-skeleton' variant='rectangular' />
                            ) : (
                              <Checkbox
                                checked={!!state.refreshIntervalId}
                                onChange={toggleAutoRefresh}
                              />
                            )
                          }
                          label='Auto-refresh (30s)'
                        />
                      </FormControl>
                    ) : (
                      <></>
                    )}
                  </CardContent>
                </Card>
                <Card sx={{ padding: 2, minHeight: 700 }}>
                  <LabReportGraph
                    timestamps={state.data.map((r) => r.timestamp)}
                    maxQuanta={state.reportParams?.maxQuantaDensity ?? DEFAULT_MAX_QUANTA}
                    occupancy={
                      graphSeries.find((series) => series.id === OCCUPANCY_SERIES_ID)?.values || []
                    }
                    quantaOccupancy={
                      state.reportParams?.algorithmsToCompute?.includes('pssq_occupancy')
                        ? graphSeries.find((series) => series.id === QUANTA_OCCUPANCY_SERIES_ID)
                            ?.values
                        : undefined
                    }
                    quantaCo2={
                      state.reportParams?.algorithmsToCompute?.includes('pssq_co2')
                        ? graphSeries.find((series) => series.id === QUANTA_CO2_SERIES_ID)?.values
                        : undefined
                    }
                    quantaCo2History={
                      graphSeries.find((series) => series.id === QUANTA_CO2_HISTORY_SERIES_ID)
                        ?.values || []
                    }
                    quantaCo2dBHistory={
                      state.reportParams?.algorithmsToCompute?.includes('pssq_co2+db')
                        ? graphSeries.find(
                            (series) => series.id === QUANTA_CO2_VOLUME_HISTORY_SERIES_ID,
                          )?.values
                        : undefined
                    }
                    addSeriesA={
                      state.selectedMetrics.length > 0
                        ? {
                            label:
                              graphSeries.find((s) => s.id === state.selectedMetrics[0])?.name ||
                              '',
                            values:
                              graphSeries.find((s) => s.id === state.selectedMetrics[0])?.values ||
                              [],
                          }
                        : undefined
                    }
                    addSeriesB={
                      state.selectedMetrics.length > 1
                        ? {
                            label:
                              graphSeries.find((s) => s.id === state.selectedMetrics[1])?.name ||
                              '',
                            values:
                              graphSeries.find((s) => s.id === state.selectedMetrics[1])?.values ||
                              [],
                          }
                        : undefined
                    }
                  />
                </Card>
              </>
            )
          )}
        </Grid>
      </Grid>
      {/* Removed for demo due to spacing issues */}
      {/* <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ padding: 2, minHeight: 400 }}>
            {showBigSpinner ? (
              <>
                <Skeleton
                  className='uva-skeleton uva-skeleton-table-header'
                  variant='rectangular'
                />
                <Grid container spacing={2}>
                  {Array(DEFAULT_TABLE_RECORD_COUNT)
                    .fill(0)
                    .map(function (v, i) {
                      return (
                        <Grid key={i} item xs={2}>
                          <Skeleton className='uva-skeleton' variant='rectangular' />
                        </Grid>
                      )
                    })}
                </Grid>
              </>
            ) : (
              state.data && (
                <Card>
                  <LabReportResultsTable
                    onExport={exportData}
                    columns={LAB_REPORT_COLUMNS_ALL}
                    rows={
                      state.loading
                        ? undefined
                        : state.data?.map((record) => mapToTableRow(record, true)) || []
                    }
                  />
                </Card>
              )
            )}
          </Paper>
        </Grid>
      </Grid> */}
    </div>
  )
}

function mapToTableRow(
  rec: Awaited<ReturnType<typeof PssqClient.getLabReport>>[number],
  includePssq?: boolean,
) {
  const row: LabReportRow = []
  row.push({ value: rec.timestamp })
  if (includePssq) {
    row.push({ value: rec.pssqOccupancy?.quantaDensity })
    row.push({ value: rec.pssqCo2?.quantaDensity })
    row.push({ value: rec.pssqCo2History?.quantaDensity })
    row.push({ value: rec.pssqOccupancy?.stats?.currentCADR })
    row.push({ value: rec.occupancy })
    row.push({ value: rec.pssqOccupancy?.stats?.currentQuantaEmissionRate })
    row.push({ value: rec.pssqCo2?.stats?.currentQuantaEmissionRate })
    row.push({ value: rec.pssqCo2?.stats?.baselineCO2Mgft3 })
  }
  row.push({ value: rec.environment_metrics.pir_max })
  row.push({ value: rec.environment_metrics.pir_min })
  row.push({ value: rec.environment_metrics.volume })
  row.push({ value: rec.environment_metrics.volume_avg_rms })
  row.push({ value: rec.environment_metrics.volume_max_rms })
  row.push({ value: rec.environment_metrics.volume_min_rms })
  row.push({ value: rec.environment_metrics.ain1 })
  row.push({ value: rec.environment_metrics.ain2 })
  row.push({ value: rec.environment_metrics.prs_temp })
  row.push({ value: rec.environment_metrics.prs_pres })
  row.push({ value: rec.environment_metrics.scd_co2 })
  row.push({ value: rec.environment_metrics.scd_temp })
  row.push({ value: rec.environment_metrics.scd_hum })
  row.push({ value: rec.environment_metrics.sgp_temp })
  row.push({ value: rec.environment_metrics.sgp_hum })
  row.push({ value: rec.environment_metrics.sgp_voc })
  row.push({ value: rec.environment_metrics.bsec_out_iaq })
  row.push({ value: rec.environment_metrics.bsec_out_iaq_acc })
  row.push({ value: rec.environment_metrics.bsec_stat_iaq })
  row.push({ value: rec.environment_metrics.bsec_co2_eq })
  row.push({ value: rec.environment_metrics.bsec_br_voc_eq })
  row.push({ value: rec.environment_metrics.bsec_raw_temp })
  row.push({ value: rec.environment_metrics.bsec_raw_pres })
  row.push({ value: rec.environment_metrics.bsec_raw_hum })
  row.push({ value: rec.environment_metrics.bsec_raw_gas })
  row.push({ value: rec.environment_metrics.bsec_stab_stat })
  row.push({ value: rec.environment_metrics.bsec_run_in_stat })
  row.push({ value: rec.environment_metrics.bsec_heat_comp_temp })
  row.push({ value: rec.environment_metrics.bsec_heat_comp_hum })
  row.push({ value: rec.environment_metrics.bsec_comp_gas })
  row.push({ value: rec.environment_metrics.bsec_gap_percent })
  row.push({ value: rec.environment_metrics.oxygen })
  return row
}

function mapToGraphSeries(
  data: Awaited<ReturnType<typeof PssqClient.getLabReport>>,
  includePssq?: boolean,
) {
  const series: {
    id: string
    name: string
    values: (number | undefined)[]
  }[] = []
  if (includePssq) {
    series.push({
      id: QUANTA_OCCUPANCY_SERIES_ID,
      name: 'Quanta (Occupancy)',
      values: data.map((r) => r.pssqOccupancy?.quantaDensity),
    })
    series.push({
      id: QUANTA_CO2_SERIES_ID,
      name: 'Quanta (CO2)',
      values: data.map((r) => r.pssqCo2?.quantaDensity),
    })
    series.push({
      id: QUANTA_CO2_HISTORY_SERIES_ID,
      name: 'Quanta History (CO2)',
      values: data.map((r) => r.pssqCo2History?.quantaDensity),
    })
    series.push({
      id: QUANTA_CO2_VOLUME_HISTORY_SERIES_ID,
      name: 'Quanta (CO2 + dB)',
      values: data.map((r) => r.pssqCo2PlusDb?.quantaDensity),
    })
    series.push({
      id: CADR_SERIES_ID,
      name: 'CADR',
      values: data.map((r) => r.pssqOccupancy?.stats?.currentCADR),
    })
    series.push({
      id: OCCUPANCY_SERIES_ID,
      name: 'Occupancy',
      values: data.map((r) => r.occupancy),
    })
    series.push({
      id: 'QuantaEmissionRateOccupancy',
      name: 'Quanta Emission Rate (Occupancy)',
      values: data.map((r) => r.pssqOccupancy?.stats?.currentQuantaEmissionRate),
    })
    series.push({
      id: 'QuantaEmissionRateCO2',
      name: 'Quanta Emission Rate (CO2)',
      values: data.map((r) => r.pssqCo2?.stats?.currentQuantaEmissionRate),
    })
    series.push({
      id: 'BaselineCO2Mgft3',
      name: 'Baseline CO2',
      // eslint-disable-next-line no-magic-numbers
      values: data.map((r) => (r.pssqCo2PlusDb?.stats?.baselineCO2Mgft3 * 35.3) / 1.8),
    })
  }
  series.push({
    id: 'PirMin',
    name: 'pir_min',
    values: data.map((r) => r.environment_metrics.pir_min),
  })
  series.push({
    id: 'PirMax',
    name: 'pir_max',
    values: data.map((r) => r.environment_metrics.pir_max),
  })
  series.push({
    id: 'Volume',
    name: 'Volume',
    values: data.map((r) => r.environment_metrics.volume),
  })
  series.push({
    id: 'VolumeAvgRms',
    name: 'volume_avg_rms',
    values: data.map((r) => r.environment_metrics.volume_avg_rms),
  })
  series.push({
    id: 'VolumeMaxRms',
    name: 'volume_max_rms',
    values: data.map((r) => r.environment_metrics.volume_max_rms),
  })
  series.push({
    id: 'VolumeMinRms',
    name: 'volume_min_rms',
    values: data.map((r) => r.environment_metrics.volume_min_rms),
  })
  series.push({ id: 'Ain1', name: 'ain1', values: data.map((r) => r.environment_metrics.ain1) })
  series.push({ id: 'Ain2', name: 'ain2', values: data.map((r) => r.environment_metrics.ain2) })
  series.push({
    id: 'PrsTemp',
    name: 'prs_temp',
    values: data.map((r) => r.environment_metrics.prs_temp),
  })
  series.push({
    id: 'PrsPres',
    name: 'prs_pres',
    values: data.map((r) => r.environment_metrics.prs_pres),
  })
  series.push({
    id: 'ScdCo2',
    name: 'scd_co2',
    values: data.map((r) => r.environment_metrics.scd_co2),
  })
  series.push({
    id: 'ScdTemp',
    name: 'scd_temp',
    values: data.map((r) => r.environment_metrics.scd_temp),
  })
  series.push({
    id: 'ScdHum',
    name: 'scd_hum',
    values: data.map((r) => r.environment_metrics.scd_hum),
  })
  series.push({
    id: 'SgpTemp',
    name: 'sgp_temp',
    values: data.map((r) => r.environment_metrics.sgp_temp),
  })
  series.push({
    id: 'SgpHum',
    name: 'sgp_hum',
    values: data.map((r) => r.environment_metrics.sgp_hum),
  })
  series.push({
    id: 'SgpVoc',
    name: 'sgp_voc',
    values: data.map((r) => r.environment_metrics.sgp_voc),
  })
  series.push({
    id: 'BsecOutIaq',
    name: 'bsec_out_iaq',
    values: data.map((r) => r.environment_metrics.bsec_out_iaq),
  })
  series.push({
    id: 'BsecOutIaqAcc',
    name: 'bsec_out_iaq_acc',
    values: data.map((r) => r.environment_metrics.bsec_out_iaq_acc),
  })
  series.push({
    id: 'BsecStatIaq',
    name: 'bsec_stat_iaq',
    values: data.map((r) => r.environment_metrics.bsec_stat_iaq),
  })
  series.push({
    id: 'BsecCo2Eq',
    name: 'bsec_co2_eq',
    values: data.map((r) => r.environment_metrics.bsec_co2_eq),
  })
  series.push({
    id: 'BsecBrVocEq',
    name: 'bsec_br_voc_eq',
    values: data.map((r) => r.environment_metrics.bsec_br_voc_eq),
  })
  series.push({
    id: 'BsecRawTemp',
    name: 'bsec_raw_temp',
    values: data.map((r) => r.environment_metrics.bsec_raw_temp),
  })
  series.push({
    id: 'BsecRawPres',
    name: 'bsec_raw_pres',
    values: data.map((r) => r.environment_metrics.bsec_raw_pres),
  })
  series.push({
    id: 'BsecRawHum',
    name: 'bsec_raw_hum',
    values: data.map((r) => r.environment_metrics.bsec_raw_hum),
  })
  series.push({
    id: 'BsecRawGas',
    name: 'bsec_raw_gas',
    values: data.map((r) => r.environment_metrics.bsec_raw_gas),
  })
  series.push({
    id: 'BsecStabStat',
    name: 'bsec_stab_stat',
    values: data.map((r) => r.environment_metrics.bsec_stab_stat),
  })
  series.push({
    id: 'BsecRunInStat',
    name: 'bsec_run_in_stat',
    values: data.map((r) => r.environment_metrics.bsec_run_in_stat),
  })
  series.push({
    id: 'BsecHeatCompTemp',
    name: 'bsec_heat_comp_temp',
    values: data.map((r) => r.environment_metrics.bsec_heat_comp_temp),
  })
  series.push({
    id: 'BsecHeatCompHum',
    name: 'bsec_heat_comp_hum',
    values: data.map((r) => r.environment_metrics.bsec_heat_comp_hum),
  })
  series.push({
    id: 'BsecCompGas',
    name: 'bsec_comp_gas',
    values: data.map((r) => r.environment_metrics.bsec_comp_gas),
  })
  series.push({
    id: 'BsecGapPercent',
    name: 'bsec_gap_percent',
    values: data.map((r) => r.environment_metrics.bsec_gap_percent),
  })
  series.push({
    id: 'Oxygen',
    name: 'oxygen',
    values: data.map((r) => r.environment_metrics.oxygen),
  })
  return series
}

const LAB_REPORT_COLUMNS_ALL: TableColDef[] = [
  {
    id: 'Timestamp',
    name: 'Timestamp',
    dataType: 'timestamp',
  },
  {
    id: 'Quanta',
    name: 'Quanta (Occupancy)',
    dataType: 'pssq',
  },
  {
    id: 'QuantaCo2',
    name: 'Quanta (CO2)',
    dataType: 'pssq',
  },
  {
    id: 'QuantaCo2History',
    name: 'Quanta History (CO2)',
    dataType: 'pssq',
  },
  {
    id: 'DeviceCADR',
    name: 'Device CADR',
    dataType: 'float',
  },
  {
    id: 'Occupancy',
    name: 'Occupancy',
    dataType: 'number',
  },
  {
    id: 'QuantaEmissionRateOccupancy',
    name: 'Quanta Emission Rate (Occupancy)',
    dataType: 'number',
  },
  {
    id: 'QuantaEmissionRateCO2',
    name: 'Quanta Emission Rate (CO2)',
    dataType: 'number',
  },
  {
    id: 'BaselineCO2Mgft3',
    name: 'Baseline CO2',
    dataType: 'number',
  },
  {
    id: 'PirMin',
    name: 'pir_min',
    dataType: 'float',
  },
  {
    id: 'PirMax',
    name: 'pir_max',
    dataType: 'float',
  },
  {
    id: 'Volume',
    name: 'Volume',
    dataType: 'number',
  },
  {
    id: 'VolumeAvgRms',
    name: 'volume_avg_rms',
    dataType: 'number',
  },
  {
    id: 'VolumeMaxRms',
    name: 'volume_max_rms',
    dataType: 'number',
  },
  {
    id: 'VolumeMinRms',
    name: 'volume_min_rms',
    dataType: 'number',
  },
  {
    id: 'Ain1',
    name: 'ain1',
    dataType: 'float',
  },
  {
    id: 'Ain2',
    name: 'ain2',
    dataType: 'float',
  },
  {
    id: 'PrsTemp',
    name: 'prs_temp',
    dataType: 'float',
  },
  {
    id: 'PrsPres',
    name: 'prs_pres',
    dataType: 'float',
  },
  {
    id: 'ScdCo2',
    name: 'scd_co2',
    dataType: 'float',
  },
  {
    id: 'ScdTemp',
    name: 'scd_temp',
    dataType: 'float',
  },
  {
    id: 'ScdHum',
    name: 'scd_hum',
    dataType: 'float',
  },
  {
    id: 'SgpTemp',
    name: 'sgp_temp',
    dataType: 'float',
  },
  {
    id: 'SgpHum',
    name: 'sgp_hum',
    dataType: 'float',
  },
  {
    id: 'SgpVoc',
    name: 'sgp_voc',
    dataType: 'float',
  },
  {
    id: 'BsecOutAaq',
    name: 'bsec_out_iaq',
    dataType: 'float',
  },
  {
    id: 'BsecOutIaqAcc',
    name: 'bsec_out_iaq_acc',
    dataType: 'float',
  },
  {
    id: 'BsecStatIaq',
    name: 'bsec_stat_iaq',
    dataType: 'float',
  },
  {
    id: 'BsecCo2Eq',
    name: 'bsec_co2_eq',
    dataType: 'float',
  },
  {
    id: 'BsecBrVocEq',
    name: 'bsec_br_voc_eq',
    dataType: 'float',
  },
  {
    id: 'BsecRawTemp',
    name: 'bsec_raw_temp',
    dataType: 'float',
  },
  {
    id: 'BsecRawPres',
    name: 'bsec_raw_pres',
    dataType: 'float',
  },
  {
    id: 'BsecRawHum',
    name: 'bsec_raw_hum',
    dataType: 'float',
  },
  {
    id: 'BsecRawGas',
    name: 'bsec_raw_gas',
    dataType: 'float',
  },
  {
    id: 'BsecStabStat',
    name: 'bsec_stab_stat',
    dataType: 'float',
  },
  {
    id: 'BsecRunInStat',
    name: 'bsec_run_in_stat',
    dataType: 'float',
  },
  {
    id: 'BsecHeatCompTemp',
    name: 'bsec_heat_comp_temp',
    dataType: 'float',
  },
  {
    id: 'BsecHeatComHum',
    name: 'bsec_heat_comp_hum',
    dataType: 'float',
  },
  {
    id: 'BsecCompGas',
    name: 'bsec_comp_gas',
    dataType: 'float',
  },
  {
    id: 'BsecGapPercent',
    name: 'bsec_gap_percent',
    dataType: 'float',
  },
  {
    id: 'Oxygen',
    name: 'Oxygen',
    dataType: 'float',
  },
]

const SELECTABLE_METRICS = LAB_REPORT_COLUMNS_ALL.filter(
  (col) =>
    !['Timestamp', 'Quanta', 'QuantaCo2', 'QuantaCo2History', 'DeviceCADR', 'Occupancy'].includes(
      col.id,
    ),
)

export default LabReportPage
