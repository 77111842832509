import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { TreeItem, TreeView } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Paper, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { UvaRoom } from '../../../../services/api.models'

// UvaRoom with required id
export interface UvaRoomWithId extends UvaRoom {
  id: string
}

export type HierarchicalRoomType = UvaRoomWithId & { rooms: HierarchicalRoomType[] }

export interface RoomSelectorProps {
  rooms: HierarchicalRoomType[]
  onSelectRoom?: (room: HierarchicalRoomType) => void
  selectedRoomId?: string
}

export function buildRoomMap(rooms: HierarchicalRoomType[]) {
  let map = {}
  rooms?.forEach((room) => {
    map[room.id] = room
    if (room.rooms) {
      map = {
        ...map,
        ...buildRoomMap(room.rooms),
      }
    }
  })
  return map
}

function parentRoomNames(selectedRoomIds: string[], roomMap: Record<string, HierarchicalRoomType>) {
  if (selectedRoomIds.length > 1) {
    const parentRoomIds = selectedRoomIds.slice(0, -1)
    const parentPath = parentRoomIds.map((id) => roomMap[id].name).join(' > ')
    return <Typography variant='overline'>{parentPath}</Typography>
  } else {
    return <></>
  }
}

// eslint-disable-next-line func-style
export const RoomSelector: React.FC<RoomSelectorProps> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  // ids of all rooms on the selected room path, starting from the top room, ending with the selected room
  const [selectedRoomIds, setSelectedRoomIds] = useState<string[]>([])

  const ALL_ROOM_MAP = buildRoomMap(props.rooms)

  function buildTree(rooms: HierarchicalRoomType[]) {
    if (!rooms?.length) {
      return []
    }
    return rooms.map((room) => (
      <TreeItem nodeId={room.id} label={room.name} key={room.id}>
        {buildTree(room.rooms)}
      </TreeItem>
    ))
  }

  function selectRoom(
    selectedRoomId: string,
    testedRooms: HierarchicalRoomType[],
    currPath: string[] = [],
  ) {
    for (const testedRoom of testedRooms) {
      if (testedRoom.id === selectedRoomId) {
        const finalIds = [...currPath, selectedRoomId]
        setSelectedRoomIds(finalIds)
        setExpanded(false)
        if (props.onSelectRoom && props.selectedRoomId !== selectedRoomId) {
          props.onSelectRoom(testedRoom)
        }
        return true
      } else if (testedRoom.rooms?.length) {
        if (selectRoom(selectedRoomId, testedRoom.rooms, [...currPath, testedRoom.id])) {
          return true
        }
      }
    }
    return false
  }

  if (
    props.selectedRoomId &&
    (!selectedRoomIds.length ||
      selectedRoomIds[selectedRoomIds.length - 1] !== props.selectedRoomId)
  ) {
    selectRoom(props.selectedRoomId, props.rooms, [])
  }

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMore />} id='room-selector-header'>
        <Stack>
          <i>{parentRoomNames(selectedRoomIds, ALL_ROOM_MAP)}</i>
          <Typography sx={{ fontWeight: 'bold', fontSize: 24 }}>
            {selectedRoomIds.length
              ? ALL_ROOM_MAP[selectedRoomIds[selectedRoomIds.length - 1]].name
              : ''}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          expanded={Object.keys(ALL_ROOM_MAP)}
          multiSelect={false}
          selected={props.selectedRoomId}
          onNodeSelect={(e, roomId: string) => selectRoom(roomId, props.rooms, [])}
        >
          {buildTree(props.rooms)}
        </TreeView>
      </AccordionDetails>
    </Accordion>
  )
}
