import { CssBaseline, PaletteMode, ThemeProvider } from '@mui/material'
import React, { useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import AppShell from '../pages/app-shell/app-shell.component'
import EnvChartsPage from '../pages/env-charts/env-charts-page.component'
import LabReportPage from '../pages/lab-report/lab-report-page.component'
import { MonitoringPage } from '../pages/monitoring/monitoring-page.component'
import { MonitoringOverviewPage } from '../pages/monitoring/monitoring-overview-page.component'
import PssqSimulatorPage from '../pages/pssq-simulator/pssq-simulator-page.component'
import { TracksPage } from '../pages/tracks/tracks-page.component'
import '../scss/index.scss'
import AuthClient from '../services/auth.service'
import PssqClient from '../services/pssq.service'
import { createUvaTheme } from '../theme'
import { AppContext, AppContextModel, AppContextWithSetter } from './App.context'
import ContinuumShell from '../pages/app-shell/continuum-shell.component'
import { UvaRoom } from '../services/api.models'

const THEME_MODE_KEY = 'themeMode'

// eslint-disable-next-line func-style
const createAppContext = (): AppContextWithSetter => {
  const [appContext, setAppContext] = React.useState<AppContextModel>({
    loading: false,
    activities: [],
    pathogens: [],
    rooms: [],
    simulationModeKey: 'UVA_SIMULATION_MODE',
    fixedSimulationParamsKey: 'UVA_FIXED_SIMULATION_PARAMS',
    simulationSpeedKey: 'UVA_SIMULATION_SPEED',
    savedScenariosKey: 'UVA_SAVED_SCENARIOS',
    activeScenarioIdKey: 'UVA_ACTIVE_SCENARIO_ID',
  })

  function patchAppContext(ctx: Partial<AppContextModel>) {
    setAppContext({
      ...appContext,
      ...ctx,
    })
  }
  return { ...appContext, patchContext: patchAppContext }
}

let dataInitialized = false

// const HOSPITAL_ROOM_IDS = [
//   '632b65f5df334fc2af3a0928',
//   '632b65f5df334fc2af3a0929',
//   '643d99f7298d1fddf9c634bd',
//   '63ed52bdc8ce7522a5e516d3',
//   '63ed53432ba8646e33befb38',
//   '63ed536bc8ce7522a5e516d4',
//   '63ed53aac8ce7522a5e516d5',
//   '63ed53bfc8ce7522a5e516d6',
//   '63ef92dbac7469ddde90bbbd',
//   '63ef92fbac7469ddde90bbbe',
//   '63ef9315ac7469ddde90bbbf',
//   '63ef93772dae62842421ef73',
//   '63ef939c2dae62842421ef74',
//   '63ef93bf2dae62842421ef75',
//   '63ef93d92dae62842421ef76',
//   '63ef93fb2dae62842421ef77',
// ]

const SCHOOL_ROOM_IDS = [
  '6446a21980d652d6406b90ea',
  '6446a23480d652d6406b90eb',
  '6446a24a80d652d6406b90ec',
  '6446a25a80d652d6406b90ed',
  '6446a27380d652d6406b90ee',
  '6446a29680d652d6406b90ef',
  '6446a2a680cf26662b63d9d4',
  '6446a2b480cf26662b63d9d5',
  '6446a2c280cf26662b63d9d6',
  '6446a2d080cf26662b63d9d7',
  '6446a2df80cf26662b63d9d8',
  '6446a2ff80cf26662b63d9d9',
  '6446a30f80d652d6406b90f0',
  '6446a32480d652d6406b90f1',
  '6446a35d80d652d6406b90f2',
  '6446a36e80d652d6406b90f3',
  '6446a38380d652d6406b90f4',
  '6446a39380d652d6406b90f5',
  '64484913f50701faf67c5bef',
  '644889cadc5f41d865b161b2',
]

function App() {
  const appContext = createAppContext()
  // Empty string is a falsy value and means "we don't know". Undefined is when logged out
  const [token, setToken] = React.useState<string | undefined>('')
  const [schoolRooms, setSchoolRooms] = React.useState<UvaRoom[]>([])
  const [uvaOfficeRooms, setUvaOfficeRooms] = React.useState<UvaRoom[]>([])
  const [themeMode, setThemeMode] = React.useState<PaletteMode>(() => {
    return (localStorage.getItem(THEME_MODE_KEY) ?? 'light') as PaletteMode
  })

  useEffect(() => {
    if (appContext.rooms && appContext.rooms.length > 0) {
      const schoolRooms = appContext.rooms.filter((r, i) => r && r.id ? SCHOOL_ROOM_IDS.includes(r.id) : false)
      const nonSchoolRooms = appContext.rooms.filter((r, i) => r && r.id ? !SCHOOL_ROOM_IDS.includes(r.id) : false)
      // console.log('school rooms!', schoolRooms)
      // console.log('hospital rooms!', hospitalRooms)
      setSchoolRooms(schoolRooms)
      setUvaOfficeRooms(nonSchoolRooms)
    } else {
      setSchoolRooms([])
      setUvaOfficeRooms([])
    }
  }, [appContext.rooms])

  const theme = createUvaTheme(themeMode)

  function loadAppData() {
    appContext.patchContext({ loading: true })
    Promise.all([PssqClient.getRooms(), PssqClient.getActivities(), PssqClient.getPathogens()])
      .then(([rooms, activities, pathogens]) => {
        // sort activities by enhancementRate
        activities.sort((a, b) => {
          return a.enhancementRate - b.enhancementRate
        })
        appContext.patchContext({
          loading: false,
          rooms,
          pathogens,
          activities,
        })
        dataInitialized = true
      })
      .catch((error) => console.error('Can not fetch basic application data!', error))
  }

  useEffect(() => {
    // Listen for changes
    AuthClient.addAuthListener((authenticated) => {
      setToken(AuthClient.getAccessToken())
      if (authenticated && !dataInitialized) {
        loadAppData()
      }
    })
  }, [])

  function toggleTheme() {
    let newMode: PaletteMode = 'light'

    if (themeMode === 'light') {
      newMode = 'dark'
    }

    setThemeMode(newMode)
    localStorage.setItem(THEME_MODE_KEY, newMode)
  }

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <div className='uva-app'>
          <AppContext.Provider value={appContext}>
            <BrowserRouter>
              <Routes>
                <Route path='/' element={<AppShell token={token} toggleTheme={toggleTheme} />}>
                  <Route index element={<Navigate to='/app/pssq-simulator' />} />
                  <Route path='/app/pssq-simulator' element={<PssqSimulatorPage />} />
                  <Route path='/app/lab-report' element={<LabReportPage rooms={uvaOfficeRooms} />} />
                  <Route path='/app/env-charts' element={<EnvChartsPage />} />
                  <Route path='/app/tracks' element={<TracksPage />} />
                  <Route path='/app/monitoring' element={<MonitoringPage mapToUse='HOSPITAL' rooms={uvaOfficeRooms} />} />
                </Route>
                <Route
                  path='/continuum/*'
                  element={<ContinuumShell token={token} toggleTheme={toggleTheme} rooms={schoolRooms} />}
                />
                <Route path='*' element={<Navigate to='/' />} />
              </Routes>
            </BrowserRouter>
          </AppContext.Provider>
        </div>
      </ThemeProvider>
    </>
  )
}

export default App
