import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import React from 'react'
import { UvaPathogen } from '../../../../services/api.models'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'

export interface PathogenEditDialogProps {
  initialPathogenData?: UvaPathogen
  onClose: (pathogen?: Partial<UvaPathogen>) => void
}

// eslint-disable-next-line func-style
const PathogenEditDialog: React.FC<PathogenEditDialogProps> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onBlur' })

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const pathogenId: string | undefined = props.initialPathogenData?.id || undefined
  async function submitWrapper(data): Promise<void> {
    const submitObj: UvaPathogen = {
      name: data.pedNameField,
      pathogenType: data.pedPathogenTypeField,
      decayRate: parseFloat(data.pedDecayRateField),
      depositionRate: parseFloat(data.pedDepositionRateField),
      quantaEmissionRate: parseFloat(data.pedQuantaEmissionRateField),
    }
    if (pathogenId) {
      submitObj['id'] = pathogenId
    }
    return await props.onClose(submitObj)
  }

  function upsertPathogen(data): void {
    setIsLoading(true)
    submitWrapper(data).then(() => {
      setIsLoading(false)
    })
  }
  return (
    <Dialog open={true} onClose={() => props.onClose()} id='UvaPathogenEditDialog'>
      <form onSubmit={handleSubmit(upsertPathogen)}>
        <DialogTitle>Pathogen</DialogTitle>
        <DialogContent>
          <TextField
            id='name'
            label='Name'
            type='text'
            defaultValue={props.initialPathogenData?.name}
            variant='filled'
            fullWidth
            error={errors.pedNameField ? true : false}
            helperText={errors.pedNameField ? errors.pedNameField.message?.toString() : ''}
            {...register('pedNameField', {
              required: { value: true, message: 'Name is required' },
              minLength: {
                value: 3,
                message: 'Name must be unique and at least 3 characters long',
              },
            })}
            className='uva-form-control'
          />
          <FormControl variant='filled' fullWidth className='uva-form-control'>
            <InputLabel id='pathogenType-label' error={errors.pedPathogenType ? true : false}>
              Pathogen Type
            </InputLabel>
            <Select
              id='PedPathogenType'
              labelId='pathogenType-label'
              defaultValue={props.initialPathogenData?.pathogenType.toUpperCase() || ''}
              variant='filled'
              fullWidth
              key='pedPathogenType'
              error={errors.pedPathogenType ? true : false}
              label='Pathogen Type'
              {...register('pedPathogenTypeField', {
                required: { value: true, message: 'Pathogen Type is required' },
              })}
            >
              <MenuItem value='' key='PathogenType-Unselected'>
                Select One
              </MenuItem>
              <MenuItem value='VIRUS' key='PathogenType-Virus'>
                Virus
              </MenuItem>
              <MenuItem value='BACTERIA' key='PathogenType-Bacteria'>
                Bacteria
              </MenuItem>
              <MenuItem value='FUNGUS' key='PathogenType-Fungus'>
                Fungus
              </MenuItem>
            </Select>
            <FormHelperText error={errors.pedPathogenType ? true : false}>
              {errors.pedPathogenType ? errors.pedPathogenType.message?.toString() : ''}
            </FormHelperText>
          </FormControl>
          <fieldset>
            <legend>Rate Attributes</legend>
            <TextField
              id='decayRate'
              label='Decay Rate'
              type='text'
              defaultValue={props.initialPathogenData?.decayRate}
              variant='filled'
              fullWidth
              {...register('pedDecayRateField', {
                required: { value: true, message: 'Decay Rate is required' },
                min: {
                  value: 0,
                  message: 'Decay Rate must be a non-negative number',
                },
                max: {
                  value: 1,
                  message: 'Decay Rate cannot be greater than 1',
                },
                pattern: {
                  value: /^([0-9]*[.])?[0-9]+$/,
                  message: 'Invalid value for Decay Rate',
                },
              })}
              error={errors.pedDecayRateField ? true : false}
              helperText={errors.pedDecayRateField?.message?.toString()}
              className='uva-form-control  uva-form-control-inline  uva-form-control-inline-sm'
            />
            <TextField
              id='depositionRate'
              label='Deposition Rate'
              type='text'
              defaultValue={props.initialPathogenData?.depositionRate}
              variant='filled'
              fullWidth
              {...register('pedDepositionRateField', {
                required: { value: true, message: 'Deposition Rate is required' },
                min: {
                  value: 0,
                  message: 'Deposition Rate must be a non-negative number',
                },
                max: {
                  value: 1,
                  message: 'Deposition Rate cannot be greater than 1',
                },
                pattern: {
                  value: /^([0-9]*[.])?[0-9]+$/,
                  message: 'Invalid value for Deposition Rate',
                },
              })}
              error={errors.pedDepositionRateField ? true : false}
              helperText={errors.pedDepositionRateField?.message?.toString()}
              className='uva-form-control  uva-form-control-inline  uva-form-control-inline-sm'
            />
            <TextField
              id='quantaEmissionRate'
              label='Quanta Emission Rate'
              type='text'
              defaultValue={props.initialPathogenData?.quantaEmissionRate}
              variant='filled'
              fullWidth
              {...register('pedQuantaEmissionRateField', {
                required: { value: true, message: 'Quanta Emission Rate is required' },
                min: {
                  value: 0,
                  message: 'Quanta Emission must be a non-negative number',
                },
                pattern: {
                  value: /^([0-9]*[.])?[0-9]+$/,
                  message: 'Invalid value for Quanta Emission Rate',
                },
              })}
              error={errors.pedQuantaEmissionRateField ? true : false}
              helperText={errors.pedQuantaEmissionRateField?.message?.toString()}
              className='uva-form-control  uva-form-control-inline  uva-form-control-inline-sm'
            />
          </fieldset>
        </DialogContent>
        <DialogActions className='uva-form-actions'>
          <Button variant='contained' color='secondary' onClick={() => props.onClose()}>
            Cancel
          </Button>
          <LoadingButton loading={isLoading} variant='contained' type='submit' disabled={!isValid}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default PathogenEditDialog
