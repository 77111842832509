import { pssq, UVAInsertOneResult, UVAUpdateResult } from '../app/uva-components'
import { UvaActivity, UvaPathogen, UvaRoom, UvaAlert, UvaPssqTrack } from './api.models'
import AuthClient from './auth.service'

type RawUvaPssqTrack = Omit<UvaPssqTrack, 'created_at' | 'last_pssq_calculation'> & {
  created_at: string
  last_pssq_calculation: {
    value: number
    occupancy: number
    timestamp: string
  }
  alerts: RawUvaAlert[]
}

export type InsertTrack = Omit<
  UvaPssqTrack,
  'id' | 'created_at' | 'last_pssq_calculation' | 'created_by_email'
>

type RawUvaAlert = Omit<UvaAlert, 'created_at' | 'activeAlertBegin' | 'activeAlertEnd'> & {
  created_at: string
  activeAlertBegin?: string
  activeAlertEnd?: string
}

export type InsertAlert = Omit<
  UvaAlert,
  'id' | 'created_at' | 'isActive' | 'created_by_email' | 'activeAlertBegin' | 'activeAlertEnd'
>

export type PssqTrackWithAlerts = UvaPssqTrack & { alerts: UvaAlert[] }

class PssqService {
  host = 'localhost'
  token: string | null = null

  get STD_HEADERS() {
    AuthClient.getAccessToken()
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${AuthClient.getAccessToken()}`,
    }
  }

  calculatePssq(props: {
    activityId: string
    roomId: string
    pathogenId: string
    occupants: number
    time: number
    previousQuantaDensity: number
  }): Promise<pssq> {
    const url =
      `/api/pssq/activityId/${props.activityId}/roomId/${props.roomId}/pathogenId/${props.pathogenId}` +
      `/occupants/${props.occupants}/time/${props.time}/previousQuantaDensity/${props.previousQuantaDensity}`
    return fetch(url, {
      method: 'GET',
      headers: this.STD_HEADERS,
    }).then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp)
      }
      return resp.json()
    })
  }

  getRooms(): Promise<UvaRoom[]> {
    const url = '/api/rooms'
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: this.STD_HEADERS,
    }).then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp)
      }
      return resp.json()
    })
  }

  getRoom(roomId: string): Promise<UvaRoom> {
    const url = `${this.host}/api/rooms/${roomId}`
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: this.STD_HEADERS,
    }).then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp)
      }

      return resp.json()
    })
  }

  updateRoom(room: UvaRoom) {
    if (!room.id) {
      throw new Error('Room ID is mandatory')
    }
    const url = `/api/rooms/${room.id}`

    return fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: this.STD_HEADERS,
      body: JSON.stringify(room),
    }).then((resp) => {
      if (!resp.ok) {
        console.error('Error updating Room', room, resp)
        return Promise.reject(resp)
      }

      return resp.json().then((res: UVAUpdateResult) => {
        if (res.acknowledged) {
          return Promise.resolve()
        } else {
          console.error('Error updating Room', room, res)
          return Promise.reject()
        }
      })
    })
  }

  insertRoom(room: UvaRoom) {
    const url = '/api/rooms'
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: this.STD_HEADERS,
      body: JSON.stringify(room),
    }).then((resp) => {
      if (!resp.ok) {
        console.error('Error inserting Room', room, resp)
        return Promise.reject(resp)
      }

      return resp.json().then((res: UVAInsertOneResult) => {
        if (res.acknowledged) {
          return {
            ...room,
            id: res.insertedId,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any as UvaRoom
        } else {
          console.error('Error inserting Room', room, res)
          return Promise.reject()
        }
      })
    })
  }

  getActivities(): Promise<UvaActivity[]> {
    const url = '/api/activities'
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: this.STD_HEADERS,
    }).then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp)
      }
      return resp.json()
    })
  }

  getActivity(activityId: string): Promise<UvaActivity> {
    const url = `${this.host}/api/activities/${activityId}`
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: this.STD_HEADERS,
    }).then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp)
      }
      return resp.json()
    })
  }

  getPathogens(): Promise<UvaPathogen[]> {
    const url = '/api/pathogens'
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: this.STD_HEADERS,
    }).then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp)
      }

      return resp.json()
    })
  }

  updatePathogen(pathogen: UvaPathogen) {
    if (!pathogen.id) {
      throw new Error('Pathogen ID is mandatory')
    }
    const url = `/api/pathogens/${pathogen.id}`
    return fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: this.STD_HEADERS,
      body: JSON.stringify(pathogen),
    }).then((resp) => {
      if (!resp.ok) {
        console.error('Error updating Pathogen', pathogen, resp)
        return Promise.reject(resp)
      }

      return resp.json().then((res: UVAUpdateResult) => {
        if (res.acknowledged) {
          return Promise.resolve()
        } else {
          console.error('Error updating Pathogen', pathogen, res)
          return Promise.reject()
        }
      })
    })
  }

  insertPathogen(pathogen: UvaPathogen) {
    const url = '/api/pathogens'
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: this.STD_HEADERS,
      body: JSON.stringify(pathogen),
    }).then((resp) => {
      if (!resp.ok) {
        console.error('Error inserting Pathogen', pathogen, resp)
        return Promise.reject(resp)
      }

      return resp.json().then((res: UVAInsertOneResult) => {
        if (res.acknowledged) {
          return {
            ...pathogen,
            id: res.insertedId,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any as UvaPathogen
        } else {
          console.error('Error inserting Pathogen', pathogen, res)
          return Promise.reject()
        }
      })
    })
  }

  calcCleanAirDeviceMode(req: {
    roomId: string
    pathogenId: string
    activityId: string
    occupancy: number
    timeDurationMinutes: number
    previousQuantaDensity: number
    assumedInfectionRate: number
    targetQuantaDensity: number
    remediationDeviceId: string
    uvaRemediationDevices: {
      id: string
      name: string
      currentCADR: number
      maxCADR: number
    }[]
  }): Promise<{
    quantaDensity: number
    deviceInstructions: {
      deviceId: string
      deviceCADR: number
      requiredCADR: number
      overloaded: boolean
      occupancyOverloadThreshold: number
    }
    stats: {
      airVolume: number
      occupancy: number
      assumedInfectionRate: number
      quantaExhalationRatePerPerson: number
      targetQuantaDensity: number
      environmentalCADR: {
        decay: number
        deposition: number
      }
      remediationDeviceCADR: {
        device: string
        currentCADR: number
      }[]
      currentCADR: number
      minimumDesiredCADR: number
    }
  }> {
    const url = '/api/pssq/clean-air-device-mode'
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: this.STD_HEADERS,
      body: JSON.stringify(req),
    }).then((resp) => {
      if (!resp.ok) {
        console.error('Error calling /pssq/clean-air-device-mode', req, resp)
        return Promise.reject(resp)
      }

      return resp.json()
    })
  }

  getLabReport(req: {
    startTime: Date
    endTime: Date
    // interval in seconds
    interval: number
    roomId: string
    dataDeviceSerialNumbers?: string[]
    pssqOptions?: {
      pathogenId: string
      activityId: string
      assumedInfectionRate: number
      targetQuantaDensity: number
      cadrVentAch?: number
      algorithmsToCompute?: string[]
      airPathogenRemediationDevices?: { numDevices: number; cadrPerDevice: number }
    }
  }): Promise<
    {
      timestamp: Date
      environment_metrics: {
        pir_min: number
        pir_max: number
        volume: number
        volume_avg_rms: number
        volume_max_rms: number
        volume_min_rms: number
        ain1: number
        ain2: number
        prs_temp: number
        prs_pres: number
        scd_co2: number
        scd_temp: number
        scd_hum: number
        sgp_temp: number
        sgp_hum: number
        sgp_voc: number
        bsec_out_iaq: number
        bsec_out_iaq_acc: number
        bsec_stat_iaq: number
        bsec_co2_eq: number
        bsec_br_voc_eq: number
        bsec_raw_temp: number
        bsec_raw_pres: number
        bsec_raw_hum: number
        bsec_raw_gas: number
        bsec_stab_stat: number
        bsec_run_in_stat: number
        bsec_heat_comp_temp: number
        bsec_heat_comp_hum: number
        bsec_comp_gas: number
        bsec_gap_percent: number
        oxygen: number
      }
      occupancy?: number
      pssqOccupancy?: {
        stats: {
          airVolume: number
          occupancy: number
          assumedInfectionRate: number
          quantaExhalationRatePerPerson: number
          targetQuantaDensity: number
          environmentalCADR: {
            decay: number
            deposition: number
          }
          remediationDeviceCADR: {
            device: string
            currentCADR: number
          }[]
          currentQuantaEmissionRate: number
          currentCADR: number
          minimumDesiredCADR: number
        }
        quantaDensity: number
      },
      pssqCo2?: {
        stats: {
          algoVersion: string
          airVolume: number
          assumedInfectionRate: number
          baselineCO2Mgft3: number
          targetQuantaDensity: number
          environmentalCADR: {
            decay: number
            deposition: number
          }
          remediationDeviceCADR: {
            device: string
            currentCADR: number
          }[]
          currentQuantaEmissionRate: number
          currentCADR: number
          minimumDesiredCADR: number
        }
        quantaDensity: number
      },
      pssqCo2History: {
        // there are also stats here but we ignore/do not need them for now
        quantaDensity: number,
        interval: number,
      },
      pssqCo2PlusDb: {
        quantaDensity: number,
        interval: number,
        stats: {
          algoVersion: string
          airVolume: number
          assumedInfectionRate: number
          baselineCO2Mgft3: number
          targetQuantaDensity: number
          environmentalCADR: {
            decay: number
            deposition: number
          }
          remediationDeviceCADR: {
            device: string
            currentCADR: number
          }[]
          currentQuantaEmissionRate: number
          currentCADR: number
          minimumDesiredCADR: number
        }
      }
    }[]
  > {
    const url = '/api/pssq/report'
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: this.STD_HEADERS,
      body: JSON.stringify(req),
    }).then((resp) => {
      if (!resp.ok) {
        console.error('Error calling /pssq/report', req, resp)
        return Promise.reject(resp)
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return resp.json().then((json: any[]) => {
        json.forEach((rec) => (rec.timestamp = new Date(rec.timestamp)))
        return json
      })
    })
  }

  private convertFromRawTrack(track: RawUvaPssqTrack): PssqTrackWithAlerts {
    return {
      ...track,
      created_at: new Date(track.created_at),
      last_pssq_calculation: track.last_pssq_calculation
        ? {
          ...track.last_pssq_calculation,
          timestamp: new Date(track.last_pssq_calculation.timestamp),
        }
        : undefined,
      alerts: track.alerts ? track.alerts.map(a => this.convertFromRawAlert(a)) : []
    }
  }

  private convertFromRawAlert(alert: RawUvaAlert): UvaAlert {
    return {
      ...alert,
      created_at: new Date(alert.created_at),
      activeAlertBegin: alert.activeAlertBegin ? new Date(alert.activeAlertBegin) : undefined,
      activeAlertEnd: alert.activeAlertEnd ? new Date(alert.activeAlertEnd) : undefined,
    }
  }

  getTracks(): Promise<PssqTrackWithAlerts[]> {
    const url = '/api/tracks'
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: this.STD_HEADERS,
    }).then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp)
      }
      return resp.json().then((rawRes: RawUvaPssqTrack[]) => {
        const res: PssqTrackWithAlerts[] = []
        rawRes.forEach((track) => res.push(this.convertFromRawTrack(track)))
        return res
      })
    })
  }

  getAlerts(): Promise<UvaAlert[]> {
    const url = '/api/alerts'
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: this.STD_HEADERS,
    }).then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp)
      }
      return resp.json().then((rawRes: RawUvaAlert[]) => {
        const res: UvaAlert[] = []
        rawRes.forEach((alert) => res.push(this.convertFromRawAlert(alert)))
        return res
      })
    })
  }

  insertTrack(track: InsertTrack) {
    const url = '/api/tracks'
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: this.STD_HEADERS,
      body: JSON.stringify(track),
    }).then((resp) => {
      if (!resp.ok) {
        console.error('Error inserting track', track, resp)
        return Promise.reject(resp)
      }

      return resp.json().then((rawTrack) => this.convertFromRawTrack(rawTrack))
    })
  }

  insertAlert(alert: InsertAlert) {
    const url = '/api/alerts'
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: this.STD_HEADERS,
      body: JSON.stringify(alert),
    }).then((resp) => {
      if (!resp.ok) {
        console.error('Error inserting alert', alert, resp)
        return Promise.reject(resp)
      }

      return resp.json().then((rawAlert) => this.convertFromRawAlert(rawAlert))
    })
  }

  deleteAlert(alertId: string) {
    const url = '/api/alerts/' + alertId
    return fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: this.STD_HEADERS,
    }).then((resp) => {
      if (!resp.ok) {
        console.error('Error deleting alert', alertId, resp)
        return Promise.reject(resp)
      }

      return resp.json().then(({ deleted }) => {
        if (!deleted) {
          console.error('Error deleting alert', alertId, resp)
          return Promise.reject('Error deleting alert')
        }

        return Promise.resolve()
      })
    })
  }

  deleteTrack(trackId: string) {
    const url = '/api/tracks/' + trackId
    return fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: this.STD_HEADERS,
    }).then((resp) => {
      if (!resp.ok) {
        console.error('Error deleting track', trackId, resp)
        return Promise.reject(resp)
      }

      return resp.json().then(({ deleted }) => {
        if (!deleted) {
          console.error('Error deleting track', trackId, resp)
          return Promise.reject('Error deleting track')
        }

        return Promise.resolve()
      })
    })
  }

}

const PssqClient = new PssqService()

export default PssqClient
