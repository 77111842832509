import { Button, Card, CardContent, CardHeader, Grid, Skeleton } from '@mui/material'
import React from 'react'
import { AppContext } from '../../../../app/App.context'

export interface FixedParamsTableProps {
  pathogenId?: string
  roomId?: string
  infectionRate?: number
  maxQuantaDensity?: number
  airHandlerFlowRate?: number
  newSimulation: () => void
}
//eslint-disable-next-line func-style
const FixedParamsTable: React.FC<FixedParamsTableProps> = (props) => {
  const appContext = React.useContext(AppContext)
  return appContext.loading ? (
    <Skeleton className='uva-skeleton uva-skeleton-panel' variant='rectangular' />
  ) : (
    <Card className='uva-params-panel' id='UvaFixedParamsTable'>
      <CardHeader title='Fixed Simulation Parameters' />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={7}>
            Pathogen
          </Grid>
          <Grid item xs={5}>
            {appContext.pathogens.find((p) => p.id === props.pathogenId)?.name}
          </Grid>
          <Grid item xs={7}>
            Infection Rate
          </Grid>
          <Grid item xs={5}>
            {props.infectionRate}%
          </Grid>
          <Grid item xs={7}>
            Max Quanta Density
          </Grid>
          <Grid item xs={5}>
            {props.maxQuantaDensity}
          </Grid>
          <Grid item xs={7}>
            Room Volume
          </Grid>
          <Grid item xs={5}>
            {appContext.rooms.find((r) => r.id === props.roomId)?.volume} ft<sup>3</sup>
          </Grid>
          <Grid item xs={7}>
            Room Air Handler Flow Rate
          </Grid>
          <Grid item xs={5}>
            {appContext.rooms.find((r) => r.id === props.roomId)?.airHandlerFlowRate} cfm
          </Grid>
          <Grid item xs={7}>
            Max Occupancy
          </Grid>
          <Grid item xs={5}>
            {appContext.rooms.find((r) => r.id === props.roomId)?.maxOccupancy}
          </Grid>
          <Grid item xs={7}>
            Room HVAC On Time %
          </Grid>
          <Grid item xs={5}>
            {appContext.rooms.find((r) => r.id === props.roomId)?.hvacOnTimePercentage} %
          </Grid>
          <Grid item xs={7}>
            Room Pathogen Removal Efficiency
          </Grid>
          <Grid item xs={5}>
            {
              appContext.rooms.find((r) => r.id === props.roomId)
                ?.airHandlerPathogenRemovalEfficiency
            }{' '}
            %
          </Grid>
          <Grid item xs={7}>
            UVA Devices
          </Grid>
          <Grid item xs={5}>
            {appContext.rooms.find((r) => r.id === props.roomId)?.deviceSerialNumbers?.join(', ')}
          </Grid>
          <Grid item xs={12}>
            <div className='uva-form-actions'>
              <Button onClick={props.newSimulation} variant='contained'>
                New Simulation
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default FixedParamsTable
