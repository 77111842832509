/* eslint-disable no-magic-numbers */
import React from 'react'
import { Bar } from 'react-chartjs-2'
import chroma from 'chroma-js'
import { Card, CircularProgress, Typography, CardMedia, Box, CardContent } from '@mui/material'

import type { EnergyUsageResponseType } from '../../../services/continuum.service'

interface EnergyData {
  actualCost: number
  theoreticalMaxCost: number
  date: string
}

interface EnergyDataReducer {
  actualCostData: number[]
  maxCostData: number[]
  labels: string[]
  totalEnergyCostSaved: number
}

type Props = { data: EnergyUsageResponseType | null }

export default function DynamicTreatmentEnergySavingsCard({ data }: Props) {
  const energyData = data?.dailyCostData ?? []
  const { actualCostData, maxCostData, labels, totalEnergyCostSaved }: EnergyDataReducer =
    energyData.reduce(
      (accumulator: EnergyDataReducer, data: EnergyData) => {
        const { actualCost, theoreticalMaxCost, date } = data

        accumulator.actualCostData.push(actualCost)
        accumulator.maxCostData.push(theoreticalMaxCost - actualCost)
        accumulator.labels.push(
          new Date(date).toLocaleDateString('en', { month: 'short', day: '2-digit' }),
        )
        accumulator.totalEnergyCostSaved += theoreticalMaxCost - actualCost

        return accumulator
      },
      { actualCostData: [], maxCostData: [], labels: [], totalEnergyCostSaved: 0 },
    )
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
          <Typography gutterBottom variant='h5' component='div'>
            Dynamic Treatment - Energy Savings
          </Typography>
          {totalEnergyCostSaved === 0 ? null : (
            <Typography variant='h4' sx={{ marginLeft: 'auto' }}>
              {totalEnergyCostSaved.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </Typography>
          )}
        </Box>
        <Typography variant='body2' color='text.secondary'>
          Your air treatment devices are constantly adapting to your environment. This allows for
          some significant energy savings as opposed to full time usage.
        </Typography>
      </CardContent>
      <CardMedia>
        <Box
          height={300}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          {data ? (
            <Bar
              data={{
                labels: labels,
                datasets: [
                  {
                    data: actualCostData,
                    label: 'Energy Usage Cost',
                    borderColor: '#0093c8',
                    backgroundColor: '#0093c8',
                  },
                  {
                    data: maxCostData,
                    borderColor: chroma('gray').alpha(0.3).hex(),
                    borderWidth: 3,
                    backgroundColor: 'transparent',
                  },
                ],
              }}
              options={{
                indexAxis: 'x',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false },
                  tooltip: {
                    filter(e) {
                      return e.datasetIndex === 0
                    },
                  },
                },
                scales: {
                  x: {
                    display: true,
                    grid: {
                      display: false,
                    },
                    stacked: true,
                  },
                  y: {
                    display: true,
                    grid: {
                      display: false,
                    },
                    stacked: true,
                  },
                },
              }}
            />
          ) : (
            <CircularProgress size={75} />
          )}
        </Box>
      </CardMedia>
    </Card>
  )
}
