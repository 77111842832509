import React from 'react'
import LoginForm from '../../components/auth/login-form.component'

interface SignInPageProps {
  handleLogin: () => Promise<void>
}

// eslint-disable-next-line func-style
const SignInPage: React.FC<SignInPageProps> = (props) => {
  return <LoginForm handleLogin={props.handleLogin} />
}

export default SignInPage
