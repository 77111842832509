// eslint indent: 0
import React, { useContext, useEffect } from 'react'
import { Grid, Skeleton, Paper } from '@mui/material'
import { AppContext } from '../../app/App.context'
import { MongoChart } from '../../components/mongo-chart/mongo-chart.component'
import AuthClient from '../../services/auth.service'
import EnvChartPicker, {
  EnvChartPickerChangeEvent,
  SelectableChart,
} from './components/env-chart-picker/env-chart-picker.component'

const MILLISECONDS_PER_MINUTE = 60000
const MONGO_CHART_BASE_URL = 'https://charts.mongodb.com/charts-uvangel-web-oqmaj'
const FORCE_REFRESH_CHARTS_INTERVAL_MS = MILLISECONDS_PER_MINUTE * 30 // 30 minutes (token lasts for 1 hour)

type EnvChartsState = EnvChartPickerChangeEvent

// eslint-disable-next-line func-style
const EnvChartsPage: React.FC<unknown> = () => {
  const appContext = useContext(AppContext)
  const [state, setState] = React.useState<EnvChartsState>({})
  const [showingCharts, setShowingCharts] = React.useState<boolean>(true)

  function onChartPickerChange(evt: EnvChartPickerChangeEvent) {
    let chartIds = [...(evt.chartIds || [])]
    const deviceIds = [...(evt.deviceIds || [])]

    if (
      (state.deviceIds?.length === 1 && deviceIds.length > 1) ||
      ((state.deviceIds || []).length > 1 && deviceIds.length === 1)
    ) {
      // reset charts when selected devices induce swap of available charts
      chartIds = []
    }

    setState({ ...evt, deviceIds, chartIds })
  }

  useEffect(() => {
    // start off this page by forcing a token refresh, to restart our expiration time
    AuthClient.manuallyRefreshSession()
    const interval = setInterval(async () => {
      setShowingCharts(false)
      await AuthClient.manuallyRefreshSession()
      setShowingCharts(true)
    }, FORCE_REFRESH_CHARTS_INTERVAL_MS)
    return () => clearInterval(interval)
  }, [])

  return (
    <div id='UvaEnvChartPage'>
      <h1 className='uva-page-title'>Environmental Report</h1>

      <Grid container spacing={2} id='UvaEnvCharts'>
        <Grid item xs={12} md={4} xl={3}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            {appContext.loading ? (
              <Skeleton className='uva-skeleton uva-skeleton-panel' variant='rectangular' />
            ) : (
              <Paper elevation={4}>
                <EnvChartPicker
                  availableCharts={
                    state.deviceIds?.length === 1 ? SINGLE_DEVICE_CHARTS : MULTI_DEVICE_CHARTS
                  }
                  onChange={(evt) => onChartPickerChange(evt)}
                ></EnvChartPicker>
              </Paper>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} xl={9}>
          <Paper elevation={2} sx={{ padding: 2, minHeight: 450 }}>
            {state.roomId &&
            state.startTime &&
            state.endTime &&
            state.chartIds?.length &&
            showingCharts ? (
                state.chartIds.map((chartId) => {
                  return (
                    <Paper elevation={4} key={chartId} sx={{margin: 2}}>
                      <MongoChart
                        baseUrl={MONGO_CHART_BASE_URL}
                        chartId={chartId}
                        enableDownload={true}
                        chartName={
                          SINGLE_DEVICE_CHARTS.concat(MULTI_DEVICE_CHARTS).find((c) => c.id === chartId)
                            ?.name
                        }
                        filter={{
                          'metadata.room_ids': { $oid: state.roomId },
                          $and: [
                            {
                              timestamp: { $gte: state.startTime },
                            },
                            {
                              timestamp: { $lte: state.endTime },
                            },
                          ],
                          'metadata.device_id': { $in: state.deviceIds || [] },
                        }}
                        height='500px'
                        key={chartId}
                      />
                    </Paper>
                  )
                })
              ) : (
                <></>
              )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export const MULTI_DEVICE_CHARTS: SelectableChart[] = [
  {
    id: '561a45f9-bfab-41ff-88f8-de6bf657305d',
    name: 'Quanta per Minute',
  },
  {
    id: '4f6afdd3-5383-4835-8094-14f6efdc4d17',
    name: 'Temperature per Minute',
  },
  {
    id: '63330c3b-e42c-40d2-814d-6c1292028605',
    name: 'CO2 per Minute',
  },
  {
    id: '7028ff1b-ff7b-4d43-bf31-f052f52f5a44',
    name: 'VOC per Minute',
  },
  {
    id: 'e4f1e044-5421-438d-b004-9249afa70b2b',
    name: 'Volume per Minute',
  },
  {
    id: '30a2d494-650f-4050-a804-622083651e2e',
    name: 'IAQ per Minute',
  },
  {
    id: 'e1a0c9f4-1cf4-424a-b9f3-3c377798ec85',
    name: 'Pressure per Minute',
  },
  {
    id: 'da4e6aa7-9e6d-4f96-b4fd-441d79dba29d',
    name: 'Gas per Minute',
  },
]

const SINGLE_DEVICE_CHARTS = [
  {
    id: 'eb74d20f-cc31-4846-bcc0-9ace97b15ed5',
    name: 'CO2 per Second',
  },
  {
    id: '632e02f3-4c44-4f5c-8ed4-d44deea52db1',
    name: 'Temperature per Second',
  },
  {
    id: 'e9b2b12f-4728-42f7-9af2-2794e6c0c3a8',
    name: 'VOC per Second',
  },
  {
    id: '632e0011-e42c-4900-869a-6c12920f7068',
    name: 'PIR per Second',
  },
  {
    id: '632dfe35-3ec8-4335-89a5-03072c6ee964',
    name: 'Volume per Second',
  },
  {
    id: 'a35bf3a2-2e87-416a-9fce-d72a0f00c0fb',
    name: 'IAQ per Second',
  },
  {
    id: '58513a65-ba6f-4fbd-ada5-9ef6b17ac282',
    name: 'Pressure per Second',
  },
  {
    id: '3edcb4c2-e8f4-4624-8460-57467ad5c278',
    name: 'Gas per Second',
  },
]

export default EnvChartsPage
