import { Box, Card, CardContent, CardMedia, CssBaseline, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Switch, Typography, useTheme } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter, Link, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import AuthClient from '../../services/auth.service'
import SignInPage from '../sign-in/sign-in-page.component'
import AppFooter from './components/footer/app-footer.component'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MapIcon from '@mui/icons-material/Map'
import NotificationsIcon from '@mui/icons-material/Notifications'
import InsightsIcon from '@mui/icons-material/Insights'
import { MonitoringOverviewPage } from '../monitoring/monitoring-overview-page.component'
import { MonitoringPage } from '../monitoring/monitoring-page.component'
import LabReportPage from '../lab-report/lab-report-page.component'
import darkModeLogo from '../../assets/dark/logo_horizontal.svg'
import lightModeLogo from '../../assets/light/logo_horizontal.svg'
import { DarkMode, LightMode, Logout, Support } from '@mui/icons-material'
import { AppContext } from '../../app/App.context'
import { UvaRoom } from '../../services/api.models'

export interface ContinuumShellProps {
  token?: string
  toggleTheme: () => void
  useContinuumBranding?: boolean
  rooms: UvaRoom[]
}

const drawerWidth = 300
const drawerLinks = [
  { text: 'Overview', icon: <DashboardIcon />, locationPath: '/continuum/overview' },
  { text: 'Floors', icon: <MapIcon />, locationPath: '/continuum/floors' },
  // Removing for continuum demo
  // { text: 'Notifications', icon: (<NotificationsIcon />), locationPath: '/continuum/notifications' },
  { text: 'Experiments', icon: <InsightsIcon />, locationPath: '/continuum/experiments' },
]

let logo

function handleLogout() {
  AuthClient.handleLogout()
}

// eslint-disable-next-line func-style
const ContinuumShell: React.FC<ContinuumShellProps> = ({ token, toggleTheme, rooms }) => {
  const theme = useTheme()
  const location = useLocation()
  logo = theme.palette.mode === 'dark' ? darkModeLogo : lightModeLogo

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {token === undefined || token === '' ? (
        <SignInPage handleLogin={AuthClient.handleLogin} />
      ) : (
        <>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant='permanent'
            anchor='left'
          >
            <CardMedia
              component='img'
              height='140'
              sx={{ objectFit: 'contain', padding: 4, marginTop: -2 }}
              image={logo}
              alt='UV Angel Logo'
            />
            <Typography
              variant='h5'
              sx={{ textAlign: 'center', marginTop: -3, paddingBottom: 3 }}
              component='div'
            >
              Continuum
            </Typography>
            <Divider />
            <List>
              {drawerLinks.map((item, index) => {
                let active = false
                if (item.locationPath === location.pathname) {
                  active = true
                }
                return (
                  <ListItem key={item.text}>
                    <ListItemButton selected={active} component={Link} to={item.locationPath}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
            <div style={{ marginTop: 'auto' }}>
              <ListItem>
                <ListItemButton component='div'>
                  <ListItemIcon>
                    <Support />
                  </ListItemIcon>
                  <ListItemText primary={'Support'} />
                </ListItemButton>
              </ListItem>
              <Divider />
              <List>
                <ListItem>
                  <ListItemButton component='div'>
                    <ListItemIcon>
                      {theme.palette.mode === 'dark' ? <DarkMode /> : <LightMode />}
                    </ListItemIcon>
                    <ListItemText primary={'Theme'} />
                    <ListItemSecondaryAction>
                      <Switch checked={theme.palette.mode === 'dark'} onChange={toggleTheme} />
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton component='div' onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout />
                    </ListItemIcon>
                    <ListItemText primary={'Sign Out'} />
                  </ListItemButton>
                </ListItem>
              </List>
            </div>
          </Drawer>
          <Box
            component='main'
            sx={{
              flexShrink: 1,
              flexGrow: 1,
            }}
          >
            <Routes>
              <Route path='/overview' element={<MonitoringOverviewPage rooms={rooms} />} />
              <Route path='/floors' element={<MonitoringPage mapToUse='SCHOOL' rooms={rooms} />} />
              <Route path='/experiments' element={<LabReportPage rooms={rooms} />} />
              <Route path='*' element={<Navigate to='/continuum/overview' />} />
            </Routes>
          </Box>
        </>
      )}
    </Box>
  )
}

export default ContinuumShell
