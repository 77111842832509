/* eslint-disable no-magic-numbers */
/* eslint-disable react/prop-types */
import { useTheme } from '@mui/material'
import { FillPaint, LinePaint } from 'mapbox-gl'
import { PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Map, { FullscreenControl, Layer, LngLatBoundsLike, MapLayerMouseEvent, MapRef, NavigationControl, Source } from 'react-map-gl'

const MAPBOX_AUTH_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

// max "width"
const LNG_MAX = 90
// max "height"
const LAT_MAX = 45
const MAP_RATIO = LNG_MAX / LAT_MAX

const DEFAULT_PITCH = 45
const DEFAULT_ZOOM = 3.6

// technique based on https://jsfiddle.net/2mws8y3q/
// an array of valid line-dasharray values, specifying the lengths of the alternating dashes and gaps that form the dash pattern
const DASH_ARRAY_SEQUENCE = [
  [0, 4, 3],
  [0.5, 4, 2.5],
  [1, 4, 2],
  [1.5, 4, 1.5],
  [2, 4, 1],
  [2.5, 4, 0.5],
  [3, 4, 0],
  [0, 0.5, 3, 3.5],
  [0, 1, 3, 3],
  [0, 1.5, 3, 2.5],
  [0, 2, 3, 2],
  [0, 2.5, 3, 1.5],
  [0, 3, 3, 1]
]

export interface ImdfProps {
  // Room id. Mapbox doesn't support string ids.
  roomId: string,
  roomName?: string,
  // value to be printed next to the room name
  value?: string,
  // if true, no room name & value will be printed
  hideValue?: boolean,
  hasSubrooms?: boolean,
  // A point representing visual center of the feature. A good place to put a marker on.
  displayPoint?: {
    coordinates: GeoJSON.Position,
  },
}

export type ImdfFeature<G extends GeoJSON.Geometry, P extends ImdfProps = ImdfProps> = GeoJSON.Feature<G, P>

export interface FloorMapProps {
  floorPlanUrl: string,
  floorPlanWidth: number,
  floorPlanHeight: number,
  roomFeatures: ImdfFeature<any>[],
  selectedRoomIds?: string[],
  onSelectRoom?: (roomId: string) => void,
  // TODO: how to restrict this to be an instance of FloorSwitcher?
  floorSwitcher?: ReactNode,
}

/**
 * Look through all features' geometries and find a rectangle/bound that contains all points.
 */
function findBounds<G extends GeoJSON.Geometry>(features: ImdfFeature<G>[]): LngLatBoundsLike {
  let lngLow = 180, lngHigh = -180, latLow = 90, latHigh = -90

  function considerPosition(pos: GeoJSON.Position) {
    if (pos[0] < lngLow) {
      lngLow = pos[0]
    }
    if (pos[0] > lngHigh) {
      lngHigh = pos[0]
    }
    if (pos[1] < latLow) {
      latLow = pos[1]
    }
    if (pos[1] > latHigh) {
      latHigh = pos[1]
    }
  }

  function considerGeometry(geometry: GeoJSON.Geometry) {
    switch (geometry?.type) {
      case 'Point':
        considerPosition(geometry.coordinates)
        break
      case 'Polygon':
        geometry.coordinates.forEach(posArray => posArray.forEach(pos => considerPosition(pos)))
        break
      case 'LineString':
        geometry.coordinates.forEach(pos => considerPosition(pos))
        break
      case 'MultiPoint':
        geometry.coordinates.forEach(pos => considerPosition(pos))
        break
      case 'MultiLineString':
        geometry.coordinates.forEach(posArray => posArray.forEach(pos => considerPosition(pos)))
        break
      case 'MultiPolygon':
        geometry.coordinates.forEach(polyArray => polyArray.forEach(posArray => posArray.forEach(pos => considerPosition(pos))))
        break
      default:
        // ignore unknown geometry
    }
  }
  features.forEach(feature => considerGeometry(feature.geometry))

  const verticalOffset = (latHigh - latLow) * .333
  return [lngLow, latLow - verticalOffset, lngHigh, latHigh]
}


/**
 * A floor map component that
 * 1) Displays an image as the floor plan
 * 2) Shows rooms above given floor plan
 * 3) Highlights rooms that are selected
 * 
 * It can also emit an event when user clicks on some room
 */
// eslint-disable-next-line func-style
export const FloorMap: React.FC<PropsWithChildren<FloorMapProps>> = (props) => {
  const theme = useTheme()
  const mapRef = useRef<MapRef>(null)
  const [animationStep, setAnimationStep] = useState<number>(0)
  const animationStepRef = useRef(animationStep)

  const onMapClick = useCallback((event: MapLayerMouseEvent) => {
    // I use this when manually mapping a floor plan
    // console.log(`[${event.lngLat.lng}, ${event.lngLat.lat}],`)
    // navigator.clipboard.writeText(`[${event.lngLat.lng}, ${event.lngLat.lat}],`).then(
    // eslint-disable-next-line no-alert
    //  () => alert(`[${event.lngLat.lng}, ${event.lngLat.lat}],`)
    // )
    if (props.onSelectRoom && event.features?.length) {
      // There is a bug(?) in mapbox and features contain duplicate rooms. Remove duplicates first.
      const dedupedFeatures: ImdfFeature<any>[] = Object.values(event.features.reduce((prev, feature) => {
        prev[feature.id!] = feature
        return prev
      }, {}))

      // If there is only 1 feature then select it. If there are multiple then select that one
      // that is not marked as having subrooms. That is a case of clicking on a room inside a floor.
      // If we do not do this users would not be able to click on rooms inside floors and would always select floors only
      if (dedupedFeatures.length === 1) {
        props.onSelectRoom((dedupedFeatures[0].properties as ImdfProps).roomId)
      } else {
        const rooms = dedupedFeatures.filter(feature => !feature.properties.hasSubrooms)
        if (rooms.length) {
          props.onSelectRoom((rooms[0].properties as ImdfProps).roomId)
        }  
      }
    }
  }, [props])

  const selectedRoomFilter = useMemo(() => ['in', 'roomId', ...(props.selectedRoomIds || [])], [props.selectedRoomIds])

  // translate floor plan dimensions into longitude(x)/latitude(y) while keeping aspect ratio
  const origRatio = props.floorPlanWidth / props.floorPlanHeight
  const widthLng = origRatio > MAP_RATIO
    ? LNG_MAX
    : props.floorPlanWidth / props.floorPlanHeight * LAT_MAX
  const heightLat = origRatio > MAP_RATIO
    ? props.floorPlanHeight / props.floorPlanWidth * LNG_MAX
    : LAT_MAX

  // map bounds allow panning around the floor plan for half the map width/height
  const mapBounds = useMemo(() => ([
    [0 - widthLng / 2, 0 - heightLat / 2],
    [widthLng * 1.5, heightLat * 1.5],
  ] as LngLatBoundsLike), [widthLng, heightLat])

  const roomIds = props.roomFeatures.map(r => r.properties.roomId).join('')

  // This effect resets the map to contain the whole floor centered in the view
  useEffect(() => {
    mapRef.current?.fitBounds(findBounds(props.roomFeatures), { pitch: DEFAULT_PITCH, zoom: DEFAULT_ZOOM })
  // An easy cop-out but we really do want to trigger only when the set of rooms change.
  // The roomFeatures gets new value also when selected environment metric changes
  // but this effect is only interested in room geometry and we assume that geometry
  // is unchanged if roomIds stays the same.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomIds, props.selectedRoomIds])

  useEffect(() => {
    function animateDashArray(timestamp: number) {
      // Update line-dasharray using the next value in DASH_ARRAY_SEQUENCE. The
      // divisor in the expression `timestamp / 50` controls the animation speed.
      const newStep = Math.floor((timestamp / 50) % DASH_ARRAY_SEQUENCE.length)

      if (newStep !== animationStepRef.current) {
        setAnimationStep(newStep)
        animationStepRef.current = newStep
      }
      
      // Request the next frame of the animation.
      requestAnimationFrame(animateDashArray)
    }

    animateDashArray(0)
  }, [])

  const [viewState, setViewState] = useState({ pitch: DEFAULT_PITCH, zoom: DEFAULT_ZOOM })

  return (
    <Map
      ref={mapRef}
      onClick={onMapClick}
      mapboxAccessToken={MAPBOX_AUTH_TOKEN}
      initialViewState={{
        bounds: findBounds(props.roomFeatures)
      }}
      {...viewState}
      onMove={evt => setViewState({ pitch: evt.viewState.pitch, zoom: evt.viewState.zoom })}
      maxBounds={mapBounds}
      style={{
        width: '100%',
        height: 600,
      }}
      mapStyle="mapbox://styles/mapbox/empty-v8"
      interactiveLayerIds={[ROOMS_LAYER_ID, SELECTED_ROOMS_LAYER_ID]}
    >
      <Source
        id='floor-plan-source'
        type='image'
        // top left, top right, bottom right, bottom left
        coordinates={[
          // These coords are dimensions of the floor plan divided by 100.
          // It is important to keep the aspect ratio of the original image.
          [0, heightLat],
          [widthLng, heightLat],
          [widthLng, 0],
          [0, 0]
        ]}
        url={props.floorPlanUrl}>
        <Layer
          id={FLOOR_PLAN_LAYER_ID}
          source='floor-plan-source'
          type='raster'
          paint={{
            'raster-fade-duration': 0
          }}></Layer>
        <Layer
          id={BACKGROUND_LAYER_ID}
          type='background'
          beforeId={FLOOR_PLAN_LAYER_ID}
          paint={{
            'background-color': 'transparent'
          }}
        />
      </Source>
      <Source
        id='room-source'
        promoteId='roomId'
        type='geojson'
        data={{
          type: 'FeatureCollection',
          features: props.roomFeatures,
        }}
      >
        <Layer
          // Layer with all the rooms
          id={ROOMS_LAYER_ID}
          source='room-source'
          type='fill'
          paint={ROOM_PAINT} />
        <Layer
          // Layer for highlighting the selected room(s)
          id={SELECTED_ROOMS_LAYER_ID}
          source='room-source'
          type='fill'
          filter={selectedRoomFilter}
          paint={SELECTED_PAINT}/>
        <Layer
          // Fill layers can not specify width of their border outline so I introduced extra line layer just for the
          // ability to specify width of selected room border width
          id={SELECTED_ROOMS_OUTLINE_LAYER_ID}
          source='room-source'
          type='line'
          beforeId={SELECTED_ROOMS_LAYER_ID}
          filter={selectedRoomFilter}
          paint={{
            ...SELECTED_OUTLINE_PAINT,
            'line-dasharray': DASH_ARRAY_SEQUENCE[animationStep]
          }}/>
        <Layer
          // This layer combined with SELECTED_ROOMS_OUTLINE_LAYER_ID make an animated dashed outline
          id={SELECTED_ROOMS_OUTLINE_BACKGROUND_LAYER_ID}
          source='room-source'
          type='line'
          beforeId={SELECTED_ROOMS_LAYER_ID}
          filter={selectedRoomFilter}
          paint={{
            ...SELECTED_OUTLINE_PAINT,
            'line-opacity': 0.6
          }}/>
        <Layer
          // Layer with room info
          id={ROOM_INFO_LAYER_ID}
          source='room-source'
          type='symbol'
          beforeId={SELECTED_ROOMS_OUTLINE_LAYER_ID}
          filter={['!=', 'hideValue', true]}
          layout={{
            // 'text-field': ['concat', ['get', 'roomName'], '\n', ['get', 'value']],
            'text-field': [
              'format', ['get', 'roomName'], { 'font-scale': 0.8, 'text-font': [
                'literal',
                ['DIN Offc Pro Bold', 'Arial Unicode MS Regular'] ] }, '\n', {}, ['get', 'value'], { 'font-scale': 1.1, 'text-font': ['literal', ['DIN Offc Pro Italic', 'Arial Unicode MS Regular']] }
            ],
            'text-justify': 'auto',
          }}
        />
      </Source>
      {/* <FullscreenControl position='bottom-right'/> */}
      <NavigationControl position='bottom-right'/>
      { props.floorSwitcher }
      { props.children }
    </Map>
  )
}

const ROOM_PAINT = {
  'fill-color': ['case', ['has', 'fill-color'], ['get', 'fill-color'], 'rgba(70, 113, 138, 0.4)'],
  'fill-outline-color': ['case', ['has', 'fill-outline-color'], ['get', 'fill-outline-color'], '#627BC1'],
  'fill-opacity': [
    'case', ['boolean', ['feature-state', 'hover'], false],
    1,
    0.4
  ]
} as FillPaint

const SELECTED_PAINT = {
  'fill-color': ['case', ['has', 'selected-fill-color'], ['get', 'selected-fill-color'], 'rgba(0, 147, 199, 0.5)'],
  'fill-outline-color': ['case', ['has', 'selected-fill-color'], ['get', 'selected-fill-color'], 'rgba(0, 147, 199, 1)']
} as FillPaint

const SELECTED_OUTLINE_PAINT = {
  'line-color': ['case', ['has', 'selected-fill-outline-color'], ['get', 'selected-fill-outline-color'], 'rgba(0, 147, 199, 1)'],
  'line-width': ['case', ['has', 'selected-fill-outline-width'], ['get', 'selected-fill-outline-width'], 1],
} as LinePaint

const BACKGROUND_LAYER_ID = 'background-layer'
const ROOMS_LAYER_ID = 'rooms-layer'
const SELECTED_ROOMS_LAYER_ID = 'selected-rooms-layer'
const SELECTED_ROOMS_OUTLINE_LAYER_ID = 'selected-rooms-outline-layer'
const SELECTED_ROOMS_OUTLINE_BACKGROUND_LAYER_ID = 'selected-rooms-outline-background-layer'
const FLOOR_PLAN_LAYER_ID = 'floor-plan-layer'
const ROOM_INFO_LAYER_ID = 'room-info-layer'
