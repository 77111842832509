import React, { useEffect, useRef, useState } from 'react'
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'
import { IconButton, useTheme } from '@mui/material'
import AuthClient from '../../services/auth.service'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import FileSaver from 'file-saver'
import format from 'date-fns/format'
import { parse } from 'date-fns'

export interface MongoChartProps {
  chartId: string
  baseUrl: string
  height?: string
  width?: string
  filter?: Record<string, unknown>
  enableDownload?: boolean
  chartName?: string
}

// eslint-disable-next-line func-style
export const MongoChart: React.FC<MongoChartProps> = (props) => {
  const sdk = new ChartsEmbedSDK({ baseUrl: props.baseUrl })
  const theme = useTheme()
  const chartDiv = useRef({} as HTMLDivElement)
  const [rendered, setRendered] = useState(false)
  const [chart] = useState(
    sdk.createChart({
      chartId: props.chartId,
      height: props.height,
      width: props.width,
      theme: theme.palette.mode,
      showAttribution: false,
      autoRefresh: true,
      maxDataAge: 60,
      background: 'transparent',
      filter: props.filter,
      getUserToken: () => AuthClient.getAccessToken() || '',
    }),
  )

  useEffect(() => {
    chart
      .render(chartDiv.current)
      .then(() => setRendered(true))
      .catch((err) => console.log('Error during Charts rendering.', err))
  }, [chart])

  useEffect(() => {
    if (rendered) {
      chart.setTheme(theme.palette.mode)
    }
  }, [theme.palette.mode, rendered])

  useEffect(() => {
    if (rendered) {
      chart.setFilter(props.filter ?? {}).catch((err) => console.log('Error while filtering.', err))
    }
  }, [chart, props.filter, rendered])

  async function downloadData() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = (await chart.getData()) as any
    if (!data) {
      console.error('error retrieving data from chart')
      return
    }
    let csvString = ''
    const dataKeys = Object.keys(data.fields)
    for (const key of dataKeys) {
      csvString += `${data.fields[key]},`
    }
    csvString += '\n'
    // sort all documents and format timestamps
    const timestampField = Object.entries(data.fields).find(
      (entry) => entry[1] === 'timestamp',
    )?.[0]
    const sortedDocuments = data.documents as Array<Record<string, string | Date>>

    if (timestampField && sortedDocuments.length) {
      const excelTimestampFormat = 'yyyy-MM-dd HH:mm:ss a'
      try {
        // The timestamp field can be either a Date or a string. Handle both.
        if (typeof sortedDocuments[0][timestampField] === 'string') {
          sortedDocuments.forEach(
            (doc) =>
              (doc[timestampField] = format(
                parse(doc[timestampField] as string, 'dd-MMM-yyyy h:mm a', new Date()),
                excelTimestampFormat,
              )),
          )
        } else {
          sortedDocuments.forEach(
            (doc) =>
              (doc[timestampField] = format(doc[timestampField] as Date, excelTimestampFormat)),
          )
        }
        ;(sortedDocuments as Record<string, string>[]).sort((a, b) => {
          if (a[timestampField] < b[timestampField]) {
            return -1
          } else if (a[timestampField] > b[timestampField]) {
            return 1
          } else {
            return 0
          }
        })
      } catch (error) {
        console.error('Error sorting chart data. Will output as they were received', error)
      }
    }

    for (const document of sortedDocuments) {
      for (const key of dataKeys) {
        csvString += `${document[key]},`
      }
      csvString += '\n'
    }
    const blob = new Blob([csvString], { type: 'text/csv' })
    FileSaver.saveAs(blob, props.chartName ?? '')
  }

  return (
    <div className='env-chart' ref={chartDiv}>
      {props.enableDownload && rendered && (
        <IconButton className='chart-download-btn' onClick={downloadData}>
          <DownloadForOfflineIcon />
        </IconButton>
      )}
    </div>
  )
}
