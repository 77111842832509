/* eslint-disable no-magic-numbers */
import { ImdfFeature } from './components/floor-map/floor-map.component'
import { UvaRoomWithId } from './components/room-selector/room-selector.component'
import { FLOOR1_PLAN_URL, FLOOR1_PLAN_URL_NEW, FLOOR2_PLAN_URL, SCHOOL_PLAN_URL } from './floor-plan-mocks'
import type { HierarchicalRoomType } from './components/room-selector/room-selector.component'

export type HierarchicalRoomWithGeoDataType = UvaRoomWithId & {
  geojson: ImdfFeature<GeoJSON.Polygon>
  rooms: HierarchicalRoomWithGeoDataType[]
  // floor plan as data URI in case that this room represents a floor
  floorPlan?: string
}

// Geojson:
// First and last point have to be the same point in a geojson polygon.
// Otherways, mapbox has some drawing artifacts and feature detection doesn't work correctly.
export const FLOOR2: HierarchicalRoomWithGeoDataType = {
  id: '63ed52bdc8ce7522a5e516d3,63ed53432ba8646e33befb38,63ed536bc8ce7522a5e516d4,63ed53aac8ce7522a5e516d5',
  name: 'Floor 2',
  volume: 1000,
  floorPlan: FLOOR2_PLAN_URL,
  geojson: {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [3.416984889982416, 42.88119200640264],
          [72.11809403044634, 42.88119200640264],
          [72.2440019403634, 16.20336189043215],
          [83.29572230600667, 16.02506858715688],
          [83.29572230600667, 4.030595726477642],
          [24.402564398230993, 4.030595726477642],
          [24.402564398230993, 9.852278762559578],
          [16.24970544745426, 9.852278762559578],
          [16.05893983413617, 5.437651932065137],
          [3.3135608935590426, 5.437651932065137],
          [3.416984889982416, 42.88119200640264],
        ],
      ],
    },
  } as ImdfFeature<GeoJSON.Polygon>,
  rooms: [
    {
      id: '63ed52bdc8ce7522a5e516d3',
      name: 'Room 101',
      volume: 500,
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [3.416984889982416, 42.88119200640264],
              [13.371565338397517, 42.88119200640264],
              [13.371565338397517, 30.71836151900996],
              [3.416984889982416, 30.71836151900996],
              [3.416984889982416, 42.88119200640264],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '63ed53432ba8646e33befb38',
      name: 'Room 102',
      volume: 500,
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [13.467673800493856, 42.88119200640264],
              [23.663976952954556, 42.88119200640264],
              [23.663976952954556, 30.71836151900996],
              [13.467673800493856, 30.71836151900996],
              [13.467673800493856, 42.88119200640264],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '63ed536bc8ce7522a5e516d4',
      name: 'Room 103',
      volume: 500,
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [23.795787671971567, 42.88119200640264],
              [33.00322521697433, 42.88119200640264],
              [33.00322521697433, 30.71836151900996],
              [23.795787671971567, 30.71836151900996],
              [23.795787671971567, 42.88119200640264],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '63ed53aac8ce7522a5e516d5',
      name: 'Room 104',
      volume: 500,
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [33.135059721363916, 42.88119200640264],
              [43.33136287382462, 42.88119200640264],
              [43.33136287382462, 30.71836151900996],
              [33.135059721363916, 30.71836151900996],
              [33.135059721363916, 42.88119200640264],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '63ed53bfc8ce7522a5e516d6',
      name: 'Room 105',
      volume: 500,
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [43.55095201882028, 42.88119200640264],
              [52.93418789403708, 42.88119200640264],
              [52.93418789403708, 30.71836151900996],
              [43.55095201882028, 30.71836151900996],
              [43.55095201882028, 42.88119200640264],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '63ef92dbac7469ddde90bbbd',
      name: 'Room 106',
      volume: 500,
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [53.131874367243626, 42.88119200640264],
              [62.756832946506194, 42.88119200640264],
              [62.756832946506194, 30.71836151900996],
              [53.131874367243626, 30.71836151900996],
              [53.131874367243626, 42.88119200640264],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '63ef92fbac7469ddde90bbbe',
      name: 'Room 107',
      volume: 500,
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [62.82275732033645, 42.88119200640264],
              [72.11809403044634, 42.88119200640264],
              [72.11809403044634, 30.71836151900996],
              [62.82275732033645, 30.71836151900996],
              [62.82275732033645, 42.88119200640264],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '63ef9315ac7469ddde90bbbf',
      name: 'Room 108',
      volume: 500,
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [3.3247581472919308, 30.50892154689106],
              [41.98253200211366, 30.50892154689106],
              [41.98253200211366, 25.593192084789294],
              [32.51139696178848, 25.593192084789294],
              [32.51139696178848, 4.030595726477642],
              [24.402564398230993, 4.030595726477642],
              [24.402564398230993, 9.852278762559578],
              [16.24970544745426, 9.852278762559578],
              [16.24970544745426, 25.5475531770211],
              [3.3247581472919308, 25.567386561842326],
              [3.3247581472919308, 30.50892154689106],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '63ef93772dae62842421ef73',
      name: 'Room 109',
      volume: 500,
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [41.98253200211366, 30.50892154689106],
              [72.2440019403634, 30.50892154689106],
              [72.2440019403634, 16.20336189043215],
              [70.33220478572414, 16.20336189043215],
              [70.33220478572414, 4.030595726477642],
              [51.043242014328, 4.030595726477642],
              [51.043242014328, 25.532675966410068],
              [41.98253200211366, 25.593192084789294],
              [41.98253200211366, 30.50892154689106],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '63ef939c2dae62842421ef74',
      name: 'Room 110',
      volume: 500,
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [3.3135608935590426, 25.40650433932474],
              [16.05893983413617, 25.40650433932474],
              [16.05893983413617, 5.437651932065137],
              [3.3135608935590426, 5.437651932065137],
              [3.3135608935590426, 25.40650433932474],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '63ef93bf2dae62842421ef75',
      name: 'Room 111',
      volume: 500,
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [32.606594243485205, 25.353933003333836],
              [45.43987234916935, 25.353933003333836],
              [45.43987234916935, 14.911869323359753],
              [32.606594243485205, 14.911869323359753],
              [32.606594243485205, 25.353933003333836],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '63ef93d92dae62842421ef76',
      name: 'Room 112',
      volume: 500,
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [32.606594243485205, 14.699430919002339],
              [45.43987234916935, 14.699430919002339],
              [45.43987234916935, 4.030595726477642],
              [32.606594243485205, 4.030595726477642],
              [32.606594243485205, 14.699430919002339],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,

    {
      id: '63ef93fb2dae62842421ef77',
      name: 'Room 113',
      volume: 500,
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [70.4404694090469, 16.02506858715688],
              [83.29572230600667, 16.02506858715688],
              [83.29572230600667, 4.030595726477642],
              [70.4404694090469, 4.030595726477642],
              [70.4404694090469, 16.02506858715688],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
  ],
} as any as HierarchicalRoomWithGeoDataType

export const FLOOR1: HierarchicalRoomWithGeoDataType = {
  id: '632b65f5df334fc2af3a0928,632b65f5df334fc2af3a0929',
  name: 'Floor 1',
  volume: 1000,
  floorPlan: FLOOR1_PLAN_URL,
  geojson: {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [6.358805575673102, 41.38842403213911],
          [45.63002852926269, 41.38842403213911],
          [45.63002852926269, 32.53393744871744],
          [59.864584921180466, 32.53393744871744],
          [59.864584921180466, 10.949196485297577],
          [21.40099637280602, 10.85006342017806],
          [21.40099637280602, 5],
          [13.425606621306173, 5],
          [13.425606621306173, 10.85006342017806],
          [6.358805575673102, 10.85006342017806],
          [6.358805575673102, 41.38842403213911],
        ],
      ],
    },
  } as ImdfFeature<GeoJSON.Polygon>,
  rooms: [
    {
      id: 'DUMMY0000000000000000000',
      name: 'Room 1',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [6.358805575673102, 41.38842403213911],
              [19.280956059116562, 41.38842403213911],
              [19.280956059116562, 27.91276967105516],
              [6.358805575673102, 27.91276967105516],
              [6.358805575673102, 41.38842403213911],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000001',
      name: 'Room 2',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [19.280956059116562, 41.38842403213911],
              [33.11169524842677, 41.38842403213911],
              [33.11169524842677, 27.91276967105516],
              [19.280956059116562, 27.91276967105516],
              [19.280956059116562, 41.38842403213911],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000002',
      name: 'Room 3',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [33.11169524842677, 41.38842403213911],
              [45.63002852926269, 41.38842403213911],
              [45.63002852926269, 27.91276967105516],
              [33.11169524842677, 27.91276967105516],
              [33.11169524842677, 41.38842403213911],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000003',
      name: 'Room 4',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [6.358805575673102, 27.91276967105516],
              [45.63002852926269, 27.91276967105516],
              [45.63002852926269, 32.53393744871744],
              [59.864584921180466, 32.53393744871744],
              [59.864584921180466, 10.949196485297577],
              [45.73098282991424, 10.949196485297577],
              [45.73098282991424, 22.52709256257789],
              [27.96302591517997, 22.52709256257789],
              [27.96302591517997, 10.949196485297577],
              [6.358805575673102, 10.949196485297577],
              [6.358805575673102, 27.91276967105516],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000004',
      name: 'Room 5',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        properties: {
          roomId: 'ROOM5',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [27.96302591517997, 22.52709256257789],
              [45.73098282991424, 22.52709256257789],
              [45.73098282991424, 10.949196485297577],
              [27.96302591517997, 10.949196485297577],
              [27.96302591517997, 22.52709256257789],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '632b65f5df334fc2af3a0929',
      name: 'Room 6',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [13.425606621306173, 10.85006342017806],
              [21.40099637280602, 10.85006342017806],
              [21.40099637280602, 5],
              [13.425606621306173, 5],
              [13.425606621306173, 10.85006342017806],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
  ],
} as any as HierarchicalRoomWithGeoDataType

///////////////////////////////////////////////////////////////////////////////////////////////////
export const FLOOR1_NEW: HierarchicalRoomWithGeoDataType = {
  id: '632b65f5df334fc2af3a0928,632b65f5df334fc2af3a0929',
  name: 'Floor 1',
  volume: 1000,
  floorPlan: FLOOR1_PLAN_URL_NEW,
  geojson: {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [1.8214422838700557, 43.18825004164495],
          [46.36349890180054, 43.18825004164495],
          [46.36349890180054, 2.5236943206102183],
          [1.8526109189567705, 2.5236943206102183],
          [1.8526109189567705, 15.03866359532158],
          [11.175783777751406, 15.03866359532158],
          [11.175783777751406, 28.34132303333803],
          [1.9211364565252609, 28.34132303333803],
          [1.8214422838700557, 43.18825004164495],
        ],
      ],
    },
  } as ImdfFeature<GeoJSON.Polygon>,
  rooms: [
    {
      id: 'DUMMY0000000000000000005',
      name: 'Room 1',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [1.8214422838700557, 43.18825004164495],
              [21.846910534691347, 43.18825004164495],
              [21.846910534691347, 34.493440153747585],
              [23.713827258428353, 34.493440153747585],
              [23.713827258428353, 32.54912396021946],
              [13.683293408552316, 32.54912396021946],
              [13.683293408552316, 28.5099843549947],
              [1.8214422838700557, 28.50998555150126],
              [1.8214422838700557, 43.18825004164495],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000006',
      name: 'Room 2',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [21.925378671351837, 43.19543885070681],
              [25.85800662095727, 43.19543885070681],
              [25.85800662095727, 39.13736686379701],
              [21.925378671351837, 39.13736686379701],
              [21.925378671351837, 43.19543885070681],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000007',
      name: 'Room 3',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [21.924866233887656, 39.05451634649609],
              [25.86564029442343, 39.05451634649609],
              [25.86564029442343, 34.58452472726951],
              [21.924866233887656, 34.58452472726951],
              [21.924866233887656, 39.05451634649609],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000008',
      name: 'Room 4',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [30.495723338739253, 43.208209921256554],
              [37.48059056156262, 43.208209921256554],
              [37.48059056156262, 32.70410209427695],
              [30.495723338739253, 32.70410209427695],
              [30.495723338739253, 43.208209921256554],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000009',
      name: 'Room 5',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [13.795284702351893, 32.448923047876306],
              [25.883800088886176, 32.448923047876306],
              [25.883800088886176, 24.481970868529714],
              [13.795284702351893, 24.481970868529714],
              [13.795284702351893, 32.448923047876306],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '632b65f5df334fc2af3a0929',
      name: 'Room 6',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [23.82439536789846, 34.47040807713405],
              [30.375616230304644, 34.47040807713405],
              [30.375616230304644, 24.51203037332691],
              [25.985797623880302, 24.51203037332691],
              [25.985797623880302, 32.533013174186934],
              [23.82439536789846, 32.533013174186934],
              [23.82439536789846, 34.47040807713405],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000011',
      name: 'Room 7',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [30.470528189134086, 32.61317587799938],
              [35.30604816848532, 32.61317587799938],
              [35.30604816848532, 28.431634857675718],
              [30.470528189134086, 28.431634857675718],
              [30.470528189134086, 32.61317587799938],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000012',
      name: 'Room 8',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [37.54964335159286, 32.61317938529761],
              [42.38516333094415, 32.61317938529761],
              [42.38516333094415, 28.431638519273847],
              [37.54964335159286, 28.431638519273847],
              [37.54964335159286, 32.61317938529761],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '632b65f5df334fc2af3a0928',
      name: 'Room 9',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [30.521753235224992, 28.33248991536098],
              [35.347028468857786, 28.33248991536098],
              [35.347028468857786, 23.975226991034205],
              [30.521753235224992, 23.975226991034205],
              [30.521753235224992, 28.33248991536098],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000013',
      name: 'Room 10',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [37.53940405271601, 28.33248991536098],
              [42.38516877778579, 28.33248991536098],
              [42.38516877778579, 23.975226991034205],
              [37.53940405271601, 23.975226991034205],
              [37.53940405271601, 28.33248991536098],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000014',
      name: 'Room 11',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [35.40849503911241, 32.61317520307186],
              [37.48817842006247, 32.61317520307186],
              [37.48817842006247, 18.783374911451062],
              [30.51046115526026, 18.783374911451062],
              [30.51046115526026, 23.849025095274328],
              [35.40849503911241, 23.849025095274328],
              [35.40849503911241, 32.61317520307186],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000015',
      name: 'Room 12',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [1.788111269085988, 15.090741304156893],
              [14.403360117102409, 15.090741304156893],
              [14.403360117102409, 2.4988352284802886],
              [1.788111269085988, 2.4988352284802886],
              [1.788111269085988, 15.090741304156893],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '643d99f7298d1fddf9c634bd',
      name: 'Room 13',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [20.702918339226528, 10.057563046400318],
              [29.083333992557414, 10.057563046400318],
              [29.083333992557414, 2.4781979665769995],
              [20.702918339226528, 2.4781979665769995],
              [20.702918339226528, 10.057563046400318],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: 'DUMMY0000000000000000016',
      name: 'Room 14',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [31.254641356834327, 15.090636897577966],
              [46.37805551761389, 15.090636897577966],
              [46.37805551761389, 2.3937503621932166],
              [29.2864808438475, 2.3937503621932166],
              [29.2864808438475, 10.033874299744355],
              [31.257665864946034, 10.033874299744355],
              [31.257665864946034, 15.090636897577966],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,

  ]
} as any as HierarchicalRoomWithGeoDataType

///////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////// SCHOOL MOCKS ///////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
export const SCHOOL_FLOOR: HierarchicalRoomWithGeoDataType = {
  id: '6446a39380d652d6406b90f5,6446a36e80d652d6406b90f3,6446a32480d652d6406b90f1,6446a2ff80cf26662b63d9d9,6446a38380d652d6406b90f4,6446a35d80d652d6406b90f2,6446a30f80d652d6406b90f0,6446a29680d652d6406b90ef,6446a2b480cf26662b63d9d5,6446a2d080cf26662b63d9d7,6446a21980d652d6406b90ea,6446a24a80d652d6406b90ec,6446a25a80d652d6406b90ed,6446a23480d652d6406b90eb,6446a27380d652d6406b90ee,6446a2df80cf26662b63d9d8',
  name: 'Floor 1',
  volume: 1000,
  floorPlan: SCHOOL_PLAN_URL,
  geojson: {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [3.8716474693671046, 37.884132239265995],
          [26.558092931061935, 37.884132239265995],
          [26.558092931061935, 41.93606641889954],
          [49.908694395046496, 41.93606641889954],
          [49.908694395046496, 18.078225322245785],
          [50.656611505901594, 18.078225322245785],
          [50.656611505901594, 5.175697549526291],
          [29.77998198088241, 5.175697549526291],
          [29.77998198088241, 4.5938587244997535],
          [27.62351650192602, 3.9863127588983076],
          [25.52827052418087, 4.638293823483977],
          [25.52827052418087, 5.126889561870257],
          [24.88929480047142, 5.126889561870257],
          [24.88929480047142, 5.881244768483086],
          [21.53095940349141, 5.881244768483086],
          [21.53095940349141, 5.289682714409949],
          [11.886883945641102, 5.289682714409949],
          [11.886883945641102, 4.37165141321033],
          [11.5599661335103, 4.37165141321033],
          [11.5599661335103, 3.452494257853644],
          [4.248872159549791, 3.452494257853644],
          [4.248872159549791, 4.312388262059827],
          [3.9219543474203533, 4.312388262059827],
          [3.9219543474203533, 8.358274482836094],
          [5.571403308623587, 8.358274482836094],
          [5.571403308623587, 14.611863875668988],
          [4.412328576481912, 14.611863875668988],
          [4.412328576481912, 25.588715996384067],
          [3.8394432344624647, 25.588715996384067],
          [3.8716474693671046, 37.884132239265995],
        ],
      ],
    } as GeoJSON.Polygon,
  } as ImdfFeature<GeoJSON.Polygon>,
  rooms: [
    {
      id: '6446a39380d652d6406b90f5',
      name: 'Classroom 161',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [3.9082194341508796, 37.837883403233846],
              [5.84508766552463, 37.837883403233846],
              [5.84508766552463, 32.818485401665924],
              [3.873786260519239, 32.818485401665924],
              [3.9082194341508796, 37.837883403233846],
            ],
          ],
        },
      },
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a36e80d652d6406b90f3',
      name: 'Classroom 157',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [8.210017739589546, 37.83334300612593],
              [20.72970051386514, 37.83334300612593],
              [20.72970051386514, 32.72441411269618],
              [16.952893281511706, 32.72441411269618],
              [16.433234301138356, 33.21488049133764],
              [15.34751821714525, 33.21488049133764],
              [15.34751821714525, 32.72441411269618],
              [15.143366474855696, 32.72441411269618],
              [15.143366474855696, 33.01279591706924],
              [11.867658973575743, 33.01279591706924],
              [11.867658973575743, 32.72443469592791],
              [9.88183974692322, 32.72443469592791],
              [9.287943769354484, 33.30025817919321],
              [8.239346183959555, 33.30025817919321],
              [8.210017739589546, 37.83334300612593],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a32480d652d6406b90f1',
      name: 'Classroom 153',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [29.0290012105485, 41.9360460202378],
              [35.466355568815686, 41.9360460202378],
              [35.466355568815686, 36.31183927560481],
              [34.39791501142767, 36.31183927560481],
              [34.39791501142767, 35.605563258170974],
              [27.898234953978232, 35.605563258170974],
              [27.898234953978232, 36.3046643143112],
              [26.616106285112295, 36.3046643143112],
              [26.616106285112295, 37.415945007838474],
              [29.0290012105485, 37.415945007838474],
              [29.0290012105485, 41.9360460202378],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a2ff80cf26662b63d9d9',
      name: 'Classroom 147',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [39.749014195422404, 41.92942243099375],
              [48.750625891423084, 41.92942243099375],
              [48.750625891423084, 35.59108391994354],
              [39.749014195422404, 35.59108391994354],
              [39.749014195422404, 41.92942243099375],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a38380d652d6406b90f4',
      name: 'Classroom 160',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [3.848615404993467, 31.016264191357863],
              [4.6410421517229, 31.016264191357863],
              [4.6410421517229, 30.457605532727655],
              [5.63825333861908, 30.457605532727655],
              [6.225895645183357, 31.016264191357863],
              [8.095666620613542, 31.016264191357863],
              [8.095666620613542, 25.7232356787964],
              [3.8753264189278127, 25.7232356787964],
              [3.848615404993467, 31.016264191357863],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a35d80d652d6406b90f2',
      name: 'Classroom 154',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [29.563997636504723, 33.556827355799896],
              [40.86275653089223, 33.556827355799896],
              [40.86275653089223, 32.6393968113118],
              [41.405880480897395, 32.6393968113118],
              [41.405880480897395, 25.711797805467896],
              [30.721474907009338, 25.711797805467896],
              [30.721474907009338, 31.02064511049126],
              [29.563997636504723, 31.02064511049126],
              [29.563997636504723, 33.556827355799896],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a30f80d652d6406b90f0',
      name: 'Classroom 152',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [41.47713714255744, 32.63189759166032],
              [42.616807070438085, 32.63189759166032],
              [42.616807070438085, 33.57166434329916],
              [47.540537305739036, 33.57166434329916],
              [47.540537305739036, 26.47143815659618],
              [46.75701423031995, 26.47143815659618],
              [46.75701423031995, 25.71981825718754],
              [41.46823347124541, 25.71981825718754],
              [41.47713714255744, 32.63189759166032],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a29680d652d6406b90ef',
      name: 'Classroom 110',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [25.856573035632266, 18.448592620925353],
              [32.912997760552884, 18.448592620925353],
              [32.912997760552884, 13.479332671201377],
              [28.406492280573275, 13.479332671201377],
              [27.76371485317793, 12.915426374328788],
              [25.856573035632266, 12.915426374328788],
              [25.856573035632266, 18.448592620925353],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a2b480cf26662b63d9d5',
      name: 'Classroom 112',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [32.962423563640186, 18.455284858990865],
              [40.02591177677425, 18.455284858990865],
              [40.02591177677425, 13.479324354526398],
              [35.512342808581195, 13.479324354526398],
              [34.8766288693993, 12.922302733180075],
              [32.95536007542674, 12.922302733180075],
              [32.962423563640186, 18.455284858990865],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a2d080cf26662b63d9d7',
      name: 'Classroom 114',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [40.08944570226362, 18.448584045950213],
              [47.00460066292146, 18.448584045950213],
              [47.00460066292146, 13.486192700029818],
              [42.49809518294185, 13.486192700029818],
              [41.841190779120865, 12.915417563484297],
              [40.082382214050114, 12.915417563484297],
              [40.08944570226362, 18.448584045950213],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a21980d652d6406b90ea',
      name: 'Breakroom 101',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [3.925828742522782, 8.342313234073174],
              [5.564104263851874, 8.342313234073174],
              [5.564104263851874, 9.195865609576089],
              [6.650352163863829, 9.195865609576089],
              [6.650352163863829, 9.854430552765848],
              [11.743252154083564, 9.854430552765848],
              [11.743252154083564, 4.352198902525231],
              [11.45833467211304, 4.352198902525231],
              [11.45833467211304, 3.4283608308485043],
              [4.255264581050795, 3.4283608308485043],
              [4.255264581050795, 4.307807614332404],
              [3.916925071210784, 4.307807614332404],
              [3.925828742522782, 8.342313234073174],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a24a80d652d6406b90ec',
      name: 'Office 109A',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [24.804915428732897, 11.123881564227574],
              [28.259539897624336, 11.123881564227574],
              [28.259539897624336, 9.134356048005287],
              [24.79601175742252, 9.134356048005287],
              [24.804915428732897, 11.123881564227574],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a25a80d652d6406b90ed',
      name: 'Office 109C',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [29.381402482882663, 11.123881564227574],
              [32.907256322265084, 11.123881564227574],
              [32.907256322265084, 8.139664019996104],
              [31.037485346834103, 8.139664019996104],
              [31.037485346834103, 9.12556517759009],
              [29.381402482882663, 9.12556517759009],
              [29.381402482882663, 11.123881564227574],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a23480d652d6406b90eb',
      name: 'Reception Office 109',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [24.79601175742252, 9.134356048005287],
              [31.037485346834103, 9.134356048005287],
              [31.037485346834103, 8.139664019996104],
              [29.381402482882663, 8.139664019996104],
              [29.381402482882663, 5.115317655602809],
              [29.64851262222922, 5.115317655602809],
              [29.64851262222922, 4.618511386412919],
              [27.547246192697912, 4.023663810305379],
              [25.428172420544314, 4.645135166755651],
              [25.428172420544314, 5.159657167022459],
              [24.813819100044867, 5.159657167022459],
              [24.79601175742252, 9.134356048005287],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a27380d652d6406b90ee',
      name: 'Principal Office 100',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [29.381402482882663, 8.139664019996104],
              [32.91615999357711, 8.139664019996104],
              [32.91615999357711, 5.150789511747774],
              [29.381402482882663, 5.150789511747774],
              [29.381402482882663, 8.139664019996104],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a2a680cf26662b63d9d4',
      name: 'Classroom 111',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [32.967901267868655, 10.560919497193211],
              [33.91375562511567, 10.560919497193211],
              [34.551753075885955, 11.120391507743278],
              [36.47813372821474, 11.120391507743278],
              [36.47813372821474, 5.152448795047675],
              [32.98396506889128, 5.152448795047675],
              [32.967901267868655, 10.560919497193211],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a2c280cf26662b63d9d6',
      name: 'Classroom 113',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [36.53229059294978, 10.55634036223509],
              [41.000077492547945, 10.55634036223509],
              [41.67316782984466, 11.12358682063487],
              [43.52134771044359, 11.12358682063487],
              [43.52134771044359, 5.1441935889668144],
              [36.51291262073164, 5.1441935889668144],
              [36.53229059294978, 10.55634036223509],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,
    {
      id: '6446a2df80cf26662b63d9d8',
      name: 'Classroom 115',
      volume: 500,
      deviceSerialNumbers: [],
      rooms: [],
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [43.57325076374249, 11.122039021558464],
              [45.438011652009976, 11.122039021558464],
              [46.087852567618825, 10.56011011200502],
              [47.02729649996536, 10.56011011200502],
              [47.02729649996536, 10.024975684530332],
              [49.16753342854503, 10.024975684530332],
              [49.16753342854503, 11.122039021558464],
              [50.66499292972921, 11.122039021558464],
              [50.66499292972921, 5.160406334072391],
              [43.580314251956, 5.160406334072391],
              [43.57325076374249, 11.122039021558464],
            ],
          ],
        },
      } as ImdfFeature<GeoJSON.Polygon>,
    } as any as HierarchicalRoomWithGeoDataType,

  ]
} as any as HierarchicalRoomWithGeoDataType



///////////////////////////////////////////////////////////////////////////////////////////////////

export const HOSPITAL: HierarchicalRoomType = {
  id: 'HOSPITAL',
  name: 'UVA Hospital',
  volume: 1000,
  deviceSerialNumbers: [],
  rooms: [FLOOR1_NEW, FLOOR2],
} as any as HierarchicalRoomType

export const SCHOOL: HierarchicalRoomType = {
  id: 'SCHOOL',
  name: 'Butler 53',
  volume: 1000,
  deviceSerialNumbers: [],
  rooms: [SCHOOL_FLOOR],
} as any as HierarchicalRoomType
