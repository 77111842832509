import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { subSeconds } from 'date-fns'
import React, { useEffect } from 'react'
import { AppContext } from '../../../../app/App.context'
import { RoomPicker } from '../../../../components/room-picker/room-picker.component'

export interface SelectableChart {
  id: string
  name: string
}

export type EnvChartPickerChangeEvent = Partial<Omit<EnvChartPickerState, 'period'>>

export interface EnvChartPickerProps {
  header?: string
  availableCharts: SelectableChart[]
  onChange: (event: EnvChartPickerChangeEvent) => void
}

interface uva_report_period {
  id: number
  label: string
  seconds: number
}

const ONE_MINUTE_IN_SECONDS = 60
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS * 60
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS * 24
const ONE_WEEK_IN_SECONDS = ONE_DAY_IN_SECONDS * 7

const CUSTOM_REPORT_PERIOD_ID = 100

const THIRTY_SECONDS_IN_MILLISECONDS = 30 * 1000

const REPORT_PERIODS: uva_report_period[] = [
  {
    id: 1,
    label: 'Last week',
    seconds: ONE_WEEK_IN_SECONDS,
  },
  {
    id: 2,
    label: 'Last 3 days',
    //eslint-disable-next-line no-magic-numbers
    seconds: 3 * ONE_DAY_IN_SECONDS,
  },
  {
    id: 3,
    label: 'Last 24 hours',
    seconds: ONE_DAY_IN_SECONDS,
  },
  {
    id: 4,
    label: 'Last 12 hours',
    seconds: ONE_HOUR_IN_SECONDS * 12,
  },
  {
    id: 5,
    label: 'Last 6 hours',
    //eslint-disable-next-line no-magic-numbers
    seconds: ONE_HOUR_IN_SECONDS * 6,
  },
  {
    id: 6,
    label: 'Last 3 hours',
    //eslint-disable-next-line no-magic-numbers
    seconds: ONE_HOUR_IN_SECONDS * 3,
  },
  {
    id: 7,
    label: 'Last 1 hour',
    seconds: ONE_HOUR_IN_SECONDS,
  },
  {
    id: 8,
    label: 'Last 30 minutes',
    seconds: ONE_MINUTE_IN_SECONDS * 30,
  },
  {
    id: CUSTOM_REPORT_PERIOD_ID,
    label: 'Custom',
    seconds: 0,
  },
]

interface UvaDevice {
  id: string
  deviceType: 'Air 1.75' | 'Air 2.0'
  serialNumber: string
}

interface EnvChartPickerState {
  roomId: string
  deviceIds: string[]
  chartIds: string[]
  period: number | null
  startTime: Date | null
  endTime: Date | null
}

const devicesPerRoom: Record<string, UvaDevice[]> = {}

// eslint-disable-next-line func-style
export const EnvChartPicker: React.FC<EnvChartPickerProps> = (props) => {
  const appContext = React.useContext(AppContext)
  const [state, setState] = React.useState<EnvChartPickerState>({
    roomId: '',
    deviceIds: [],
    chartIds: [],
    period: null,
    startTime: null,
    endTime: null,
  })

  useEffect(() => setState({ ...state, chartIds: [] }), [props.availableCharts])

  // Every THIRTY_SECONDS, if the current period is NOT custom, re-calculate the period
  // In this way, our time intervals stay up to date!
  useEffect(() => {
    const interval = setInterval(() => {
      if (!state.period || state.period === CUSTOM_REPORT_PERIOD_ID) {
        return
      }
      onPeriodChange(state.period)
    }, THIRTY_SECONDS_IN_MILLISECONDS)
    return () => clearInterval(interval)
  }, [state])

  function onRoomChange(roomId: string) {
    const room = appContext.rooms.find((r) => r.id === roomId)
    const newState = { ...state, roomId, deviceIds: [...(room?.deviceSerialNumbers || [])] }
    setState(newState)
    devicesPerRoom[roomId] =
      room?.deviceSerialNumbers?.map(
        (d) =>
          ({
            id: d,
            serialNumber: d,
          } as UvaDevice),
      ) || []
    triggerOnChange(newState)
  }

  function onPeriodChange(newValue: number) {
    const period = REPORT_PERIODS.find((p) => p.id === newValue)
    const newState = {
      ...state,
      period: newValue,
    }
    if (newValue === CUSTOM_REPORT_PERIOD_ID || !period) {
      newState.startTime = null
      newState.endTime = null
    } else {
      newState.startTime = subSeconds(new Date(), period.seconds)
      newState.endTime = new Date()
    }
    setState(newState)
    triggerOnChange(newState)
  }

  function onDevicesChange(evt: SelectChangeEvent<string[]>) {
    const newState = {
      ...state,
      deviceIds: [...(evt.target.value as string[])],
    }
    setState(newState)
    triggerOnChange(newState)
  }

  function onChartChange(evt: SelectChangeEvent<string[]>) {
    const newState = {
      ...state,
      chartIds: [...(evt.target.value as string[])],
    }
    setState(newState)
    triggerOnChange(newState)
  }

  function onCustomDateChange(newValue: Date | null, attr: 'startTime' | 'endTime') {
    const newState = {
      ...state,
      [attr]: newValue,
    }
    setState(newState)
    triggerOnChange(newState)
  }

  function triggerOnChange(event: EnvChartPickerChangeEvent) {
    props.onChange(event)
  }

  return (
    <Card className='uva-form-card'>
      {props.header && <CardHeader title={props.header}></CardHeader>}
      <CardContent>
        <FormControl variant='filled' fullWidth className='uva-form-control'>
          <RoomPicker value={state.roomId} onChange={onRoomChange} rooms={appContext.rooms} />
        </FormControl>
        <FormControl variant='filled' fullWidth className='uva-form-control'>
          <InputLabel id='device-label'>Devices (optional)</InputLabel>
          <Select<string[]>
            variant='filled'
            labelId='device-label'
            label='Devices (optional)'
            id='device-picker'
            multiple
            value={state.deviceIds}
            onChange={onDevicesChange}
          >
            {devicesPerRoom[state.roomId]?.map((device) => (
              <MenuItem value={device.id} key={'DeviceItem' + device.id}>
                <Checkbox checked={state.deviceIds.includes(device.serialNumber)} />
                {`${device.deviceType || ''} (${device.serialNumber})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant='filled' fullWidth className='uva-form-control'>
          <InputLabel id='period-label'>Report Period</InputLabel>
          <Select
            variant='filled'
            labelId='period-label'
            label='Report Period'
            value={state.period ?? ''}
            onChange={(event: SelectChangeEvent<number>) => onPeriodChange(+event.target.value)}
          >
            {REPORT_PERIODS.map((period: uva_report_period) => (
              <MenuItem value={period.id} key={'Period' + period.id}>
                {period.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {state.period === CUSTOM_REPORT_PERIOD_ID && (
          <FormControl variant='filled' fullWidth className='uva-form-control'>
            <div className='uva-date-range-control' id='uvaLabReportStartStop'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label='Start Time'
                  value={state.startTime}
                  renderInput={(params: TextFieldProps) => <TextField {...params} />}
                  className='uva-form-control uva-calendar-control'
                  onChange={(value) => onCustomDateChange(value, 'startTime')}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label='End Time'
                  className='uva-form-control uva-calendar-control'
                  value={state.endTime}
                  renderInput={(params: TextFieldProps) => <TextField {...params} />}
                  onChange={(value) => onCustomDateChange(value, 'endTime')}
                />
              </LocalizationProvider>
            </div>
          </FormControl>
        )}

        <FormControl variant='filled' className='uva-form-control' fullWidth>
          <InputLabel id='chart-label'>Selected Charts</InputLabel>
          <Select<string[]>
            variant='filled'
            labelId='chart-label'
            label='Selected Charts'
            id='chart-picker'
            multiple
            value={state.chartIds}
            onChange={onChartChange}
          >
            {props.availableCharts.map((chart) => (
              <MenuItem value={chart.id} key={'Chart' + chart.id}>
                <Checkbox checked={state.chartIds.includes(chart.id)} />
                {chart.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  )
}

export default EnvChartPicker
