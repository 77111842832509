import * as React from 'react'
import darkLogo from '../../../../assets/dark/logo.svg'
import lightLogo from '../../../../assets/light/logo.svg'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Switch,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu'
import AuthClient from '../../../../services/auth.service'

let logo

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  token: string | undefined
  toggleTheme: () => void
}

const drawerWidth = 240
const navItems = [
  { label: 'Alerts & Tracking', path: '/app/tracks' },
  { label: 'Lab Report', path: '/app/lab-report' },
  { label: 'Environmental Report', path: '/app/env-charts' },
  { label: 'Simulator', path: '/app/pssq-simulator' },
  { label: 'Monitoring', path: '/app/monitoring' },
]

const UIThemeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
    width: 32,
    height: 32,
    '&:before': {
      content: '\'\'',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 10,
  },
}))

export default function UVATopAppBar({ token, toggleTheme }: Props) {
  const theme = useTheme()
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  function handleOpenUserMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorElUser(event.currentTarget)
  }
  function handleCloseUserMenu() {
    setAnchorElUser(null)
  }
  function handleLogout() {
    handleCloseUserMenu()
    AuthClient.handleLogout()
  }
  const [mobileOpen, setMobileOpen] = React.useState(false)
  logo = theme.palette.mode === 'dark' ? darkLogo : lightLogo

  function handleDrawerToggle() {
    setMobileOpen((prevState) => !prevState)
  }

  const rightSideMenu = (
    <Menu
      sx={{ mt: '40px' }}
      id='UVUserMenuAppBar'
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      <MenuItem key='user-menuitem-toggle-theme'>
        <FormControlLabel
          control={
            <UIThemeSwitch
              sx={{ m: 1 }}
              checked={theme.palette.mode === 'dark'}
              onChange={toggleTheme}
            />
          }
          className='uva-account-menu-item'
          label='Theme'
        />
      </MenuItem>
      <MenuItem key='user-menuitem-logout' onClick={handleLogout}>
        <span className='uva-account-menu-item'>Sign Out</span>
      </MenuItem>
    </Menu>
  )

  const accountMenu = (
    <Box id='UVAAccountMenu'>
      <IconButton onClick={handleOpenUserMenu}>
        <AccountCircleIcon className={`uva-appbar-link-${theme.palette.mode}`} />
      </IconButton>
      {token === undefined || token === '' ? null : rightSideMenu}
    </Box>
  )

  const mobileMenu = (
    <Box onClick={handleDrawerToggle} id='UVAMobileMenu'>
      <Typography variant='h6' className='uva-mobile-menu-title'>
        UVA Lab
      </Typography>
      <Divider />
      {token && (
        <List>
          {navItems.map((item) => (
            <ListItem key={item.label} disablePadding>
              <ListItemButton component={Link} to={item.path}>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  )

  return (
    <Box>
      <CssBaseline />
      <AppBar component='nav'>
        <Toolbar id='UVAToolbar'>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            className='uva-mobile-menu-icon'
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box id='UVABranding'>
            <img src={logo} className='uva-branding-logo' />
            <Typography variant='h6' component='div' className='uva-branding-app-name'>
              UVA Lab
            </Typography>
          </Box>
          {token && (
            <Box id='UVADesktopMenu'>
              {navItems.map((item) => (
                <Button
                  component={Link}
                  to={item.path}
                  key={item.label}
                  className='uva-desktop-menu-item'
                >
                  {item.label}
                </Button>
              ))}
              <span id='UVADesktopAccountMenuContainer'>{accountMenu}</span>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {token === undefined || token === '' ? null : (
        <Box component='nav' id='UVAMobileNav'>
          <Drawer
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {mobileMenu}
          </Drawer>
          <span id='UVAMobileAccountMenuContainer'>{accountMenu}</span>
        </Box>
      )}
    </Box>
  )
}
