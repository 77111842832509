import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NotificationAddIcon from '@mui/icons-material/NotificationAdd'
import { LoadingButton } from '@mui/lab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert, AlertColor, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, Skeleton, Snackbar, Table, TableBody, TableCell, TableHead,
  TableRow
} from '@mui/material'
import { format } from 'date-fns'
import React, { useEffect } from 'react'
import { AppContext } from '../../app/App.context'
import { UvaAlert, UvaPssqTrack } from '../../services/api.models'
import PssqClient, { PssqTrackWithAlerts } from '../../services/pssq.service'
import { AlertDialog } from './components/alert-dialog.component'
import { TrackDialog } from './components/track-dialog.component'


// eslint-disable-next-line func-style
export const TracksPage: React.FC<unknown> = () => {
  const appContext = React.useContext(AppContext)
  const [state, setState] = React.useState<{
    tracks: PssqTrackWithAlerts[]
    showNewTrackDialog: boolean
    showNewAlertDialog?: string
    trackToDelete?: UvaPssqTrack
    alertToDelete?: UvaAlert
    deleteInProgress: boolean
    toast?: { severity: AlertColor; message: string }
    loading: boolean
  }>({
    tracks: [],
    showNewTrackDialog: false,
    showNewAlertDialog: undefined,
    trackToDelete: undefined,
    alertToDelete: undefined,
    deleteInProgress: false,
    toast: undefined,
    loading: false,
  })

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }))
    PssqClient.getTracks()
      .then((tracks) => {
        setState((prev) => ({ ...prev, tracks: tracks }))
      })
      .finally(() => setState((prev) => ({ ...prev, loading: false })))
  }, [])

  function activityName(activityId: string) {
    const activity = appContext.activities.find((r) => r.id === activityId)
    return activity ? activity.primary + '/' + activity.secondary : ''
  }

  function openTrackDialog() {
    setState({ ...state, showNewTrackDialog: true })
  }

  function openAlertDialog(trackId: string) {
    setState({ ...state, showNewAlertDialog: trackId })
  }

  function handleTrackSave(track: UvaPssqTrack) {
    PssqClient.getTracks()
      .then((tracks) => {
        setState({ ...state, tracks: tracks, showNewTrackDialog: false })
      })
      .catch(() =>
        setState({ ...state, tracks: [...state.tracks, {...track, alerts: []}], showNewTrackDialog: false }),
      )
  }

  function handleAlertSave() {
    PssqClient.getTracks()
      .then((tracks) => {
        setState({ ...state, tracks, showNewAlertDialog: undefined })
      })
      .catch(() =>
        // TODO: show some error???
        setState({ ...state, showNewAlertDialog: undefined }),
      )
  }


  function deleteTrack() {
    setState({ ...state, deleteInProgress: true })
    PssqClient.deleteTrack(state.trackToDelete?.id || '')
      .catch(() => {
        setState((prev) => ({
          ...prev,
          toast: { severity: 'error', message: 'Error deleting track' },
        }))
        throw new Error()
      })
      .then(() => PssqClient.getTracks())
      .then((tracks) => setState({ ...state, tracks: tracks, trackToDelete: undefined }))
      .finally(() =>
        setState((prev) => ({ ...prev, trackToDelete: undefined, deleteInProgress: false })),
      )
  }

  function deleteAlert() {
    setState({ ...state, deleteInProgress: true })
    PssqClient.deleteAlert(state.alertToDelete?.id || '')
      .catch(() => {
        setState((prev) => ({
          ...prev,
          toast: { severity: 'error', message: 'Error deleting alert' },
        }))
        throw new Error()
      })
      .then(() => PssqClient.getTracks())
      .then((tracks) => setState({ ...state, tracks: tracks, alertToDelete: undefined }))
      .finally(() =>
        setState((prev) => ({ ...prev, alertToDelete: undefined, deleteInProgress: false })),
      )
  }

  function nicknamToDelete() {
    if (state.trackToDelete) {
      return state.trackToDelete.nickname ? `${state.trackToDelete.nickname} track` : 'this track'
    } else {
      return state.alertToDelete?.nickname ? `${state.alertToDelete.nickname} alert` : 'this alert'
    }
  }

  return (
    <div id='UvaTracksPage'>
      <h1 className='uva-page-title page-header'>
        PSSQ Tracks
        {
          !state.loading && <Button variant='contained' color='primary' title='New PSSQ track' onClick={openTrackDialog}>
            Create new PSSQ track
          </Button>
        }
      </h1>
      <Card>
        {state.loading ? (
          <Grid container spacing={1} direction='column'>
            {Array(5)
              .fill(0)
              .map(function (v, i) {
                return (
                  <Grid key={'gridItem' + i} item>
                    <Skeleton
                      key={'skeleton' + i}
                      className='uva-skeleton uva-skeleton-table-header'
                      variant='rectangular'
                    />
                  </Grid>
                )
              })}
          </Grid>
        ) : (
          <>
            {state.tracks.map((track) => (
              <Accordion key={'track-' + track.id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  key={'track-summary' + track.id}
                >
                  <Grid container>
                    <Grid item xs={12} xl={3}>
                      <span className='track-nickname'>{track.nickname}</span>
                    </Grid>
                    <Grid item xs={12} xl={3}>
                      {appContext.rooms.find((r) => r.id === track.room_id)?.name}
                    </Grid>
                    <Grid item xs={12} xl={3}>
                      {appContext.pathogens.find((r) => r.id === track.pathogen_id)?.name}
                    </Grid>
                    <Grid item xs={12} xl={3}>
                      {track.alerts?.length} alert(s)
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <div className='track-details'>
                    <Grid container spacing={2}>
                      <Grid item xs='auto' className='detail-field'>
                        <span className='activity-name'>Activity: {activityName(track.activity_id)}</span>
                      </Grid>
                      <Grid item xs='auto'>
                        <span className='infection-rate'>Infection rate: {track.infection_rate * 100}%</span>
                      </Grid>
                      <Grid item xs='auto'>
                        <span className='interval-seconds'>Interval (s): {track.interval_seconds}</span>
                      </Grid>
                      <Grid item xs='auto'>
                        <span className='create-date'>Created at: {format(track.created_at, 'MM/dd/yyyy hh:mm:ss')}</span>
                      </Grid>
                    </Grid>
                    <div className='track-buttons'>
                      <Button
                        color='primary'
                        variant='contained'
                        title='Create alert'
                        startIcon={<NotificationAddIcon />}
                        onClick={() => openAlertDialog(track.id)}>
                        Create alert
                      </Button>
                      <Button
                        color='error'
                        variant='contained'
                        title='Delete track'
                        startIcon={<DeleteIcon />}
                        onClick={() => setState({ ...state, trackToDelete: track })}
                      >
                        Delete track
                      </Button>
                    </div>
                  </div>
                  <Table>
                    <TableHead>
                      <TableRow key={'trackAlertsHeaderRow' + track.id}>
                        <TableCell key={'alerts-header-nickname' + track.id} className='uva-string-label'>
                          Nickname
                        </TableCell>
                        <TableCell key={'alerts-header-treshold' + track.id} className='uva-numeric-label'>
                          Treshold
                        </TableCell>
                        <TableCell key={'alerts-header-debounce-time' + track.id} className='uva-numeric-label'>
                          Debounce Time (s)
                        </TableCell>
                        <TableCell key={'alerts-header-recipients' + track.id} className='uva-string-label'>
                          Recipients
                        </TableCell>
                        <TableCell key={'alerts-header-created' + track.id} className='uva-numeric-label'>
                          Created
                        </TableCell>
                        <TableCell key={'alerts-header-actions' + track.id} className='uva-numeric-label'></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody key='alerts-body'>
                      {track.alerts.map(alert => (
                        <TableRow key={'alert-' + alert.id}>
                          <TableCell key={'alerts-nickname' + alert.id} className='uva-string-label'>
                            {alert.nickname}
                          </TableCell>
                          <TableCell key={'alerts-treshold' + alert.id} className='uva-numeric-label'>
                            {alert.quanta_threshold}
                          </TableCell>
                          <TableCell key={'alerts-debounce-time' + alert.id} className='uva-numeric-label'>
                            {alert.debounce_seconds}
                          </TableCell>
                          <TableCell key={'alerts-recipients' + alert.id} className='uva-string-label'>
                            {alert.recipient_emails.join(', ')}
                          </TableCell>
                          <TableCell key={'alerts-created' + alert.id} className='uva-numeric-label'>
                            {format(alert.created_at, 'MM/dd/yyyy hh:mm:ss')}
                          </TableCell>
                          <TableCell key={'alerts-actions' + alert.id} className='uva-numeric-label'>
                            <IconButton
                              color='error'
                              title='Delete alert'
                              onClick={() => setState({ ...state, alertToDelete: alert })}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </Card>
      {state.showNewTrackDialog && (
        <TrackDialog
          onClose={() => setState({ ...state, showNewTrackDialog: false })}
          onSave={handleTrackSave}
        />
      )}
      {state.showNewAlertDialog && (
        <AlertDialog trackId={state.showNewAlertDialog}
          onClose={() => setState({ ...state, showNewAlertDialog: undefined })}
          onSave={handleAlertSave}
        />
      )}
      {(state.trackToDelete || state.alertToDelete) && (
        <Dialog
          open={!!state.trackToDelete || !!state.alertToDelete}
          onClose={() => setState({ ...state, trackToDelete: undefined, alertToDelete: undefined })}
        >
          <DialogTitle>Delete { state.trackToDelete ? 'track' : 'alert'}</DialogTitle>
          <DialogContent>
            Are you sure you want to delete{' '}
            {nicknamToDelete()}?
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setState({ ...state, trackToDelete: undefined, alertToDelete: undefined })}
              variant='contained'
              color='secondary'
            >
              Cancel
            </Button>
            <LoadingButton
              onClick={() => state.trackToDelete ? deleteTrack() : deleteAlert()}
              loading={state.deleteInProgress}
              variant='contained'
              color='error'
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {state.toast && (
        <Snackbar
          open={true}
          autoHideDuration={5000}
          onClose={() => setState({ ...state, toast: undefined })}
        >
          <Alert severity={state.toast.severity}>{state.toast.message}</Alert>
        </Snackbar>
      )}
    </div>
  )
}
