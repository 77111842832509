import React from 'react'
import { AppContext } from '../../app/App.context'

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  registerables,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'
import { Skeleton } from '@mui/material'

const quantaColor = 'rgba(255, 0, 0, 1)'
const maxQuantaColor = 'rgba(255, 0, 0, .5)'
const occupantsColor = 'rgba(0, 147, 149, 1)'
const modeColor = 'rgba(70, 113, 138, .4)'
const lineTension = 0.0

const MAX_QUANTA_DENSITY = 3
const MAX_DEVICE_CADR = 115
const MAX_OCCUPANCY = 20
const TICK_COUNT = 5

ChartJS.register(
  ...registerables,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
)

export interface SimulationRunData {
  id: number
  quanta?: number
  occupants: number
  targetQuantaDensity: number
  activityRate: number
  deviceCADR?: number
}

interface ResultsGraphLimits {
  quantaMax: number
  occupancyMax: number
  deviceCadrMax: number
}

export interface SimulationResultsGraphProps {
  results?: SimulationRunData[]
  description?: string
}

type UvaScalePosition = 'left' | 'right' | 'top' | 'bottom'

const scalePosLeft: UvaScalePosition = 'left'
const scalePosRight: UvaScalePosition = 'right'

export interface SimulationResultsTableProps {
  results?: SimulationRunData[]
  limits?: ResultsGraphLimits
  description?: string
}

// eslint-disable-next-line func-style
const SimulationResultsGraph: React.FC<SimulationResultsTableProps> = ({ results, limits }) => {
  const appContext = React.useContext(AppContext)

  const labels: number[] = []
  const quanta: number[] = []
  const maxQuanta: number[] = []
  const occupants: number[] = []
  const deviceCADR: number[] = []

  // const room = appContext.rooms.find(r => r.id)
  // const maxOccupants = room?.maxOccupancy

  const quantaMax: number = limits?.quantaMax || MAX_QUANTA_DENSITY
  const occupancyMax: number = limits?.occupancyMax || MAX_OCCUPANCY
  const deviceCadrMax: number = limits?.deviceCadrMax || MAX_DEVICE_CADR

  results?.map((row) => {
    labels.push(row.id)
    quanta.push(row.quanta ?? 0)
    occupants.push(row.occupants)
    maxQuanta.push(row.targetQuantaDensity)
    deviceCADR.push(row.deviceCADR ?? 0)
  })

  const data = {
    labels,
    datasets: [
      {
        label: 'Quanta',
        type: 'line' as const,
        data: quanta,
        borderColor: quantaColor,
        backgroundColor: quantaColor,
        yAxisID: 'yQuanta',
      },
      {
        label: 'Max Quanta',
        type: 'line' as const,
        data: maxQuanta,
        borderColor: maxQuantaColor,
        backgroundColor: maxQuantaColor,
        yAxisID: 'yQuanta',
      },
      {
        label: 'Occupants',
        type: 'line' as const,
        data: occupants,
        borderColor: occupantsColor,
        backgroundColor: occupantsColor,
        yAxisID: 'yOccupants',
      },
      {
        label: 'Device CADR',
        type: 'line' as const,
        backgroundColor: modeColor,
        borderColor: modeColor,
        data: deviceCADR,
        tension: lineTension,
        yAxisID: 'yDeviceCADR',
      },
    ],
  }

  const scales = {
    x: {
      display: true,
    },
    yQuanta: {
      position: scalePosLeft,
      min: 0,
      max: quantaMax,
      ticks: {
        stepSize: 1,
        color: quantaColor,
      },
    },
    yOccupants: {
      position: scalePosRight,
      min: 0,
      max: occupancyMax, // change to max occupancy
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 1,
        color: occupantsColor,
      },
    },
    yDeviceCADR: {
      min: 0,
      max: deviceCadrMax,
      grid: {
        display: false,
        borderWidth: 0,
      },
      ticks: {
        stepSize: 1,
        color: modeColor,
        callback: function (val) {
          switch (val % TICK_COUNT) {
            case 0:
              return val
            default:
              return null
          }
        },
      },
    },
  }

  const options = {
    pointRadius: 1,
    responsive: true,
    scales,
    animation: {
      duration: 0,
    },
  }

  return appContext.loading ? (
    <Skeleton variant='rectangular' className='uva-skeleton uva-skeleton-graph' />
  ) : (
    <Chart type='bar' options={options} data={data} />
  )
}

export default SimulationResultsGraph
