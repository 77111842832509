import React, { useState, useContext } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  Paper,
  Slider,
  Stack,
  TextField,
  InputLabel,
  CardActions,
  FormHelperText,
  Alert,
  AlertTitle,
} from '@mui/material'
import { AppContext } from '../../../../../app/App.context'
import { UvaActivity } from '../../../../../services/api.models'
import {
  UvaScenarioEvent,
  UvaFormFieldError,
  UvaScenario,
  UvaScenarioEventFormMode,
  UvaFormSubmissionError,
} from '../../../../../app/uva-interfaces'

const invalidActivityMsg = 'You must select an activity'
const invalidDurationMsg = 'Duration must be a number greater than 0'

const DEFAULT_MAX_OCCUPANCY = 20
const MAX_EVENT_ID = 100000000

export interface EventFormProps {
  formMode: UvaScenarioEventFormMode
  activeScenario: UvaScenario
  activeScenarioEvent: UvaScenarioEvent | undefined
  maxOccupants?: number
  closeEventForm: () => void
  updateScenarioEvent: (event: UvaScenarioEvent) => boolean
  addScenarioEvent: (event: UvaScenarioEvent) => boolean
}

// eslint-disable-next-line func-style
const ScenarioEventForm: React.FC<EventFormProps> = (props) => {
  if (props.formMode === 'Edit' && !props.activeScenarioEvent) {
    throw new Error('No scenario event to edit')
  }

  if (props.formMode === 'Edit' && !props.activeScenarioEvent?.id) {
    throw new Error('Scenario event has no id')
  }

  const newScenarioEventId = Math.floor(Math.random() * MAX_EVENT_ID).toString()
  let defaultScenarioEvent: UvaScenarioEvent = {
    id: newScenarioEventId,
    activityId: '',
    duration: 0,
    occupants: 0,
    order: 1,
  }

  if (props.formMode === 'Edit' && props.activeScenarioEvent) {
    defaultScenarioEvent = props.activeScenarioEvent
  }

  const [scenarioEvent, setScenarioEvent] = useState<UvaScenarioEvent>(defaultScenarioEvent)
  const [isActivityIdError, setIsActivityIdError] = useState<UvaFormFieldError>({
    error: false,
  })
  const [isDurationError, setIsDurationError] = useState<UvaFormFieldError>({
    error: false,
  })
  const [isFormError, setIsFormError] = useState<UvaFormSubmissionError>({
    status: undefined,
    message: undefined,
  })

  const appContext = useContext(AppContext)
  const maxOccupants = props.maxOccupants ? props.maxOccupants : DEFAULT_MAX_OCCUPANCY

  const formDefaults: UvaScenarioEvent = {
    id: props.activeScenarioEvent?.id ? props.activeScenarioEvent.id : 0,
    occupants: props.activeScenarioEvent?.occupants
      ? (props.activeScenarioEvent.occupants as number)
      : 0,
    activityId: props.activeScenarioEvent?.activityId
      ? (props.activeScenarioEvent.activityId as string)
      : '',
    duration: props.activeScenarioEvent?.duration
      ? (props.activeScenarioEvent.duration as number)
      : 0,
    order: props.activeScenarioEvent?.order ? (props.activeScenarioEvent.order as number) : 0,
  }

  const occupantsMarks = [
    {
      label: 0,
      value: 0,
    },
    {
      label: props.maxOccupants ? props.maxOccupants.toString() : DEFAULT_MAX_OCCUPANCY,
      value: props.maxOccupants ? props.maxOccupants : DEFAULT_MAX_OCCUPANCY,
    },
  ]

  function validateActivityId(val: string | number | undefined): boolean {
    if (!val || (typeof val === 'string' && val.trim() === '')) {
      return false
    }
    return true
  }

  function validateDuration(val: string | number | undefined): boolean {
    if (val === undefined || val === '') {
      return false
    }
    if (typeof val === 'string') {
      const isInt = parseInt(val)
      if (isNaN(isInt) || parseInt(val) < 0) {
        return false
      }
    }
    return true
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleOccupantsChange(event: any) {
    const val = (event.target as HTMLInputElement).value
    const numericVal = parseInt(val)
    if (isNaN(numericVal)) {
      throw new Error('Invalid occupant')
    } else {
      setScenarioEvent({
        ...scenarioEvent,
        occupants: numericVal,
      })
    }
  }

  function handleActivityChange(event: any) {
    const val = (event.target as HTMLInputElement).value
    if (!validateActivityId(val)) {
      setIsActivityIdError({
        error: true,
        message: 'Activity is required',
      })
    } else {
      setScenarioEvent({
        ...scenarioEvent,
        activityId: val,
      })
      setIsActivityIdError({
        error: false,
        message: undefined,
      })
    }
  }

  function handleDurationChange(event: any) {
    const val = (event.target as HTMLInputElement).value

    if (!validateDuration(val)) {
      setIsDurationError({
        error: true,
        message: 'Duration must be a positive integer',
      })
    } else {
      const numericVal = parseFloat(val)
      if (!isNaN(numericVal)) {
        setScenarioEvent({
          ...scenarioEvent,
          duration: numericVal,
        })
        setIsDurationError({
          error: false,
          message: undefined,
        })
      }
    }
  }

  function handleScenarioEventAction() {
    const isActivityValid = validateActivityId(scenarioEvent.activityId)
    const isDurationValid = validateDuration(scenarioEvent.duration)

    if (!isActivityValid) {
      setIsActivityIdError({
        error: true,
        message: invalidActivityMsg,
      })
    } else {
      setIsActivityIdError({
        error: false,
        message: undefined,
      })
    }

    if (!isDurationValid) {
      setIsDurationError({
        error: true,
        message: invalidDurationMsg,
      })
    } else {
      setIsDurationError({
        error: false,
        message: undefined,
      })
    }

    if (isActivityValid && isDurationValid) {
      if (props.formMode === 'Add') {
        const results = props.addScenarioEvent(scenarioEvent)
        if (results) {
          console.log('Event added successfully')
        } else {
          throw new Error('Failed to add scenario event')
        }
      } else if (props.formMode === 'Edit') {
        const results = props.updateScenarioEvent(scenarioEvent)
        if (results) {
          console.log('Event updated successfully')
        } else {
          throw new Error('Failed to update scenario event')
        }
      }
    } else {
      setIsFormError({
        status: 'error',
        message: 'There is a problem with one or more form entries. Please check below.',
      })
    }
  }

  return (
    <Paper component='div'>
      {isFormError.status === 'error' && (
        <Alert variant='outlined' severity='error' className='uva-alert'>
          <AlertTitle>Error</AlertTitle>
          <p>{isFormError.message}</p>
        </Alert>
      )}
      <Card className='uva-form-card'>
        <CardHeader title={`${props.formMode.toString()} Event`}></CardHeader>
        <CardContent>
          {props.formMode && (
            <>
              <Stack className='uva-form-control'>
                <InputLabel>Occupants</InputLabel>
                <Slider
                  key='OccupantsSlider'
                  valueLabelDisplay='auto'
                  marks={occupantsMarks}
                  id='uvaOccupantsCtl'
                  defaultValue={formDefaults.occupants as number}
                  max={maxOccupants}
                  name='occupants'
                  onChange={(e) => handleOccupantsChange(e)}
                ></Slider>
              </Stack>
              <FormControl variant='filled' fullWidth className='uva-form-control'>
                <InputLabel error={isActivityIdError.error}>Activity</InputLabel>
                <Select
                  variant='filled'
                  fullWidth
                  id='uvaActivityIdCtl'
                  name='activityId'
                  defaultValue={formDefaults.activityId}
                  onBlur={handleActivityChange}
                  onChange={handleActivityChange}
                  onSelect={handleActivityChange}
                  error={isActivityIdError.error}
                >
                  <MenuItem value={''} key='UvaActivityId-undefined'>
                    Select one
                  </MenuItem>
                  {appContext?.activities.map((activity: UvaActivity) => (
                    <MenuItem value={activity.id} key={`UvaActivityId-${activity.id}`}>
                      {activity.primary.toUpperCase()} - {activity.secondary} (
                      {activity.enhancementRate})
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={isActivityIdError.error}>
                  {isActivityIdError.message}
                </FormHelperText>
              </FormControl>
              <TextField
                id='uvaDurationCtl'
                label='Activity Duration'
                variant='filled'
                name='duration'
                defaultValue={formDefaults.duration}
                fullWidth
                className='uva-form-control'
                error={isDurationError.error}
                helperText={isDurationError.message}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onChange={handleDurationChange}
              />
              <CardActions className='uva-form-actions'>
                <Button variant='contained' color='secondary' onClick={props.closeEventForm}>
                  Cancel
                </Button>
                <Button variant='contained' onClick={handleScenarioEventAction}>
                  {props.formMode === 'Add' ? 'Add' : 'Update'}
                </Button>
              </CardActions>
            </>
          )}
        </CardContent>
      </Card>
    </Paper>
  )
}

export default ScenarioEventForm
