import React from 'react'
import { UvaActivity, UvaPathogen, UvaRoom } from '../services/api.models'
import { UvaUser } from './uva-interfaces'

/**
 * AppContext should contain data shared across the whole application.
 * Generally, it is data like logged user, settings, etc.
 */
export interface AppContextModel {
  loading: boolean
  // TODO: if these do not end up reused on multiple pages then move them to a page state
  activities: Readonly<UvaActivity>[]
  pathogens: Readonly<UvaPathogen>[]
  rooms: Readonly<UvaRoom>[]
  simulationModeKey: string
  fixedSimulationParamsKey: string
  simulationSpeedKey: string
  savedScenariosKey: string
  activeScenarioIdKey: string
  token?: string
  user?: UvaUser
}

export interface AppContextWithSetter extends AppContextModel {
  patchContext: (ctx: Partial<AppContextModel>) => void
}

export const AppContext = React.createContext<AppContextWithSetter>({} as AppContextWithSetter)
