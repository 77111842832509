import { Pause, PlayArrow } from '@mui/icons-material'
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Slider,
} from '@mui/material'
import React from 'react'
import { AppContext } from '../../../../app/App.context'

export interface VariableParamsPanelProps {
  activityId: string
  maxOccupants: number
  simulationRuns: boolean
  change: (activityId: string, occupants: number) => void
  toggle: () => void
  export: () => void
}

// eslint-disable-next-line func-style
const VariableParamsPanel: React.FC<VariableParamsPanelProps> = (props) => {
  const appContext = React.useContext(AppContext)
  const [state, setState] = React.useState<{
    activityId: string
    occupants: number
    error: string | null
  }>({
    // eslint-disable-next-line
    activityId: appContext.activities[0]?.id || '',
    occupants: 0,
    error: null,
  })

  React.useEffect(() => {
    setState((prev) => ({ ...prev, occupants: 0 }))
  }, [props.maxOccupants])

  function toggle() {
    if (!props.simulationRuns) {
      if (!state.activityId || Number.isNaN(+state.occupants)) {
        setState((prev) => ({ ...prev, error: 'Enter valid simulation parameters' }))
        return
      }

      props.change(state.activityId, +state.occupants)
    }

    setState((prev) => ({ ...prev, error: null }))
    props.toggle()
  }

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: props.maxOccupants,
      label: props.maxOccupants.toString(),
    },
  ]

  return appContext.loading ? (
    <Skeleton
      className='uva-skeleton uva-skeleton-panel uva-skeleton-panel-small'
      variant='rectangular'
      id='VARIABLEPARAMSPANEL'
    />
  ) : (
    <Card className='uva-params-panel' id='UvaVariableParamsPanel'>
      <CardHeader title='Variable Simulation Parameters' />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl variant='filled' fullWidth className='uva-form-control'>
              <InputLabel id='activity-label'>Activity</InputLabel>
              <Select
                id='activity-filter'
                labelId='activity-label'
                label='Activity'
                onChange={(event) =>
                  setState((prev) => ({ ...prev, activityId: event.target.value }))
                }
                disabled={props.simulationRuns}
                className='uva-form-control'
                value={state.activityId}
              >
                {appContext.activities.map((activity) => (
                  <MenuItem value={activity.id} key={`Activity${activity.id}`}>
                    {activity.primary} - {activity.secondary} ({activity.enhancementRate})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* eslint-disable-next-line no-magic-numbers */}
          {props.maxOccupants > 0 && ( // only render this slider if props.maxOccupancy is set and valid
            <Grid item xs={12}>
              <InputLabel id='occupancy-label'>Occupancy</InputLabel>
              <Slider
                aria-labelledby='occupancy-label'
                step={1}
                min={0}
                max={props.maxOccupants}
                disabled={props.simulationRuns}
                valueLabelDisplay='auto'
                marks={marks}
                value={state.occupants}
                id='UvaMaxOccupantsSlider'
                onChange={(e, val) => setState((prev) => ({ ...prev, occupants: val as number }))}
              />
            </Grid>
          )}
          {state.error && (
            <Grid item xs={12}>
              <Alert severity='error'>{state.error}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <div className='uva-form-actions'>
              <Button
                onClick={toggle}
                variant='contained'
                id='UvaVariableSimulationButton'
                data-test-id='play-test-btn'
              >
                {props.simulationRuns ? <Pause /> : <PlayArrow />}
              </Button>
              <Button onClick={props.export} variant='contained' disabled={props.simulationRuns}>
                Export
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default VariableParamsPanel
