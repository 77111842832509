import React from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  registerables,
  ChartDataset,
  ChartOptions,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { differenceInHours, format } from 'date-fns'

const QUANTA_OCCUPANCY_COLOR = 'rgba(0, 147, 199, .5)'
const QUANTA_CO2_COLOR = 'rgba(0, 147, 199, .9)'
const QUANTA_CO2_DB_COLOR = 'rgba(0, 147, 199, .6)'
const QUANTA_CO2_HISTORY_COLOR = 'rgba(102, 147, 199, .9)'
const QUANTA_COLOR = 'rgba(0, 147, 199, 1)'
const MAX_QUANTA_COLOR = 'rgba(255, 0, 0, .3)'
const OCCUPANTS_COLOR = 'rgba(113, 178, 57, .9)'
const ADD_SERIES_COLOR_A = '#7E5333'
const ADD_SERIES_COLOR_B = '#B98853'
const DEFAULT_LINE_DASH = 3

ChartJS.register(
  ...registerables,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
)

export interface LabReportGraphProps {
  timestamps: Date[]
  occupancy: (number|undefined)[]
  maxQuanta: number
  quantaCo2History: (number|undefined)[]
  quantaCo2dBHistory?: (number|undefined)[]
  quantaOccupancy?: (number|undefined)[]
  quantaCo2?: (number|undefined)[]
  addSeriesA?: {
    label: string
    values: (number|undefined)[]
  }
  addSeriesB?: {
    label: string
    values: (number|undefined)[]
  }
}

// eslint-disable-next-line func-style
const LabReportGraph: React.FC<LabReportGraphProps> = (props) => {
  const CURRENT_TIMEZONE_STRING = new Date()
    .toString()
    .match(/\((.*)\)/)
    ?.pop()
  const chartOptions: ChartOptions<'line'> = {
    spanGaps: true,
    scales: {
      xAxis: {
        display: true,
        offset: true,
        grid: {
          display: false,
        },
      },
      yQuanta: {
        position: 'left',
        min: 0,
        ticks: {
          color: QUANTA_COLOR,
        },
        title: {
          display: true,
          text: 'Pathogen Quanta',
        },
      },
      yOccupants: {
        position: 'right',
        min: 0,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
          color: OCCUPANTS_COLOR,
        },
        title: {
          display: true,
          text: 'Occupants',
        },
      },
      ySeriesA: {
        display: props.addSeriesA ? true : false,
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          color: ADD_SERIES_COLOR_A,
        },
        title: {
          display: true,
          text: props.addSeriesA?.label || 'Environment Metric Value',
        },
      },
      ySeriesB: {
        position: 'right',
        display: props.addSeriesB ? true : false,
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          color: ADD_SERIES_COLOR_B,
        },
        title: {
          display: true,
          text: props.addSeriesB?.label || 'Environment Metric Value',
        },
      },
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        display: true,
        position: 'bottom',
      },
      title: {
        display: true,
        text: `Report Metrics in ${CURRENT_TIMEZONE_STRING || 'your timezone'}`,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    animation: {
      duration: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  }

  let labelFromat = 'h:mm:ss a'
  if (
    props.timestamps.length &&
    differenceInHours(props.timestamps[props.timestamps.length - 1], props.timestamps[0]) > 24
  ) {
    labelFromat = 'MM/dd/yyyy h:mm:ss a'
  }

  const datasets: ChartDataset<'line', (number|undefined)[]>[] = [
    {
      label: 'Max Quanta',
      data: Array(props.timestamps.length).fill(props.maxQuanta),
      borderColor: MAX_QUANTA_COLOR,
      backgroundColor: MAX_QUANTA_COLOR,
      xAxisID: 'xAxis',
      yAxisID: 'yQuanta'
    },
    {
      label: 'Occupancy',
      data: props.occupancy,
      borderColor: OCCUPANTS_COLOR,
      backgroundColor: OCCUPANTS_COLOR,
      stepped: true,
      xAxisID: 'xAxis',
      yAxisID: 'yOccupants',
    },
    {
      label: 'Quanta History (CO2)',
      data: props.quantaCo2History,
      xAxisID: 'xAxis',
      yAxisID: 'yQuanta',
      borderColor: QUANTA_CO2_HISTORY_COLOR,
      backgroundColor: QUANTA_CO2_HISTORY_COLOR,
    }
  ]

  if (props.quantaOccupancy) {
    datasets.push({
      label: 'Computed Quanta (Occupancy)',
      data: props.quantaOccupancy,
      xAxisID: 'xAxis',
      yAxisID: 'yQuanta',
      borderColor: QUANTA_OCCUPANCY_COLOR,
      backgroundColor: QUANTA_OCCUPANCY_COLOR,
    })
  }
  if (props.quantaCo2) {
    datasets.push({
      label: 'Computed Quanta (CO2)',
      data: props.quantaCo2,
      xAxisID: 'xAxis',
      yAxisID: 'yQuanta',
      borderColor: QUANTA_CO2_COLOR,
      backgroundColor: QUANTA_CO2_COLOR,
    })
  }
  if (props.quantaCo2dBHistory) {
    datasets.push({
      label: 'Computed Quanta (CO2 + dB)',
      data: props.quantaCo2dBHistory,
      xAxisID: 'xAxis',
      yAxisID: 'yQuanta',
      borderColor: QUANTA_CO2_DB_COLOR,
      backgroundColor: QUANTA_CO2_DB_COLOR,
    })
  }

  if (props.addSeriesA) {
    datasets.push({
      label: props.addSeriesA.label,
      data: props.addSeriesA.values,
      borderColor: ADD_SERIES_COLOR_A,
      backgroundColor: ADD_SERIES_COLOR_A,
      xAxisID: 'xAxis',
      yAxisID: 'ySeriesA',
    })
  }
  if (props.addSeriesB) {
    datasets.push({
      label: props.addSeriesB.label,
      data: props.addSeriesB.values,
      borderColor: ADD_SERIES_COLOR_B,
      backgroundColor: ADD_SERIES_COLOR_B,
      xAxisID: 'xAxis',
      yAxisID: 'ySeriesB',
    })
  }

  const chartPlugins = [
    {
      id: 'vertical_line',
      afterDraw: (chart) => {
        if (chart.tooltip?._active?.length) {
          const x = chart.tooltip._active[0].element.x
          let yAxis = {}
          let maxYValue = 0
          for (const [key, value] of Object.entries(chart.scales)) {
            if (!key.startsWith('y') || !value) {
              continue
            }
            if (value['max'] > maxYValue) {
              maxYValue = value['max']
              yAxis = chart.scales[key]
            }
          }
          const ctx = chart.ctx
          ctx.save()
          ctx.beginPath()
          ctx.moveTo(x, yAxis['top'])
          ctx.lineTo(x, yAxis['bottom'])
          ctx.lineWidth = 3
          ctx.strokeStyle = 'rgba(255, 246, 143, 0.4)'
          ctx.setLineDash([DEFAULT_LINE_DASH])
          ctx.stroke()
          ctx.restore()
        }
      },
    },
  ]

  return (
    <Line
      options={chartOptions}
      plugins={chartPlugins}
      redraw={true}
      data={{
        labels: props.timestamps.map((timestamp) => format(timestamp, labelFromat)),
        datasets,
      }}
    />
  )
}

export default LabReportGraph
