/* eslint-disable camelcase */
/* eslint-disable no-magic-numbers */
import {
  Card,
  Grid,
  Typography,
  CardMedia,
  Box,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Autocomplete,
  TextField,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  CircularProgress,
  Modal,
  IconButton,
} from '@mui/material'
import { Doughnut, Bar, Line, Chart } from 'react-chartjs-2'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { buildRoomMap } from './components/room-selector/room-selector.component'
import { SCHOOL } from './room-mocks'
import chroma from 'chroma-js'

import type { HierarchicalRoomWithGeoDataType } from './room-mocks'
import { useNavigate } from 'react-router-dom'
import { Info, Room, Warning } from '@mui/icons-material'
import AccountLogo from '../../assets/Butler53Logo.png'
import fetchContinuumService from '../../services/continuum.service'
import RecentAverageRiskCard from './ContinuumDashboardCards/RecentAverageRiskCard'
import DynamicTreatmentEnergySavingsCard from './ContinuumDashboardCards/DynamicTreatmentEnergySavingsCard'
import LocationsNeedingAttentionCard from './ContinuumDashboardCards/LocationsNeedingAttentionCard'
export const DEFAULT_QUANTA_THRESHOLD = 0.004


import type { UvaRoom as UvaRoomType } from '../../services/api.models'

import type {
  HighRiskLocationsResponseType,
  RoomsHealthScoreResponseType,
  RecentAverageRiskResponseType,
  EnergyUsageResponseType,
  RoomsNeedingAttentionResponseType,
} from '../../services/continuum.service'

// const RiskAnalysisByTimeframe = ({ timeFrame }: { timeFrame: string }) => {
//   let labels: string[] = []
//   let data: number[] = []
//   let thresholdData: number[] = []
//   if (timeFrame === 'day') {
//     labels = [...Array(24)].map((x, i) => {
//       if (i < 12) {
//         if (i === 0) {
//           return '12a'
//         }
//         return `${i}a`
//       }
//       if (i === 12) {
//         return '12p'
//       }
//       return `${i - 12}p`
//     })
//     thresholdData = [...Array(24)].map((x, i) => {
//       return 25
//     })
//     data = [...Array(24)].map((x, i) => {
//       if (i < 6 || i > 18) {
//         return getRandomNumber(0, 10)
//       }
//       return getRandomNumber(10, 75)
//     })
//   } else if (timeFrame === 'week') {
//     labels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
//     thresholdData = [...Array(7)].map((x, i) => {
//       return 25
//     })
//     data = [...Array(7)].map((x, i) => {
//       if (i === 0 || i === 6) {
//         return getRandomNumber(0, 10)
//       }
//       return getRandomNumber(10, 50)
//     })
//   } else if (timeFrame === 'month') {
//     labels = [...Array(12)].map((x, i) =>
//       new Date(0, i + 1, 0).toLocaleDateString('en', { month: 'short' }),
//     )
//     thresholdData = [...Array(12)].map((x, i) => {
//       return 25
//     })
//     data = [...Array(12)].map((x, i) => getRandomNumber(10, 50))
//   }
//   return (
//     <>
//       <Line
//         data={{
//           labels: labels,
//           datasets: [
//             {
//               data: data,
//               borderColor: '#0093c8',
//               fill: {
//                 target: '+1',
//                 above: 'rgb(0, 147, 199, 0.2)',
//                 below: 'transparent',
//               },
//             },
//             {
//               data: thresholdData,
//               borderColor: 'red',
//             },
//           ],
//         }}
//         options={{
//           responsive: true,
//           maintainAspectRatio: false,
//           plugins: { legend: { display: false } },
//           elements: {
//             line: {
//               tension: 0.3,
//             },
//             point: {
//               radius: 0,
//             },
//           },
//           scales: {
//             x: {
//               grid: {
//                 display: false,
//               },
//             },
//             y: {
//               display: false,
//               grid: {
//                 display: false,
//               },
//             },
//           },
//         }}
//       />
//     </>
//   )
// }

// Location Health Component
function PerLocationHealth({
  roomsHealthData,
  rooms,
  onClick,
  onHover,
}: {
  roomsHealthData: any
  rooms: UvaRoomType[]
  onClick: any
  onHover: any
}) {
  const getFormattedQuantaValue = (percentOfQuantaThreshold) => {
    if (percentOfQuantaThreshold < 100) {
      return 100 - percentOfQuantaThreshold
    }
    if (percentOfQuantaThreshold > 200) {
      return -100 // cap the risk amount at 200% (-100) of threshold for visualization
    }
    if (percentOfQuantaThreshold > 100) {
      return (percentOfQuantaThreshold - 100) * -1
    }
    return 0
  }
  const findRoomLabel = (id, roomsList) => {
    const matchedRoom = roomsList.find((room) => room.id === id)
    const roomName = matchedRoom.name ?? ''
    if (roomName.length >= 20) {
      return roomName.slice(0, 20) + '...'
    }
    return roomName
  }
  // sort based on decreasing percentOfQuanta
  roomsHealthData.sort((a, b) => b.percentOfQuantaThreshold - a.percentOfQuantaThreshold)
  const roomDataWithMockLabels = roomsHealthData.map((room, i) => {
    const { room_id, percentOfQuantaThreshold } = room
    return {
      id: room_id,
      label: findRoomLabel(room_id, rooms),
      roomScore: getFormattedQuantaValue(percentOfQuantaThreshold),
    }
  })
  return (
    <>
      <Bar
        data={{
          labels: roomDataWithMockLabels.map((room) => room.label),
          datasets: [
            {
              data: roomDataWithMockLabels.map((room) => room.roomScore),
              label: 'Health Score',
              borderColor: '#0093c8',
              hoverBorderWidth: 3,
              hoverBorderColor: '#FFEA00',
              backgroundColor: roomDataWithMockLabels.map((room) => {
                if (room.roomScore >= 0) {
                  return '#0093c8'
                }
                return chroma
                  .scale(['#0093c8', chroma('red').alpha(0.7).hex()])
                  .domain([0, 0.1, 1])((room.roomScore / 100) * -1)
                  .hex()
              }),
            },
          ],
        }}
        options={{
          onClick: onClick,
          onHover: onHover,
          indexAxis: 'x',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: { display: false },
            tooltip: {
              filter(e) {
                return e.datasetIndex === 0
              },
            },
          },
          scales: {
            x: {
              display: false,
              grid: {
                display: false,
                drawBorder: false,
              },
              stacked: true,
            },
            y: {
              display: true,
              max: 110,
              title: {
                font: {
                  size: 20,
                },
                padding: 10,
                display: true,
                text: 'Health Score',
              },
              min: -110,
              ticks: {
                display: false,
                maxTicksLimit: 3,
                mirror: false,
                // callback: (val) => {
                //   // Only show 0 line
                //   if (Number(val) > 0) {
                //     return 'Increased Health'
                //   }
                //   if (Number(val) < 0) {
                //     return 'Decreased Health'
                //   }
                //   if (Number(val) === 0) {
                //     return ''
                //   }
                // },
              },
              grid: {
                display: true,
                drawBorder: false,
                lineWidth: (context) => (context.tick.value === 0 ? 3 : 0), //Set only 0 line visible
                color: 'darkgray',
              },
              stacked: false,
            },
          },
        }}
      />
    </>
  )
}

const DesiredVsTreatedAirTreatment = () => {
  let labels: string[] = []
  let desiredAirCADR: number[] = []
  let actualAirCADR: number[] = []
  let percentDifference: number[] = []
  const allDates = [...Array(24)]
    .map((x, i) => new Date(new Date().getTime() - i * 60 * 60 * 1000))
    .reverse()
  labels = allDates.map((x, i) => x.toLocaleTimeString('en-US', { timeStyle: 'short' }))
  desiredAirCADR = allDates.map((x, i) => {
    const currDate = new Date(x)
    if (currDate.getUTCHours() < 12 || currDate.getUTCHours() > 22) {
      return getRandomNumber(1, 8)
    }
    return getRandomNumber(30, 75)
  })
  actualAirCADR = allDates.map((x, i) => {
    const currDate = new Date(x)
    if (currDate.getUTCHours() < 12 || currDate.getUTCHours() > 22) {
      return desiredAirCADR[i] * (Math.random() * (1.0 - 0.95) + 0.95)
    }
    return desiredAirCADR[i] * (Math.random() * (0.9 - 0.5) + 0.5)
  })
  percentDifference = allDates.map((x, i) => {
    return (actualAirCADR[i] / desiredAirCADR[i]) * 100
  })

  return (
    <>
      <Chart
        type='line'
        data={{
          labels: labels,
          datasets: [
            {
              label: 'Air Treatment Performance',
              // fill: true,
              data: percentDifference,
              // backgroundColor: (context: ScriptableContext<'line'>) => {
              //   const ctx = context.chart.ctx
              //   const gradient = ctx.createLinearGradient(0, 230, 0, 50)
              //   gradient.addColorStop(1, 'rgba(128,128,128,0.2)')
              //   gradient.addColorStop(0.2, 'rgba(128,128,128,0.0)')
              //   gradient.addColorStop(0, 'rgba(128,128,128,0)')
              //   return gradient
              // },
              borderColor: 'gray',
              borderWidth: 3,
            },
            {
              type: 'bar' as const,
              data: actualAirCADR,
              label: 'Actual Air CADR',
              borderColor: '#0093c8',
              backgroundColor: '#0093c8',
            },
            {
              type: 'bar' as const,
              data: desiredAirCADR,
              label: 'Desired Air CADR',
              borderColor: '#0093c8',
              borderWidth: 3,
              backgroundColor: 'transparent',
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: { legend: { position: 'bottom' } },
          elements: {
            line: {
              tension: 0.3,
            },
            point: {
              radius: 0,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              stacked: true,
            },
            y: {
              display: false,
              grid: {
                display: false,
              },
              // stacked: true,
            },
          },
        }}
      />
    </>
  )
}

function FacilityHealthSummary({ roomHealthPercentage }: { roomHealthPercentage: number }) {
  let healthString = 'Your facility health'
  let indicatorString = ' is unknown'
  if (roomHealthPercentage < 33) {
    healthString = 'Your facility health '
    indicatorString = 'Needs Improvement'
  } else if (roomHealthPercentage < 66) {
    healthString = 'Your facility health is '
    indicatorString = 'Average'
  } else {
    healthString = 'Your facility health is '
    indicatorString = 'Great!'
  }
  return (
    <Box>
      {/* <Typography variant='body1' color='text.secondary'>
        {healthString}
      </Typography>
      <Typography variant='h6'>{indicatorString}</Typography> */}
      {/* <br /> */}
      <Typography sx={{ marginTop: 'auto' }} variant='body2' color='text.secondary'>
        powered by
        <br />
        <b>UVA PSSQv3 &copy;</b>
      </Typography>
    </Box>
  )
}

function getRandomNumber(min, max) {
  return Math.round(Math.random() * (max - min) + min)
}

const riskChartOptions = {
  day: {
    title: 'Average Risk by Time of Day',
    description:
      'Facilities typically operate cyclically. Determining when you need to be at the top of your game can be vital to maintaining your environmental health throughout the day',
  },
  week: {
    title: 'Average Risk by Day of Week',
    description:
      'Facilities typically operate cyclically. Determining when you need to be at the top of your game can be vital to maintaining your environmental health throughout the week',
  },
  month: {
    title: 'Average Risk by Month of Year',
    description:
      'Facilities typically operate cyclically. Determining when you need to be at the top of your game can be vital to maintaining your environmental health throughout the year',
  },
}

function getMoldRiskString(moldRiskPercentage) {
  if (moldRiskPercentage < 40) {
    return 'Low'
  }
  if (moldRiskPercentage < 75) {
    return 'Med'
  }
  return 'High'
}

function HealthScoreCard({
  roomHealthPercentage,
  roomHealthColor,
}: {
  roomHealthPercentage: number
  roomHealthColor: string
}) {
  return (
    <>
      <CardContent sx={{ width: '60%' }}>
        <Typography gutterBottom variant='h5' component='div'>
          Facility Health Score
        </Typography>
        <FacilityHealthSummary roomHealthPercentage={roomHealthPercentage}></FacilityHealthSummary>
      </CardContent>

      <CardMedia component='div' sx={{ width: '40%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography
            sx={{ position: 'absolute', typography: { xl: 'h4', lg: 'h5', sm: 'h5' } }}
            component='div'
          >
            {roomHealthPercentage}
          </Typography>
          <Doughnut
            data={{
              labels: ['Environmental Health'],
              datasets: [
                {
                  label: 'Facility Health',
                  data: [roomHealthPercentage, 100 - roomHealthPercentage],
                  backgroundColor: [roomHealthColor, 'rgba(0, 0, 0, 0)'],
                  borderColor: [roomHealthColor, roomHealthColor],
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: { legend: { display: false } },
            }}
          />
        </Box>
      </CardMedia>
    </>
  )
}

function HighRiskLocationCard({ locations }: { locations: any }) {
  const labels = locations.daysThresholdResults.map((location) =>
    new Date(location.day).toLocaleDateString(),
  )
  const data = locations.daysThresholdResults.map((location) => location.aboveThresholdCount)
  return (
    <>
      <CardContent sx={{ width: '60%' }}>
        {' '}
        <Typography gutterBottom variant='h5' component='div'>
          High Risk Locations
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          The number of locations recently flagged as high-risk.
        </Typography>
      </CardContent>
      <CardMedia component='div' sx={{ width: '40%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Bar
            data={{
              labels,
              datasets: [
                {
                  data,
                  backgroundColor: data.map((d, i) => {
                    if (data.length - 1 === i) {
                      return '#0093c8'
                    }
                    return chroma('gray').alpha(0.3).hex()
                  }),
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: { legend: { display: false } },
              scales: {
                x: {
                  display: false,
                  grid: {
                    display: false,
                  },
                },
                y: {
                  display: false,
                  grid: {
                    display: false,
                  },
                },
              },
            }}
          />
        </Box>
      </CardMedia>
    </>
  )
}

function MoldRiskCard({ riskLevel }: { riskLevel: number | undefined }) {
  const moldRiskColor = riskLevel
    ? chroma
      .scale(['#0093c8', chroma('red').alpha(0.7).hex()])(riskLevel / 100)
      .hex()
    : '#0093c8'
  return (
    <Card sx={{ display: 'flex', height: 175, alignItems: 'center', justifyContent: 'center' }}>
      {riskLevel !== undefined ? (
        <>
          <CardContent sx={{ width: '60%' }}>
            <Typography gutterBottom variant='h5' component='div'>
              Mold Risk
            </Typography>
            <Typography variant='body1' color='text.secondary'>
              Based on the number of consecutive mold risk days throughout your environment
            </Typography>
          </CardContent>
          <CardMedia sx={{ width: '40%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Typography
                sx={{ position: 'absolute', typography: { xl: 'h4', lg: 'h5', sm: 'h5' } }}
                component='div'
              >
                {getMoldRiskString(riskLevel)}
              </Typography>
              <Bar
                data={{
                  labels: ['Mold Risk'],
                  datasets: [
                    {
                      data: [riskLevel],
                      label: 'Mold Risk',
                      borderColor: moldRiskColor,
                      backgroundColor: moldRiskColor,
                    },
                    {
                      data: [100 - riskLevel],
                      borderColor: moldRiskColor,
                      borderWidth: 3,
                      backgroundColor: 'transparent',
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    x: {
                      display: false,
                      grid: {
                        display: false,
                      },
                      stacked: true,
                    },
                    y: {
                      display: false,
                      grid: {
                        display: false,
                      },
                      stacked: true,
                    },
                  },
                }}
              />
            </Box>
          </CardMedia>
        </>
      ) : (
        <CircularProgress size={50} />
      )}
    </Card>
  )
}

export interface MonitoringOverviewPageProps {
  rooms: UvaRoomType[]
}

// eslint-disable-next-line func-style
export const MonitoringOverviewPage: React.FC<PropsWithChildren<MonitoringOverviewPageProps>> = ({
  rooms,
}) => {
  const navigate = useNavigate()
  const [roomHealthPercentage, setRoomHealthPercentage] = useState<number | undefined>(undefined)
  const [roomHealthColor, setRoomHealthColor] = useState<string>('green')
  const [moldRisk, setMoldRisk] = useState<number | undefined>(undefined)
  const [roomsHealthData, setRoomsHealthData] = useState<
    RoomsHealthScoreResponseType['aggregatedData'] | null
  >(null)
  const [highRiskLocations, setHighRiskLocations] = useState<HighRiskLocationsResponseType | null>(
    null,
  )
  const [recentAverageRiskData, setRecentAverageRiskData] =
    useState<RecentAverageRiskResponseType | null>(null)
  const [energyUsageData, setEnergyUsageData] = useState<EnergyUsageResponseType | null>(null)
  const [hasRequestBeenMade, setHasRequestBeenMade] = useState<boolean>(false)
  const [roomsNeedingAttention, setRoomsNeedingAttention] =
    useState<RoomsNeedingAttentionResponseType | null>(null)
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = React.useState(false)

  useEffect(() => {
    if (rooms.length > 0 && !hasRequestBeenMade) {
      setHasRequestBeenMade(true)
      const roomIDList = rooms ? rooms.map((data) => (data.id ? data.id : 'null')) : []

      const fetchMoldRisk = async (roomids) => {
        const daysOfHistory = 5
        const res = await fetchContinuumService.fetchMoldRisk(roomids, daysOfHistory)
        setMoldRisk(Number(res.moldRiskPercentage))
      }
      fetchMoldRisk(roomIDList)

      const fetchHealthScoreAndSet = async (roomids) => {
        const averageQuantaThresholdScore = DEFAULT_QUANTA_THRESHOLD
        const minutesDuration = 60
        const healthData = await fetchContinuumService.fetchHealthScore(
          roomids,
          averageQuantaThresholdScore,
          minutesDuration,
        )
        setRoomHealthPercentage(healthData?.healthScore)
      }
      fetchHealthScoreAndSet(roomIDList)

      const fetchRoomsHealthScore = async (roomids) => {
        const hoursOfHistory = 1
        const res = await fetchContinuumService.fetchRoomsHealthScore(
          roomids,
          DEFAULT_QUANTA_THRESHOLD,
          hoursOfHistory,
        )
        setRoomsHealthData(res.aggregatedData)
      }
      fetchRoomsHealthScore(roomIDList)

      const fetchHighRiskLocations = async (roomids) => {
        const daysOfHistory = 5
        const res = await fetchContinuumService.fetchHighRiskLocations(
          roomids,
          DEFAULT_QUANTA_THRESHOLD,
          daysOfHistory,
        )
        // the api currently is returning 6 days with an argument of 5
        // we remove the oldest day if more than 5 days are returned.
        if (res.daysThresholdResults.length > 5) {
          res.daysThresholdResults.shift()
        }
        setHighRiskLocations(res)
      }
      fetchHighRiskLocations(roomIDList)

      const fetchRecentAverageRisk = async (roomids) => {
        const hoursOfHistory = 12
        const res = await fetchContinuumService.fetchRecentAverageRisk(
          roomids,
          DEFAULT_QUANTA_THRESHOLD,
          hoursOfHistory,
        )
        setRecentAverageRiskData(res)
      }
      fetchRecentAverageRisk(roomIDList)

      const fetchEnergyUsage = async (roomids) => {
        const daysOfHistory = 30
        const res = await fetchContinuumService.fetchEnergyUsage(roomids, daysOfHistory)
        setEnergyUsageData(res)
      }
      fetchEnergyUsage(roomIDList)
      const fetchRoomsNeedingsAttention = async (roomids) => {
        const res = await fetchContinuumService.fetchRoomsNeedingAttention(roomids)
        setRoomsNeedingAttention(res)
      }
      fetchRoomsNeedingsAttention(roomIDList)
    }
  }, [rooms, hasRequestBeenMade])
  useEffect(() => {
    if (roomHealthPercentage !== undefined) {
      setRoomHealthColor(
        chroma
          .scale([chroma('red').alpha(0.7).hex(), '#0093c8'])(roomHealthPercentage / 100)
          .hex(),
      )
    }
  }, [roomHealthPercentage])
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Typography variant='h4'>{SCHOOL.name}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            options={rooms.map((value) => {
              return {
                id: value.id,
                label: value.name,
              }
            })}
            onChange={(event: any, newValue) => {
              navigate(`/continuum/floors/?roomId=${newValue?.id}`)
            }}
            autoHighlight
            noOptionsText='No locations'
            renderInput={(params) => <TextField {...params} label='Find a location' />}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 175 }}
          >
            {!roomHealthPercentage ? (
              <CircularProgress size={50} />
            ) : (
              <HealthScoreCard
                roomHealthPercentage={roomHealthPercentage}
                roomHealthColor={roomHealthColor}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 175 }}
          >
            {!highRiskLocations ? (
              <CircularProgress size={50} />
            ) : (
              <HighRiskLocationCard locations={highRiskLocations} />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <MoldRiskCard riskLevel={moldRisk} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card sx={{ display: 'flex', height: 175 }}>
            <CardMedia
              component='img'
              sx={{ objectFit: 'contain', padding: 2 }}
              image={AccountLogo}
              alt='Account Logo'
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Box display='flex' justifyContent='start' alignItems='center'>
                <Typography variant='h5' component='div'>
                  Location Health
                </Typography>
                <IconButton size='medium' color='info' onClick={() => setIsDisclaimerModalOpen(true)}>
                  <Info/>
                </IconButton>
              </Box>
              <Typography variant='body2' color='text.secondary'>
                Every location is different - which is why each location can have its own risk
                threshold. Here are all of your locations scored against their relative risk
                thresholds.
              </Typography>
            </CardContent>
            <CardMedia
              sx={{ height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              {!roomsHealthData || !rooms ? (
                <CircularProgress size={75} />
              ) : (
                <PerLocationHealth
                  roomsHealthData={roomsHealthData}
                  rooms={rooms}
                  onClick={(event, elements, chart) => {
                    if (
                      elements &&
                      elements.length > 0 &&
                      elements[0].datasetIndex === 0 &&
                      roomsHealthData.length >= elements[0].index
                    ) {
                      console.log(
                        `should navigate to /continuum/floors?roomId=${
                          roomsHealthData?.[elements[0].index].room_id
                        }`,
                      )
                      navigate(
                        `/continuum/floors/?roomId=${roomsHealthData?.[elements[0].index].room_id}`,
                      )
                    }
                  }}
                  onHover={(event, chartElement) => {
                    event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
                  }}
                />
              )}
            </CardMedia>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <LocationsNeedingAttentionCard
            roomsNeedingAttention={roomsNeedingAttention}
            rooms={rooms}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <DynamicTreatmentEnergySavingsCard data={energyUsageData} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <RecentAverageRiskCard
            defaultQuantaThreshold={DEFAULT_QUANTA_THRESHOLD}
            recentAverageRiskData={recentAverageRiskData}
          />
        </Grid>
      </Grid>
      <Modal
        open={isDisclaimerModalOpen}
        onClose={() => {
          setIsDisclaimerModalOpen(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80vw',
          maxHeight: '70vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            How does Continuum calculate risk?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Typography variant='body1'>
              Quanta represents our estimate of how risky the room is.
            </Typography>
            <br/>
            <Typography variant='body1'>
              Different numbers of pathogens are required to infect people with various diseases. Tuberculosis can infect most people with a single bacterium , while Sars-Cov-2 requires ~10,000 virions to infect the average person . Moreover, different people will respond to pathogens in different ways, with some people being more susceptible and other people being more resistant to disease. In epidemiology, the common way to normalize these problems is to study pathogens in terms of “quanta.” One quanta (also called one “infectious dose”) is the amount of a pathogen that is required to infect 50% of people who inhale that much infectious material. By normalizing organisms in terms of quanta, it becomes possible to quickly estimate how likely an infection is to occur.
            </Typography>
            <br/>
            <Typography variant='body1'>
              Estimating risk of airborne infection in a room is a three-step process from an infector to a susceptible person.
            </Typography>
            <List>
              <ListItem>
                1. Pathogens leave the infector’s breath. What is the quanta emission rate?
              </ListItem>
              <ListItem>
                2. Pathogens travel through the air to a susceptible person or persons. How many pathogens reach the air that those people are about to breathe?
              </ListItem>
              <ListItem>
                3. The susceptible person inhales contaminated air. How much quanta do they inhale, and what is the likelihood of infection for that much infectious dose?
              </ListItem>
            </List>
            <br/>
            <Typography variant='body1'>
              Here’s how we calculate risk:
            </Typography>
            <List>
              <ListItem>
                I.	Determine the average breathing rate in the room by sensing trends in gases associated with human breath. This breathing rate is used for both estimating the quanta emission rate of a potential infector, and the inhalation rate of susceptible persons. This answers numbers 1 and 3 above. Our models use quanta information for Sars-Cov-2 as a default but can be adjusted to analyze other common airborne pathogens.
              </ListItem>
              <ListItem>
                II.	Determine the pathogen removal rate in the room. This is a combination of ventilation with fresh air, air cleaning devices, natural death of pathogens, and deposition of pathogens (settling out onto surfaces). As above, the default setting is to use natural death and deposition numbers from Sars-Cov-2, but other options are available.
              </ListItem>
              <ListItem>
                III.	Combining I and II above, we create an estimate of the total quanta concentration in the room in real time. Using our estimate of the average breathing rate, we can therefore estimate how many quanta a susceptible person is inhaling every minute. 
              </ListItem>
              <ListItem>
                IV.	Using the Wells-Riley epidemiological model, quanta inhalation can be correlated to the chance of an infection taking place . This model works forwards and backwards in our Continuum environment: Quanta levels in the room can be used to estimate the risk of infection, which is where our risk scores come from…
              </ListItem>
              <ListItem>
                V.	…but it is also possible to set a maximum allowable risk of infection, which is correlated to a “safe quanta level”. Continuum-enabled remediation devices will then run when needed to keep the estimated quanta levels below the “safe quanta level,” while turning off to save energy when they aren’t needed.
              </ListItem>
            </List>
          </Typography>
          <br />
          <Typography variant='body1'>
            <b>Disclaimer:</b> Epidemiology is a very complex field, with many confounding variables, and high levels of uncertainty about pathogen properties. Our estimates are not a guarantee of whether or not airborne transmission will occur. Instead, we offer an insightful tool that leverages state-of-the-art epidemiology models, pathogen properties, and our own novel developments in determining breathing rates to estimate the approximate riskiness of spaces and make recommendations about how occupant health can be improved. For those with an active Continuum subscription, our algorithms and pathogen properties are constantly being updated as new studies and methodologies become available.
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}
