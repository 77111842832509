import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React, { useContext } from 'react'
import { AppContext } from '../../app/App.context'

const DEFAULT_TABLE_ROWS = 12
const FIXED_DECIMAL_PRECISON = 2

export interface SimulationRunData {
  id: number
  quanta?: number
  occupants: number
  activityRate: number
  deviceCADR?: number
}

export interface SimulationResultsTableProps {
  results?: SimulationRunData[]
  description?: string
}

const SKELETON = (
  <>
    <Skeleton className='uva-skeleton uva-skeleton-table-header' variant='rectangular' />
    <Grid container spacing={2}>
      {Array(DEFAULT_TABLE_ROWS)
        .fill(0)
        .map(function (v, i) {
          return (
            <Grid key={i} item xs={3}>
              <Skeleton className='uva-skeleton' variant='rectangular' />
            </Grid>
          )
        })}
    </Grid>
  </>
)

// eslint-disable-next-line func-style
const SimulationResultsTable: React.FC<SimulationResultsTableProps> = ({
  results,
  description,
}) => {
  const appContext = useContext(AppContext)
  if (appContext.loading) {
    return SKELETON
  } else {
    if (results) {
      return (
        <TableContainer>
          <Table stickyHeader aria-label={description}>
            <TableHead>
              <TableRow key='headerRow'>
                <TableCell key='recIdCol' className='uva-numeric-label'>
                  Duration (Minutes)
                </TableCell>
                <TableCell key='quantaCol' className='uva-numeric-label'>
                  Quanta
                </TableCell>
                <TableCell key='occupantsCol' className='uva-numeric-label'>
                  Occupants
                </TableCell>
                <TableCell key='activityRateCol' className='uva-numeric-label'>
                  Activity Rate
                </TableCell>
                <TableCell key='uvaCol' className='uva-numeric-label'>
                  UVA Device CADR
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((row) => (
                <TableRow hover key={'row' + row.id} data-test-id={'row' + row.id}>
                  <TableCell key={'recIdCol' + row.id} className='uva-numeric-data'>
                    {row.id}
                  </TableCell>
                  <TableCell
                    key={'quantaCol' + row.id}
                    data-test-id={'quantaCol' + row.id}
                    className='uva-numeric-data'
                  >
                    {row.quanta !== undefined && row.quanta !== null
                      ? row.quanta.toFixed(FIXED_DECIMAL_PRECISON)
                      : 'null'}
                  </TableCell>
                  <TableCell
                    key={'occupantsCol' + row.id}
                    data-test-id={'occupantsCol' + row.id}
                    className='uva-numeric-data'
                  >
                    {row.occupants}
                  </TableCell>
                  <TableCell
                    key={'activityRateCol' + row.id}
                    data-test-id={'activityRateCol' + row.id}
                    className='uva-numeric-data'
                  >
                    {row.activityRate !== undefined && row.activityRate !== null
                      ? row.activityRate.toFixed(FIXED_DECIMAL_PRECISON)
                      : 'null'}
                  </TableCell>
                  <TableCell
                    key={'uvaCol' + row.id}
                    data-test-id={'uvaCol' + row.id}
                    className='uva-numeric-data'
                  >
                    {row.deviceCADR !== undefined && row.deviceCADR !== null
                      ? row.deviceCADR.toFixed(FIXED_DECIMAL_PRECISON)
                      : 'null'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    } else {
      return <span></span>
    }
  }
}

export default React.memo(SimulationResultsTable)
