import { CssBaseline } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import AuthClient from '../../services/auth.service'
import SignInPage from '../sign-in/sign-in-page.component'
import AppFooter from './components/footer/app-footer.component'
import AppHeader from './components/header/app-header.component'
import ContinuumHeader from './components/header/continuum-header.component'

export interface AppShellProps {
  token?: string,
  toggleTheme: () => void,
  useContinuumBranding?: boolean
}

// eslint-disable-next-line func-style
const AppShell: React.FC<AppShellProps> = ({token, toggleTheme, useContinuumBranding}) => {
  return (
    <div className='app-shell' id='UvaAppShell'>
      <CssBaseline />
      {useContinuumBranding ? <ContinuumHeader token={token} toggleTheme={toggleTheme} /> : <AppHeader token={token} toggleTheme={toggleTheme} /> }
      <main style={{paddingTop: '2rem'}} >
        { token === undefined || token === '' ? 
          <SignInPage handleLogin={AuthClient.handleLogin} /> :  
          <Outlet />
        }
        <AppFooter />
      </main>
    </div>
  )
}

export default AppShell
