import { LoadingButton } from '@mui/lab'
import {
  Alert, Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { AppContext } from '../../../app/App.context'
import { PathogenPicker } from '../../../components/pathogen-picker/pathogen-picker.component'
import { RoomPicker } from '../../../components/room-picker/room-picker.component'
import { UvaPssqTrack } from '../../../services/api.models'
import PssqClient from '../../../services/pssq.service'

export interface TrackDialogProps {
  title?: string
  onClose: () => void
  onSave: (track: UvaPssqTrack) => void
}

// eslint-disable-next-line func-style
export const TrackDialog: React.FC<TrackDialogProps> = (props) => {
  const appContext = React.useContext(AppContext)
  const [state, setState] = React.useState<{
    nickname: string
    roomId: string
    pathogenId: string
    activityId: string
    calculationInterval: number | null
    infectionRate: string
    error: string
    isLoading: boolean
  }>({
    nickname: '',
    roomId: '',
    pathogenId: '',
    activityId: '',
    calculationInterval: null,
    infectionRate: '',
    error: '',
    isLoading: false,
  })
  const errorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // scroll to the error message at the bottom of the form each time it changes
    if (state.error) {
      errorRef.current?.scrollIntoView()
    }
  }, [state.error])

  function handlePositiveNumberChange(value: string, field: string, name: string) {
    const numValue = Number(value)

    if (Number.isNaN(numValue) || numValue < 0) {
      setState({
        ...state,
        error: `${name} must be a positive number`,
      })
      return
    }

    setState({
      ...state,
      [field]: value,
      error: '',
    })
  }

  function handleInfectionRateChange(value: string) {
    const ir = +value

    if (Number.isNaN(ir) || ir < 0 || ir > 100) {
      setState({
        ...state,
        error: 'Infection rate must be between 0 and 100',
      })
      return
    }

    setState({
      ...state,
      infectionRate: value,
      error: '',
    })
  }

  function handleSave() {
    if (state.isLoading) {
      return
    }

    if (!state.roomId) {
      setState({ ...state, error: 'Select room' })
      return
    }

    if (!state.pathogenId) {
      setState({ ...state, error: 'Select pathogen' })
      return
    }

    if (!state.activityId) {
      setState({ ...state, error: 'Select activity' })
      return
    }

    if (!state.infectionRate) {
      setState({ ...state, error: 'Provide value for infection rate' })
      return
    }

    if (state.calculationInterval === null) {
      setState({ ...state, error: 'Select calculation interval' })
      return
    }

    const ir = +state.infectionRate
    if (Number.isNaN(ir) || ir < 0 || ir > 100) {
      setState({
        ...state,
        error: 'Infection rate must be between 0 and 100',
      })
      return
    }

    setState({ ...state, error: '', isLoading: true })

    const newTrack = {
      nickname: state.nickname,
      room_id: state.roomId,
      pathogen_id: state.pathogenId,
      activity_id: state.activityId,
      infection_rate: ir / 100,
      interval_seconds: state.calculationInterval,
    }

    PssqClient.insertTrack(newTrack)
      .then((track) => props.onSave(track))
      .catch((error) => {
        console.error('Error saving track', newTrack)
        setState({ ...state, error: error.message || 'Error saving track', isLoading: false })
      })
  }

  return (
    <Dialog open={true} onClose={() => props.onClose()} id='UvaTrackDialog'>
      <DialogTitle>{props.title || 'New PSSQ Track'}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth className='uva-form-control'>
          <TextField
            label='Nickname'
            className='uva-form-control'
            variant='filled'
            value={state.nickname}
            onChange={(event) => setState({ ...state, nickname: event.target.value })}
          />
        </FormControl>
        <FormControl variant='filled' fullWidth className='uva-form-control'>
          <RoomPicker
            rooms={appContext.rooms}
            value={state.roomId}
            onChange={(roomId) => setState({ ...state, roomId })}
            disabled={state.isLoading}
          />
        </FormControl>
        <FormControl variant='filled' className='uva-form-control' fullWidth>
          <PathogenPicker
            value={state.pathogenId}
            disabled={state.isLoading}
            onChange={(value) => setState({ ...state, pathogenId: value })}
          />
        </FormControl>
        <FormControl variant='filled' fullWidth className='uva-form-control'>
          <InputLabel id='activity-label'>Activity</InputLabel>
          <Select
            id='activity-filter'
            labelId='activity-label'
            label='Activity'
            onChange={(event) => setState({ ...state, activityId: event.target.value })}
            className='uva-form-control'
            disabled={state.isLoading}
            value={state.activityId}
          >
            {appContext.activities.map((activity) => (
              <MenuItem value={activity.id} key={`Activity${activity.id}`}>
                {activity.primary} - {activity.secondary}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth className='uva-form-control'>
          <TextField
            label='Infection Rate'
            className='uva-form-control'
            variant='filled'
            value={state.infectionRate}
            disabled={state.isLoading}
            onChange={(event) => setState({ ...state, infectionRate: event.target.value })}
            onBlur={(event) => handleInfectionRateChange(event.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            }}
          />
        </FormControl>

        <FormControl variant='filled' fullWidth className='uva-form-control'>
          <InputLabel id='interval-label'>Calculation Interval</InputLabel>
          <Select
            variant='filled'
            labelId='interval-label'
            value={state.calculationInterval ?? ''}
            id='interval-picker'
            disabled={state.isLoading}
            onChange={(event: SelectChangeEvent<number>) =>
              setState({ ...state, calculationInterval: +event.target.value })
            }
          >
            <MenuItem value={1 * 60} key='Interval1'>
              1 minute
            </MenuItem>
            {/* eslint-disable-next-line no-magic-numbers */}
            <MenuItem value={3 * 60} key='Interval2'>
              3 minutes
            </MenuItem>
            <MenuItem value={5 * 60} key='Interval3'>
              5 minutes
            </MenuItem>
            <MenuItem value={10 * 60} key='Interval4'>
              10 minutes
            </MenuItem>
            <MenuItem value={15 * 60} key='Interval5'>
              15 minutes
            </MenuItem>
            <MenuItem value={30 * 60} key='Interval6'>
              30 minutes
            </MenuItem>
          </Select>
        </FormControl>

        {state.error && (
          <Alert severity='error' ref={errorRef}>
            {state.error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='secondary' onClick={() => props.onClose()}>
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSave}
          loading={state.isLoading}
          variant='contained'
          type='submit'
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
