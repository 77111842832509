/* eslint-disable no-magic-numbers */
import React from 'react'

import { Box, Card, Typography, CardMedia, CardContent, CircularProgress } from '@mui/material'
import { Line } from 'react-chartjs-2'

function LineChartContainer({
  data,
  defaultQuantaThreshold,
}: {
  data: any
  defaultQuantaThreshold: number
}) {
  const labels = data.map((dataPoint) => {
    const timestamp = dataPoint.hour
    return new Date(timestamp).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
  })

  const averageQuantaMeasurement = data.map((dataPoint) => dataPoint.avgPssq)
  const averageQuantaThreshold = data.map(() => defaultQuantaThreshold)
  const pointRadiusList = Array(data.length).fill(0)
  pointRadiusList[pointRadiusList.length - 1] = 10
  return (
    <Line
      data={{
        labels: labels,
        datasets: [
          {
            data: averageQuantaMeasurement,
            borderColor: '#0093c8',
            fill: {
              target: '+1',
              above: 'rgb(0, 147, 199, 0.2)',
              below: 'transparent',
            },
            pointStyle: 'circle',
            pointRadius: pointRadiusList,
            pointHoverRadius: 15,
          },
          {
            data: averageQuantaThreshold,
            borderColor: 'red',
          },
        ],
      }}
      options={{
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        plugins: { legend: { display: false } },
        elements: {
          line: {
            tension: 0.3,
          },
          point: {
            radius: 0,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
      }}
    />
  )
}

export default function RecentAverageRiskCard({
  recentAverageRiskData,
  defaultQuantaThreshold,
}: any) {
  return (
    <Card
      sx={{
        height: '100%',
      }}
    >
      <CardContent>
        <Typography variant='h5'>Recent Average Risk</Typography>
        <Typography variant='body2' color='text.secondary'>
          Your environments average risk compared to your configured thresholds
        </Typography>
      </CardContent>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        minHeight={300}
      >
        {recentAverageRiskData ? (
          <CardMedia
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 300,
              width: '100%',
              padding: 1,
            }}
          >
            <LineChartContainer
              data={recentAverageRiskData.hourlyAvgRecentRisk}
              defaultQuantaThreshold={defaultQuantaThreshold}
            />
          </CardMedia>
        ) : (
          <CircularProgress size={75} />
        )}
      </Box>
    </Card>
  )
}
