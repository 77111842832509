/* eslint-disable no-magic-numbers */
import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Skeleton,
} from '@mui/material'
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { MongoChart } from '../../components/mongo-chart/mongo-chart.component'
import { EnvironmentalMetrics, UvaRoom } from '../../services/api.models'
import AuthClient from '../../services/auth.service'
import { MULTI_DEVICE_CHARTS } from '../env-charts/env-charts-page.component'
import AlertsHistoryTable from './components/alerts-history-table/alerts-history-table.component'
import { FloorMap } from './components/floor-map/floor-map.component'
import { FloorSwitcher } from './components/floor-switcher/floor-switcher.component'
import LocationStatsTable from './components/location-stats-table/location-stats-table.component'
import { buildRoomMap, RoomSelector } from './components/room-selector/room-selector.component'
import { FLOOR1, FLOOR1_NEW, FLOOR2, HOSPITAL, SCHOOL, SCHOOL_FLOOR } from './room-mocks'
import getRoomColorConfigData from './utils/color-config-utils'

import type { HierarchicalRoomWithGeoDataType } from './room-mocks'
import type { HierarchicalRoomType } from './components/room-selector/room-selector.component'
import { useParams, useSearchParams } from 'react-router-dom'

const MONGO_CHART_BASE_URL = 'https://charts.mongodb.com/charts-uvangel-web-oqmaj'

const pollingIntervalMinutes = 1

let endDateIso8601 = new Date()
let startDateIso8601 = subtractTimeFromDate(endDateIso8601, 48)

const COLOR_SCHEMA = {
  minColor: [0x46, 0x71, 0x8a] as const,
  maxColor: [0xff, 0x00, 0x00] as const,
}

export enum AvailableMetricEnum {
  PSSQ = 'PSSQ',
  RISK = 'Risk',
  MOLD = 'Mold',
  TEMP = 'Temperature',
  CO2 = 'CO2',
  HUMIDITY = 'Humidity',
  CADR = 'Ventilation',
  IAQ = 'Air Quality',
  VOC = 'VOC',
  PRESSURE = 'Pressure',
}

function isFloor(room: HierarchicalRoomType) {
  return !!room?.rooms?.length
}

/**
 Type* Find a floor on which this room is located.
 * If the floor is not found then the room itself is returned
 */
function findFloor(
  room: HierarchicalRoomType,
  roomMap: Record<string, HierarchicalRoomWithGeoDataType>,
) {
  if (isFloor(room)) {
    return room
  }
  const floor = Object.values(roomMap)
    .filter((room) => isFloor(room))
    .find((floor) => floor.id === room.id || floor.rooms.some((r) => r.id === room.id))
  if (floor) {
    return floor
  } else {
    return room
  }
}

function subtractTimeFromDate(objDate, intHours) {
  const numberOfMlSeconds = objDate.getTime()
  const addMlSeconds = intHours * 60 * 60 * 1000
  const newDateObj = new Date(numberOfMlSeconds - addMlSeconds)
  return newDateObj
}

/**
 * Convert a value that is minValue <= value <= maxValue to a
 * color that is between minColor and maxColor.
 *
 * It uses simple interpolation. Returned color is an array of [red, green, blue] integer values.
 *
 * @param value value to convert to a color
 * @param minValue what is the minimal value on the scale
 * @param maxValue what is the maximal value on the scale
 * @param colorScheme colors that represent min/max values in rrggbb format
 */
function value2color(
  value: number,
  minValue: number,
  maxValue: number,
  colorScheme: {
    minColor: readonly [number, number, number]
    maxColor: readonly [number, number, number]
  } = COLOR_SCHEMA,
) {
  if (value <= minValue) {
    return colorScheme.minColor
  }
  if (value >= maxValue) {
    return colorScheme.maxColor
  }

  const valueSpread = maxValue - minValue
  const [rSpread, gSpread, bSpread] = [
    colorScheme.maxColor[0] - colorScheme.minColor[0],
    colorScheme.maxColor[1] - colorScheme.minColor[1],
    colorScheme.maxColor[2] - colorScheme.minColor[2],
  ]

  return [
    Math.floor(colorScheme.minColor[0] + (value / valueSpread) * rSpread),
    Math.floor(colorScheme.minColor[1] + (value / valueSpread) * gSpread),
    Math.floor(colorScheme.minColor[2] + (value / valueSpread) * bSpread),
  ] as const
}

// eslint-disable-next-line func-style
const MetricCharts = ({
  charts,
  roomId,
}: {
  charts: { id: string; name: string }[]
  roomId: string
}) => (
  <>
    {charts.map((chart) => (
      <Grid item key={chart.id} xs={12} md={6} lg={4}>
        <Paper>
          {chart.id === '561a45f9-bfab-41ff-88f8-de6bf657305d' ? (
            <MongoChart
              baseUrl={MONGO_CHART_BASE_URL}
              chartId={chart.id}
              enableDownload={true}
              chartName='Metric Chart'
              filter={{
                room_id: {
                  $in: roomId.split(',').map((id) => {
                    return { $oid: id }
                  }),
                },
                $and: [
                  {
                    timestamp: { $gte: startDateIso8601 },
                  },
                  {
                    timestamp: { $lte: endDateIso8601 },
                  },
                ],
              }}
              height='250px'
              key={chart.id}
            />
          ) : (
            <MongoChart
              baseUrl={MONGO_CHART_BASE_URL}
              chartId={chart.id}
              enableDownload={true}
              chartName='Metric Chart'
              filter={{
                'metadata.room_ids': {
                  $in: roomId.split(',').map((id) => {
                    return { $oid: id }
                  }),
                },
                $and: [
                  {
                    timestamp: { $gte: startDateIso8601 },
                  },
                  {
                    timestamp: { $lte: endDateIso8601 },
                  },
                ],
              }}
              height='250px'
              key={chart.id}
            />
          )}
        </Paper>
      </Grid>
    ))}
  </>
)

function transformEnvironmentalMetricsResponse(environmentalMetrics: any): EnvironmentalMetrics {
  const newMetrics: EnvironmentalMetrics = {}
  if (environmentalMetrics.volume_avg_rms) {
    newMetrics.ventilationRate = {
      mean: parseFloat(environmentalMetrics.volume_avg_rms.mean),
      trend: environmentalMetrics.volume_avg_rms.trend,
    }
  }
  if (environmentalMetrics.scd_co2) {
    newMetrics.co2 = {
      mean: parseFloat(environmentalMetrics.scd_co2.mean),
      trend: environmentalMetrics.scd_co2.trend,
    }
  }
  if (environmentalMetrics.bsec_heat_comp_temp) {
    newMetrics.temp = {
      mean: parseFloat(environmentalMetrics.bsec_heat_comp_temp.mean),
      trend: environmentalMetrics.bsec_heat_comp_temp.trend,
    }
  }
  if (environmentalMetrics.scd_hum) {
    newMetrics.humidity = {
      mean: parseFloat(environmentalMetrics.scd_hum.mean),
      trend: environmentalMetrics.scd_hum.trend,
    }
  }
  if (environmentalMetrics.bsec_br_voc_eq) {
    newMetrics.voc = {
      mean: parseFloat(environmentalMetrics.bsec_br_voc_eq.mean),
      trend: environmentalMetrics.bsec_br_voc_eq.trend,
    }
  }
  if (environmentalMetrics.bsec_out_iaq) {
    newMetrics.dummy = {
      mean: parseFloat(environmentalMetrics.bsec_out_iaq.mean),
      trend: environmentalMetrics.bsec_out_iaq.trend,
    }
  }
  if (environmentalMetrics.bsec_raw_pres) {
    newMetrics.pressure = {
      // raw values are in Pa, convert to hPA
      mean: parseFloat(environmentalMetrics.bsec_raw_pres.mean) / 100,
      trend: environmentalMetrics.bsec_raw_pres.trend,
    }
  }
  if (environmentalMetrics.bsec_out_iaq) {
    newMetrics.iaq = {
      mean: parseFloat(environmentalMetrics.bsec_out_iaq.mean),
      trend: environmentalMetrics.bsec_out_iaq.trend,
    }
  }
  if (environmentalMetrics.pssq) {
    newMetrics.pssq = {
      mean: parseFloat(environmentalMetrics.pssq.mean),
      trend: environmentalMetrics.pssq.trend,
    }
  }
  return newMetrics
}

async function getEnvironmentalMetricsForRoom(
  roomIds: string[],
  aggregateRoomsData: boolean,
  abortSignal?: AbortSignal,
): Promise<{ [roomId: string]: EnvironmentalMetrics } | null> {
  const path = `/api/rooms/${roomIds}/environmental-metrics/?includePssqHistory=true&aggregateRoomsData=${aggregateRoomsData}`
  try {
    const response = await fetch(path, {
      method: 'GET',
      signal: abortSignal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${AuthClient.getAccessToken()}`,
      },
    })

    if (response.ok) {
      const result = await response.json()
      if (result.environmentalMetrics) {
        const resultMap = {}
        for (const roomId of Object.keys(result.environmentalMetrics)) {
          resultMap[roomId] = transformEnvironmentalMetricsResponse(
            result.environmentalMetrics[roomId],
          )
        }
        return resultMap
      }
    } else {
      throw response
    }
  } catch (error: unknown) {
    type errorType = {
      name: string
    }
    const errorRes = error as errorType
    if (errorRes.name !== 'AbortError') {
      console.error('Error fetching environmental metrics for rooms ', roomIds, error)
    }
  }
  return null
}

/**
 * Tries to decode well formed PNG encoded in base64 format and return image dimensions
 */
function getPngDimensions(rawBase64Data?: string) {
  const dataUriDescriptor = 'data:image/png;base64,'
  // PNG has 16B at the start of file and then there is image width(4B) and height(4B)
  const pngGarbage = 16
  if (!rawBase64Data?.startsWith(dataUriDescriptor)) {
    console.error('Floor plan is NOT a png!')
    return { width: 0, height: 0 }
  }
  // take big enough part of base64 data
  const base64Part = rawBase64Data.slice(dataUriDescriptor.length, dataUriDescriptor.length + 50)
  // convert it to a normal string
  const binaryPart = window.atob(base64Part)
  // convert it to a binary array
  const uint8 = Uint8Array.from(binaryPart, (c) => c.charCodeAt(0))
  const dataView = new DataView(uint8.buffer)

  return {
    width: dataView.getInt32(pngGarbage),
    height: dataView.getInt32(pngGarbage + 4),
  }
}

function formatMetricValue(value: number, selectedMetric: keyof typeof AvailableMetricEnum) {
  if (value === null || value === undefined) {
    return '---'
  }

  switch (selectedMetric) {
    case 'TEMP':
      return value.toFixed(1) + ' °F'
    case 'CO2':
      return value.toFixed(1) + ' ppm'
    case 'HUMIDITY':
      return value.toFixed(1) + ' %'
    case 'CADR':
      return value.toFixed(1) + ' cfm'
    case 'PSSQ':
      return value.toFixed(4) + ' quanta/cm³'
    case 'RISK':
      return value.toFixed(1) + ' %'
    case 'MOLD': {
      if (value <= 80) {
        return 'Low'
      }
      if (value < 120) {
        return 'Moderate'
      }
      return 'High'
    }
    case 'IAQ':
      return value.toFixed(1) + ' iaq'
    case 'VOC':
      return value.toFixed(1) + ' ppm'
    case 'PRESSURE':
      return value.toFixed(1) + ' hPa'
    default:
      return '---'
  }
}

// hack this with percent shift so that it looks right given uncalibrated sensor
function convertFromCToF(degreesC: number, shiftValue = 0) {
  return (degreesC * 9) / 5 + 32 - shiftValue
}

function transformEnvrionmentalMetricsMap2RoomValues(
  environmentalMetricsMap: Record<string, EnvironmentalMetrics | null> | null,
  selectedMetric: keyof typeof AvailableMetricEnum,
) {
  const newRoomValues = {}
  for (const key in environmentalMetricsMap) {
    switch (selectedMetric) {
      case 'TEMP':
        if (environmentalMetricsMap[key]?.temp) {
          newRoomValues[key] = convertFromCToF(environmentalMetricsMap[key]?.temp?.mean || 0)
        } else {
          newRoomValues[key] = environmentalMetricsMap[key]?.temp
        }
        break
      case 'CO2':
        newRoomValues[key] = environmentalMetricsMap[key]?.co2?.mean
        break
      case 'HUMIDITY':
        newRoomValues[key] = environmentalMetricsMap[key]?.humidity?.mean
        break
      case 'CADR':
        if (environmentalMetricsMap[key]?.ventilationRate) {
          newRoomValues[key] = environmentalMetricsMap[key]?.ventilationRate?.mean || 0 * 15
        } else {
          newRoomValues[key] = environmentalMetricsMap[key]?.ventilationRate
        }
        break
      case 'PSSQ':
        if (environmentalMetricsMap[key]?.pssq) {
          newRoomValues[key] = environmentalMetricsMap[key]?.pssq?.mean || 0 * 100
        } else {
          newRoomValues[key] = environmentalMetricsMap[key]?.pssq
        }
        break
      case 'RISK':
        newRoomValues[key] = environmentalMetricsMap[key]?.dummy?.mean
        break
      case 'MOLD':
        newRoomValues[key] = environmentalMetricsMap[key]?.dummy?.mean
        break
      case 'IAQ':
        newRoomValues[key] = environmentalMetricsMap[key]?.iaq?.mean
        break
      case 'VOC':
        newRoomValues[key] = environmentalMetricsMap[key]?.voc?.mean
        break
      case 'PRESSURE':
        newRoomValues[key] = environmentalMetricsMap[key]?.pressure?.mean
        break
      default:
        console.log('unknown selected metric')
        newRoomValues[key] = 0
    }
  }
  return newRoomValues
}

export interface MonitoringPageProps {
  mapToUse: 'SCHOOL' | 'HOSPITAL'
  rooms: UvaRoom[]
}

// eslint-disable-next-line func-style
export const MonitoringPage: React.FC<PropsWithChildren<MonitoringPageProps>> = ({
  mapToUse,
  rooms,
}) => {
  // Change between SCHOOL/HOSPITAL to see different maps and floor plans
  //    Default to hospital
  let mapInUse = HOSPITAL
  let ROOM_MAP: Record<string, HierarchicalRoomWithGeoDataType> = buildRoomMap([HOSPITAL])

  if (mapToUse === 'SCHOOL') {
    mapInUse = SCHOOL
    ROOM_MAP = buildRoomMap([SCHOOL])
  }

  const envMetricPickerRef = useRef(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedRoom, setSelectedRoom] = useState<HierarchicalRoomType>(mapInUse.rooms[0])
  const [roomValues, setRoomValues] = useState<Record<string, number>>({})
  const [environmentalMetricsMap, setEnvironmentalMetricsMap] = useState<{
    [key: string]: EnvironmentalMetrics | null
  } | null>(null)
  const [selectedMetric, setSelectedMetric] = useState<keyof typeof AvailableMetricEnum>('IAQ')

  useEffect(() => {
    const roomId = searchParams.get('roomId')
    if (roomId && ROOM_MAP[roomId]) {
      // console.log('initializing room from path', roomId)
      setSelectedRoom(ROOM_MAP[roomId])
    }
  }, [])

  useEffect(() => {
    console.log('updating query param in URL', selectedRoom.id)
    if (!isFloor(selectedRoom)) {
      setSearchParams(`?roomId=${selectedRoom.id}`, { replace: true })
    }
  }, [selectedRoom, setSearchParams])

  // on polling interval, refresh the data all locations
  useEffect(() => {
    const abortController = new AbortController()
    async function updateTick() {
      console.log('updating time range for charts')
      endDateIso8601 = new Date()
      startDateIso8601 = subtractTimeFromDate(endDateIso8601, 48)

      console.log('REFRESHING Live Data for all rooms')

      const roomIdsArray: string[] = Object.keys(ROOM_MAP)

      const individualRooms: string[] = []
      const aggregateRooms: string[][] = [] // floors
      for (const r of roomIdsArray) {
        if (r.includes(',')) {
          aggregateRooms.push(r.split(','))
        } else {
          individualRooms.push(r)
        }
      }

      //console.log('aggregateRooms', aggregateRooms)
      //console.log('individualRooms', individualRooms)
      const individualRoomsEnvData = await getEnvironmentalMetricsForRoom(
        individualRooms,
        false,
        abortController.signal,
      ) // individualRoomsEnvData object key = mongodb id (ex: 63ed52bdc8ce7522a5e516d3)
      if (!individualRoomsEnvData) {
        return
      }
      const envMetricsMap: typeof environmentalMetricsMap = {
        ...individualRoomsEnvData,
      }
      for (const aggregateRoomIds of aggregateRooms) {
        const aggregateRoomEnvData = await getEnvironmentalMetricsForRoom(
          aggregateRoomIds,
          true,
          abortController.signal,
        ) // individualRoomsEnvData object key = mongodb id (ex: 63ed52bdc8ce7522a5e516d3)
        if (!aggregateRoomEnvData) {
          console.error('error processing aggregate data', aggregateRoomEnvData)
          return
        }
        const floorId = JSON.stringify(aggregateRoomIds.join(','))
        envMetricsMap[floorId] = aggregateRoomEnvData[floorId]
      }
      setEnvironmentalMetricsMap(envMetricsMap)
    }

    updateTick()
    const interval = setInterval(updateTick, pollingIntervalMinutes * 60 * 1000)
    return () => {
      clearInterval(interval)
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    setRoomValues(
      transformEnvrionmentalMetricsMap2RoomValues(environmentalMetricsMap, selectedMetric),
    )
  }, [selectedMetric, environmentalMetricsMap])

  function selectMetric(metric: keyof typeof AvailableMetricEnum) {
    setSelectedMetric(metric)
  }

  const currentFloor = findFloor(selectedRoom, ROOM_MAP) as HierarchicalRoomWithGeoDataType
  const currentFloorPlanDimensions = getPngDimensions(currentFloor.floorPlan)
  // Do not select floors (gives them yellow border), only single rooms
  const selectedRoomIds = useMemo(() => selectedRoom?.rooms?.length ? [] : [selectedRoom.id], [selectedRoom])

  // Put current floor and all it's subrooms into roomFeatures
  const roomFeatures = useMemo(
    () =>
      [currentFloor, ...currentFloor.rooms].map((room) => {
        // returns object with 'fill-color'and optional 'fill-outline-color' fields
        const roomColorConfigData = getRoomColorConfigData(
          room,
          roomValues[room.id],
          selectedMetric,
        )
        const formattedValue = formatMetricValue(roomValues[room.id], selectedMetric)
        return {
          ...(room as any as HierarchicalRoomWithGeoDataType).geojson,
          // roomId is expected by FloorPlan Typebut will not be stored in geojson data to avoid data duplication
          properties: {
            roomId: room.id,
            roomName: room.name,
            value: formattedValue,
            // floors will not print their name & value
            hideValue: isFloor(room),
            hasSubrooms: isFloor(room),
            // this line configures floor map to show the selected room with transparent fill color
            'selected-fill-color': 'rgba(255, 255, 255, 0)',
            'selected-fill-outline-width': 6,
            'selected-fill-outline-color': 'yellow',
            ...roomColorConfigData,
          },
        }
      }),
    [currentFloor, selectedMetric, roomValues],
  )

  return (
    <div id='MonitoringPage'>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} xl={3}>
          <Card className='uva-params-panel'>
            <RoomSelector
              rooms={mapInUse.rooms}
              onSelectRoom={(room) => setSelectedRoom(room)}
              selectedRoomId={selectedRoom?.id}
            />
          </Card>

          <Card className='uva-params-panel'>
            <LocationStatsTable
              environmentalMetrics={
                environmentalMetricsMap && environmentalMetricsMap[selectedRoom.id]
              }
              selectedRoom={selectedRoom}
            />
          </Card>
          {/* Removed for Continuum demo */}
          {/* <Card className='uva-params-panel'>
            <AlertsHistoryTable numberOfAlerts={4} selectedRoom={selectedRoom} />
          </Card> */}
        </Grid>
        <Grid item xs={12} md={8} xl={9} sx={{ position: 'relative' }}>
          {/* <MonitoringAlert environmentalMetricsMap={environmentalMetricsMap} room={selectedRoom} /> */}
          {!environmentalMetricsMap ? (
            <Card>
              <Skeleton className='uva-skeleton-card' variant='rectangular' />
            </Card>
          ) : (
            <Card id='UVAMapboxContainer'>
              <FloorMap
                // TODO: we should have a placeholder image when there is no floor plan attached to a floor
                floorPlanUrl={currentFloor.floorPlan ?? ''}
                floorPlanWidth={currentFloorPlanDimensions.width}
                floorPlanHeight={currentFloorPlanDimensions.height}
                roomFeatures={roomFeatures}
                selectedRoomIds={selectedRoomIds}
                onSelectRoom={(roomId) => setSelectedRoom(ROOM_MAP[roomId])}
                floorSwitcher={
                  mapInUse.rooms.length > 1 ? (
                    <Paper id='FloorSwitcherWrapper'>
                      <FloorSwitcher
                        floors={mapInUse.rooms.map((f, idx) => ({
                          id: f.id,
                          name: f.name,
                          ordinal: idx,
                        }))}
                        showFloorName={true}
                        selectedFloorId={findFloor(selectedRoom, ROOM_MAP).id}
                        onSelectFloor={(floorId) => setSelectedRoom(ROOM_MAP[floorId])}
                      />
                    </Paper>
                  ) : undefined
                }
              >
                <Paper id='UVAEnvironmentalMetricSelectorContainer'>
                  <FormControl variant='filled' id='UVAEnvironmentalMetricSelector'>
                    <InputLabel id='device-label'>Environmental Metric</InputLabel>
                    <Select<string>
                      variant='filled'
                      labelId='metric-label'
                      label='Environmental Metric'
                      id='env-metric-picker'
                      // ref={envMetricPickerRef}
                      // set select's menu container to self otherwise the menu would be obscured by floor map in fullscreen mode
                      MenuProps={{
                        container: envMetricPickerRef.current,
                      }}
                      value={selectedMetric}
                      onChange={(evt: SelectChangeEvent<string>) =>
                        selectMetric(evt.target.value as keyof typeof AvailableMetricEnum)
                      }
                    >
                      {Object.keys(AvailableMetricEnum).map((metric) => (
                        <MenuItem value={metric} key={'EnvMetric' + metric}>
                          {AvailableMetricEnum[metric]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Paper>
              </FloorMap>
            </Card>
          )}
          <Grid container spacing={2} sx={{ marginTop: 0.5 }}>
            <MetricCharts charts={MULTI_DEVICE_CHARTS} roomId={selectedRoom.id}></MetricCharts>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
