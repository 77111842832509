/* eslint-disable no-magic-numbers */
import { DEFAULT_QUANTA_THRESHOLD } from '../pages/monitoring/monitoring-overview-page.component'
import AuthClient from './auth.service'

export interface HealthScoreResponseType {
  calculatedTime: string
  healthScore: number
}

export interface RoomsHealthScoreResponseType {
  calculatedTime: string
  aggregatedData: Array<{ room_id: string; percentOfQuantaThreshold: string }>
}

export interface HighRiskLocationsResponseType {
  calculatedTime: string
  daysThresholdResults: Array<{ room_id: string; aboveThresholdCount: number }>
}

export interface RecentAverageRiskResponseType {
  calculatedTime: string
  hourlyAvgRecentRisk: Array<any>
}

export interface MoldRiskResponseType {
  calculatedTime: string
  moldRiskPercentage: string
}

export interface EnergyUsageResponseType {
  calculatedTime: string
  dailyCostData: any
}

type AlertSubType = {
  alert: boolean
  value: number
}

type AlertsType = {
  highCO2: AlertSubType
  highHumidity: AlertSubType
  highIAQ: AlertSubType
  quanta: AlertSubType
  temp: AlertSubType
}

export type RoomsNeedingAttentionResponseType = Array<{ roomId: string; alerts: AlertsType }>

export interface FetchContinuumService {
  fetchHealthScore(
    roomids: string[],
    avgQuantaThresholdScore: number,
    minutesDuration: number,
  ): Promise<HealthScoreResponseType>
  fetchRoomsHealthScore(
    roomids: string[],
    avgQuantaThresholdScore: number,
    hoursOfHistory: number,
  ): Promise<RoomsHealthScoreResponseType>
  fetchHighRiskLocations(
    roomids: string[],
    avgQuantaThresholdScore: number,
    daysOfHistory: number,
  ): Promise<HighRiskLocationsResponseType>
  fetchRecentAverageRisk(
    roomids: string[],
    avgQuantaThresholdScore: number,
    hoursOfHistory: number,
  ): Promise<RecentAverageRiskResponseType>
  fetchMoldRisk(roomids: string[], daysOfHistory: number): Promise<MoldRiskResponseType>
  fetchEnergyUsage(roomids: string[], daysOfHistory: number): Promise<EnergyUsageResponseType>
  fetchRoomsNeedingAttention(roomids: string[]): Promise<RoomsNeedingAttentionResponseType>
}

const fetchWithParams = async (url, params) => {
  const headers = new Headers()
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json;charset=UTF-8')
  headers.append('Authorization', `Bearer ${AuthClient.getAccessToken()}`)
  const urlWithParams = url.concat(params.toString())
  const response = await fetch(urlWithParams, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
  if (!response.ok) {
    const urlParts = url.split('/')
    const endpoint = urlParts[urlParts.length - 2]
    throw new Error(`HTTP error! | ${endpoint} | status: ${response.status}`)
  }
  return response.json()
}

const fetchRecentAverageRisk = async (roomIds, avgQThresholdScore, hoursOfHistory) => {
  const url = '/api/lab-continuum/recent-avg-risk/?'
  const params = new URLSearchParams({
    roomIds: roomIds.join(','),
    avgQThresholdScore: avgQThresholdScore.toString(),
    hoursOfHistory: hoursOfHistory.toString(),
  })
  return fetchWithParams(url, params)
}

const fetchHealthScore = async (roomIds, avgQThresholdScore, minutesDuration) => {
  const url = '/api/lab-continuum/health-score/?'
  const params = new URLSearchParams({
    roomIds: roomIds.join(','),
    avgQThresholdScore: avgQThresholdScore.toString(),
    minutesDuration: minutesDuration.toString(),
  })
  return fetchWithParams(url, params)
}

const fetchRoomsHealthScore = async (roomIds, avgQThresholdScore, hoursOfHistory) => {
  const url = '/api/lab-continuum/rooms-health-score/?'
  const params = new URLSearchParams({
    roomIds: roomIds.join(','),
    avgQThresholdScore: avgQThresholdScore.toString(),
    hoursOfHistory: hoursOfHistory.toString(),
  })
  return fetchWithParams(url, params)
}

const fetchHighRiskLocations = async (roomIds, avgQThresholdScore, daysOfHistory) => {
  const url = '/api/lab-continuum/high-risk-locations/?'
  const params = new URLSearchParams({
    roomIds: roomIds.join(','),
    avgQThresholdScore: avgQThresholdScore.toString(),
    daysOfHistory: daysOfHistory.toString(),
  })
  return fetchWithParams(url, params)
}

const fetchMoldRisk = async (roomIds, daysOfHistory) => {
  const url = '/api/lab-continuum/mold-risk/?'
  const params = new URLSearchParams({
    roomIds: roomIds.join(','),
    daysOfHistory: daysOfHistory.toString(),
  })
  return fetchWithParams(url, params)
}

const fetchEnergyUsage = async (roomIds, daysOfHistory) => {
  const url = '/api/lab-continuum/energy-usage/?'
  const params = new URLSearchParams({
    roomIds: roomIds.join(','),
    daysOfHistory: daysOfHistory.toString(),
  })
  return fetchWithParams(url, params)
}

const fetchRoomsNeedingAttention = async (roomIds) => {
  const url = '/api/lab-continuum/roomsNeedingAttention/?'
  const avgQuantaThresholdValue = DEFAULT_QUANTA_THRESHOLD.toString()
  const thresholdTemperatureF = '75'
  const thresholdCO2 = '800'
  const thresholdIAQ = '100'
  const thresholdHumidity = '70'
  const minutesDuration = '60' // 1 hour
  const params = new URLSearchParams({
    roomIds: roomIds.join(','),
    avgQuantaThresholdValue,
    thresholdTemperatureF,
    thresholdCO2,
    thresholdIAQ,
    thresholdHumidity,
    minutesDuration,
  })
  return fetchWithParams(url, params)
}

const fetchContinuumService = {
  fetchHealthScore,
  fetchRoomsHealthScore,
  fetchHighRiskLocations,
  fetchRecentAverageRisk,
  fetchMoldRisk,
  fetchEnergyUsage,
  fetchRoomsNeedingAttention,
}

export default fetchContinuumService
