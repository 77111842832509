import { Alert, FormHelperText, InputLabel, Link, MenuItem, Select, Snackbar } from '@mui/material'
import React, { useContext, useState } from 'react'
import { AppContext } from '../../app/App.context'
import PathogenEditDialog from '../../pages/pssq-simulator/components/pathogen-edit-dialog/pathogen-edit-dialog.component'
import { UvaPathogen } from '../../services/api.models'
import PssqClient from '../../services/pssq.service'

export interface PathogenPickerProps {
  // currently selected value of pathogen (must be id of a pathogen in appContext)
  value: string
  // handler for onChange events
  onChange?: (newValue: string) => void
  // handler for onBlur events
  onBlur?: (event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  // error message
  error?: {
    message: string
  }
  // if true then buttons for creating/editing pathogens will be shown
  enableEditing?: boolean
  // label for the control
  label?: string
  // label for edit pathogen button
  editPathogenLabel?: string
  // label for new pathogen button
  createPathogenLabel?: boolean
  // if true then pathogen picker is disabled/read-only
  disabled?: boolean
}

// eslint-disable-next-line func-style
export const PathogenPicker: React.FC<PathogenPickerProps> = ({
  label = 'Pathogen',
  editPathogenLabel = 'Edit Pathogen',
  createPathogenLabel = 'New Pathogen',
  value,
  onChange,
  onBlur,
  enableEditing = true,
  error,
  disabled,
}) => {
  const appContext = useContext(AppContext)
  const [state, setState] = useState<{
    createDialogOpen: boolean
    editDialogOpen: boolean
    toastError: string
  }>({
    createDialogOpen: false,
    editDialogOpen: false,
    toastError: '',
  })

  function onEditPathogen() {
    setState({ ...state, editDialogOpen: true })
  }
  function onCreatePathogen() {
    setState({ ...state, createDialogOpen: true })
  }
  async function onEditDialogClose(pathogen?: Partial<UvaPathogen>): Promise<void> {
    if (!pathogen) {
      // user cancelled dialog
      setState({ ...state, editDialogOpen: false })
      return
    }
    let updated

    try {
      updated = await PssqClient.updatePathogen(pathogen as UvaPathogen)
      if (appContext && pathogen.id) {
        const pathogenIdx = appContext.pathogens.findIndex((p) => p.id === pathogen.id)
        const updatedPathogenArr = [...appContext.pathogens]
        if (pathogenIdx >= 0) {
          updatedPathogenArr[pathogenIdx] = pathogen as UvaPathogen
        }
        appContext.patchContext({
          pathogens: updatedPathogenArr,
        })
        setState({ ...state, editDialogOpen: false })
      }
    } catch (e) {
      console.error(e)
      setState({ ...state, toastError: 'Error editing the pathogen' })
    }
    return updated
  }

  async function onCreateDialogClose(pathogen?: Partial<UvaPathogen>): Promise<void> {
    if (!pathogen) {
      // user cancelled dialog
      setState({ ...state, createDialogOpen: false })
      return
    }

    let inserted
    try {
      inserted = await PssqClient.insertPathogen(pathogen as UvaPathogen)
      if (appContext) {
        appContext.patchContext({
          pathogens: [...appContext.pathogens, inserted],
        })
        setState({ ...state, createDialogOpen: false })
        onChange && onChange(inserted.id)
      }
    } catch (error) {
      console.error('Error creating a new pathogen', error)
      setState({ ...state, toastError: 'Error creating a new pathogen' })
    }
  }

  function onToastClose() {
    setState({ ...state, toastError: '' })
  }

  return (
    <>
      <InputLabel id='pathogen-label' error={!!error}>
        {label}
      </InputLabel>
      <Select
        id='pathogen-filter'
        labelId='pathogen-label'
        label={label}
        className='uva-form-control'
        variant='filled'
        onChange={(event) => onChange && onChange(event.target.value)}
        onBlur={(event) => onBlur && onBlur(event)}
        error={!!error}
        value={value}
        disabled={disabled}
      >
        <MenuItem value='' key='Pathogen-undefined'>
          Select One
        </MenuItem>
        {appContext.pathogens.map((pathogen) => (
          <MenuItem value={pathogen.id} key={`Pathogen${pathogen.id}`}>
            {pathogen.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      {enableEditing && (
        <div className='uva-entity-picker-actions'>
          {value ? (
            <Link href='#' onClick={onEditPathogen}>
              {/* FIXME:  Do not use spaces for spacing. */} {editPathogenLabel}{' '}
            </Link>
          ) : (
            // FIXME: Should be handled with css for links in uva-entity-picker-actions
            <span></span>
          )}
          <Link href='#' onClick={onCreatePathogen}>
            {/* FIXME:  Do not use spaces for spacing. */} {createPathogenLabel}{' '}
          </Link>
        </div>
      )}
      {state.editDialogOpen && (
        <PathogenEditDialog
          initialPathogenData={appContext?.pathogens.find((pathogen) => pathogen.id === value)}
          onClose={onEditDialogClose}
        />
      )}
      {state.createDialogOpen && <PathogenEditDialog onClose={onCreateDialogClose} />}
      {/* FIXME: Errors should appear in the form that generated them. And should never appear on lower-left corner of screen. */}
      <Snackbar open={!!state.toastError} autoHideDuration={6000} onClose={onToastClose}>
        <Alert onClose={onToastClose} severity='error' sx={{ width: '100%' }}>
          {state.toastError}
        </Alert>
      </Snackbar>
    </>
  )
}
