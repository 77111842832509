/* eslint no-magic-numbers: 0 */
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  InputAdornment,
  Paper,
  Skeleton,
  TextField,
} from '@mui/material'
import React from 'react'
import { AppContext } from '../../../../app/App.context'
import {
  UvaFixedSimulationParams,
  UvaFormFieldError,
  UvaFormSubmissionError,
} from '../../../../app/uva-interfaces'
import { RoomPicker } from '../../../../components/room-picker/room-picker.component'
import { PathogenPicker } from '../../../../components/pathogen-picker/pathogen-picker.component'
import { UvaPathogen, UvaRoom } from '../../../../services/api.models'

const pathogenErrorMsg = 'Pathogen is required'
const roomErrorMsg = 'Room is required'
const maxQuantaDensityErrorMsg = 'Max quanta density must be a non-negative number'
const infectionRateErrorMsg = 'Infection rate must be a number between 0 and 100 inclusive'

export interface FixedParamsPanelProps extends UvaFixedSimulationParams {
  result: (data: Required<UvaFixedSimulationParams>) => void
  roomEdited: (room: Partial<UvaRoom>) => void
  pathogenEdited: (data: Partial<UvaPathogen>) => void
  updateFixedParams: (data: Required<UvaFixedSimulationParams>) => void
}

// eslint-disable-next-line func-style
const FixedParamsPanel: React.FC<FixedParamsPanelProps> = (props) => {
  const appContext = React.useContext(AppContext)
  const [state, setState] = React.useState<{
    roomId: string
    pathogenId: string
    maxQuantaDensity: string
    infectionRate: string
    pathogenDialogOpen: boolean
    formError?: UvaFormSubmissionError
  }>({
    roomId: props.roomId || appContext.rooms[0]?.id || '',
    pathogenId: props.pathogenId || appContext.pathogens[0]?.id || '',
    maxQuantaDensity: props.maxQuantaDensity?.toString() ?? '',
    infectionRate: props.infectionRate?.toString() ?? '',
    pathogenDialogOpen: false,
  })

  const [pathogenError, setPathogenError] = React.useState<UvaFormFieldError>({
    error: false,
    message: undefined,
  })

  const [roomError, setRoomError] = React.useState<UvaFormFieldError>({
    error: false,
    message: undefined,
  })

  const [maxQuantaDensityError, setMaxQuantaDensityError] = React.useState<UvaFormFieldError>({
    error: false,
    message: undefined,
  })

  const [infectionRateError, setInfectionRateError] = React.useState<UvaFormFieldError>({
    error: false,
    message: undefined,
  })

  function validatePathogenId(val: string | undefined): boolean {
    if (!val || (typeof val === 'string' && val.trim() === '')) {
      return false
    }
    return true
  }

  function validateRoomId(val: string | undefined): boolean {
    if (!val || (typeof val === 'string' && val.trim() === '')) {
      return false
    }
    return true
  }

  function validateMaxQuantaDensity(val: string | undefined): boolean {
    if (val === undefined || val === '') {
      return false
    }

    // decimals are ok
    if (val && val.length === 1 && val === '.') {
      return true
    }

    if (typeof val === 'string') {
      const isFloat = parseFloat(val)
      if (isNaN(isFloat)) {
        return false
      }
    }
    return true
  }

  function validateInfectionRate(val: string | undefined): boolean {
    if (val === undefined || val === '') {
      return false
    }
    if (typeof val === 'string') {
      const isFloat = parseFloat(val)
      if (
        isNaN(isFloat) ||
        isFloat.toString() !== val ||
        parseFloat(val) < 0 ||
        parseFloat(val) > 100
      ) {
        return false
      }
    }
    return true
  }

  function handlePathogenBlur(event: React.SyntheticEvent) {
    const val = (event.target as HTMLInputElement).value
    if (validatePathogenId(val)) {
      setPathogenError({
        error: false,
        message: undefined,
      })
    } else {
      setPathogenError({
        error: true,
        message: pathogenErrorMsg,
      })
    }
  }

  function handleRoomBlur(event: React.SyntheticEvent) {
    const val = (event.target as HTMLInputElement).value
    if (validateRoomId(val)) {
      setRoomError({
        error: false,
        message: undefined,
      })
    } else {
      console.log('setting room to invalid')
      setRoomError({
        error: true,
        message: roomErrorMsg,
      })
    }
  }

  function handleMaxQuantaDensityChange(event: React.SyntheticEvent) {
    const val = (event.target as HTMLInputElement).value
    const lastChar = val.charAt(val.length - 1)

    if (validateMaxQuantaDensity(val)) {
      setState({
        ...state,
        maxQuantaDensity: val.toString(),
      })
      setMaxQuantaDensityError({
        error: false,
        message: undefined,
      })
    } else {
      // No error on entering period to allow decimal values
      if (lastChar !== '.') {
        setMaxQuantaDensityError({
          error: true,
          message: maxQuantaDensityErrorMsg,
        })
      }
    }
  }

  function handleInfectionRateChange(event: React.SyntheticEvent) {
    const val = (event.target as HTMLInputElement).value
    if (validateInfectionRate(val)) {
      setState({
        ...state,
        infectionRate: val.toString(),
      })
      setInfectionRateError({
        error: false,
        message: undefined,
      })
    } else {
      setInfectionRateError({
        error: true,
        message: infectionRateErrorMsg,
      })
    }
  }

  function validateForm(): boolean {
    let isFormValid = true
    const isValidPathogen = validatePathogenId(state.pathogenId)
    const isValidRoom = validateRoomId(state.roomId)
    const isValidMaxQuantaDensity = validateMaxQuantaDensity(state.maxQuantaDensity)
    const isValidInfectionRate = validateInfectionRate(state.infectionRate)

    if (!isValidPathogen) {
      isFormValid = false
      setPathogenError({
        error: true,
        message: pathogenErrorMsg,
      })
    }

    if (!isValidRoom) {
      isFormValid = false
      setRoomError({
        error: true,
        message: roomErrorMsg,
      })
    }

    if (!isValidMaxQuantaDensity) {
      isFormValid = false
      setMaxQuantaDensityError({
        error: true,
        message: maxQuantaDensityErrorMsg,
      })
    }

    if (!isValidInfectionRate) {
      isFormValid = false
      setInfectionRateError({
        error: true,
        message: infectionRateErrorMsg,
      })
    }

    if (!isFormValid) {
      setState({
        ...state,
        formError: {
          status: 'error',
          message: 'There was a problem initiating the scenario.',
        },
      })
    }
    return isFormValid
  }

  function onSave() {
    if (validateForm()) {
      setState((prev) => ({
        ...prev,
        error: null,
      }))

      const maxQuanta = +state.maxQuantaDensity
      const ir = +state.infectionRate

      if (props) {
        props.updateFixedParams({
          roomId: state.roomId,
          pathogenId: state.pathogenId,
          maxQuantaDensity: maxQuanta,
          infectionRate: ir,
        })
      }
    }
  }

  return appContext.loading ? (
    <Skeleton className='uva-skeleton uva-skeleton-panel' id='FIXEDPARAMSPANEL' />
  ) : (
    <Card className='uva-params-panel' id='UvaFixedParamsPanel'>
      <CardHeader title='Fixed Parameters' />
      <CardContent>
        {state.formError?.status === 'error' && (
          <Paper component='div' className='uva-form-notifications'>
            <Alert severity='error'>
              <AlertTitle>Error in Fixed Simulation Parameters</AlertTitle>
              There was a problem starting the simulation.
            </Alert>
          </Paper>
        )}
        <FormControl variant='filled' className='uva-form-control' fullWidth>
          <PathogenPicker
            value={state.pathogenId}
            onChange={(value) => setState((prev) => ({ ...prev, pathogenId: value }))}
            onBlur={(event) => handlePathogenBlur(event)}
            error={pathogenError.error ? { message: pathogenError.message || '' } : undefined}
          />
        </FormControl>
        <FormControl variant='filled' className='uva-form-control' fullWidth>
          <RoomPicker
            rooms={appContext.rooms}
            value={state.roomId}
            onChange={(value) => setState((prev) => ({ ...prev, roomId: value }))}
            onBlur={(event) => handleRoomBlur(event)}
            error={roomError.error ? { message: roomError.message || '' } : undefined}
          />
        </FormControl>
        <FormControl fullWidth className='uva-form-control'>
          <TextField
            label='Maximum Quanta Density'
            className='uva-form-control'
            variant='filled'
            fullWidth
            error={maxQuantaDensityError.error}
            helperText={maxQuantaDensityError.message}
            onChange={(event) => handleMaxQuantaDensityChange(event)}
            defaultValue={state.maxQuantaDensity}
          />
        </FormControl>
        <FormControl fullWidth className='uva-form-control'>
          <TextField
            label='Infection Rate'
            className='uva-form-control'
            variant='filled'
            error={infectionRateError.error}
            helperText={infectionRateError.message}
            defaultValue={state.infectionRate}
            onChange={(event) => handleInfectionRateChange(event)}
            InputProps={{
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            }}
          />
        </FormControl>
      </CardContent>
      <CardActions className='uva-form-actions'>
        <Button
          onClick={onSave}
          variant='contained'
          id='UvaSaveFixedParamsBtn'
          data-test-id='save-persistent-params-btn'
        >
          Next
        </Button>
      </CardActions>
    </Card>
  )
}

export default FixedParamsPanel
