/* eslint-disable no-magic-numbers */
import { AvailableMetricEnum } from '../monitoring-page.component'
import type { HierarchicalRoomWithGeoDataType } from '../room-mocks'
import type { HierarchicalRoomType } from '../components/room-selector/room-selector.component'

function isFloor(room: HierarchicalRoomType) {
  return !!room?.rooms?.length
}

const temperatureColorMap = {
  cold: 'rgba(0, 113, 193, 1)',
  cool: 'rgba(114, 172, 69, 1)',
  warm: 'rgba(237, 125, 49, 1)',
  hot: 'rgba(254, 2, 1, 1)',
}

function getTemperatureMetricColorConfigData(value: number) {
  const roundedValue = Math.round(value)
  let fillColor
  if (roundedValue <= 40) {
    fillColor = temperatureColorMap.cold
  } else if (roundedValue <= 60) {
    fillColor = temperatureColorMap.cool
  } else if (roundedValue <= 80) {
    fillColor = temperatureColorMap.warm
  } else {
    fillColor = temperatureColorMap.hot
  }
  return { 'fill-color': fillColor }
}

function getPssqColorConfigData(value: number) {
  let fillColor
  if (value <= 0.002) {
    fillColor = colorMap.excellent
  } else if (value <= 0.003) {
    fillColor = colorMap.good
  } else if (value <= 0.004) {
    fillColor = colorMap.lightlyPolluted
  } else if (value <= 0.005) {
    fillColor = colorMap.moderatelyPolluted
  } else if (value <= 0.006) {
    fillColor = colorMap.heavillyPolluted
  } else {
    fillColor = colorMap.severelyPolluted
  }
  return { 'fill-color': fillColor }
}

function getCO2ColorConfigData(value: number) {
  let fillColor
  if (value <= 500) {
    fillColor = colorMap.excellent
  } else if (value <= 700) {
    fillColor = colorMap.good
  } else if (value <= 1000) {
    fillColor = colorMap.lightlyPolluted
  } else if (value <= 1500) {
    fillColor = colorMap.moderatelyPolluted
  } else if (value <= 2500) {
    fillColor = colorMap.heavillyPolluted
  } else {
    fillColor = colorMap.severelyPolluted
  }
  return { 'fill-color': fillColor }
}

function getHumidityColorConfigData(value: number) {
  let fillColor
  if (value <= 50) {
    fillColor = colorMap.excellent
  } else if (value <= 65) {
    fillColor = colorMap.good
  } else if (value <= 75) {
    fillColor = colorMap.lightlyPolluted
  } else if (value <= 90) {
    fillColor = colorMap.moderatelyPolluted
  } else if (value <= 100) {
    fillColor = colorMap.heavillyPolluted
  } else {
    fillColor = colorMap.severelyPolluted
  }
  return { 'fill-color': fillColor }
}

function getVOCColorConfigData(value: number) {
  let fillColor
  if (value <= 200) {
    fillColor = colorMap.excellent
  } else if (value <= 400) {
    fillColor = colorMap.good
  } else if (value <= 800) {
    fillColor = colorMap.lightlyPolluted
  } else if (value <= 2000) {
    fillColor = colorMap.moderatelyPolluted
  } else {
    fillColor = colorMap.severelyPolluted
  }
  return { 'fill-color': fillColor }
}

const colorMap = {
  excellent: 'rgba(0, 228, 0, 1)',
  good: 'rgba(146, 208, 80, 1)',
  lightlyPolluted: 'rgba(255, 255, 0, 1)',
  moderatelyPolluted: 'rgba(255, 126, 0, 1)',
  heavillyPolluted: 'rgba(255, 0, 0, 1)',
  severelyPolluted: 'rgba(153, 0, 76, 1)',
  extremelyPolluted: 'rgba(102, 51, 0)',
}

function getIAQMetricColorConfigData(value: number) {
  const roundedValue = Math.round(value)
  let fillColor
  if (roundedValue >= 0 && roundedValue <= 50) {
    fillColor = colorMap.excellent
  } else if (roundedValue >= 51 && roundedValue <= 100) {
    fillColor = colorMap.good
  } else if (roundedValue >= 101 && roundedValue <= 150) {
    fillColor = colorMap.lightlyPolluted
  } else if (roundedValue >= 151 && roundedValue <= 200) {
    fillColor = colorMap.moderatelyPolluted
  } else if (roundedValue >= 201 && roundedValue <= 250) {
    fillColor = colorMap.heavillyPolluted
  } else if (roundedValue >= 251 && roundedValue <= 350) {
    fillColor = colorMap.severelyPolluted
  } else {
    fillColor = colorMap.extremelyPolluted
  }

  return { 'fill-color': fillColor }
}

const defaultColorConfigData = {
  'fill-color': 'rgba(228, 228, 228, 0)',
  'fill-outline-color': 'rgba(255, 255, 255, 0)',
}

export default function getRoomColorConfigData(
  room: HierarchicalRoomWithGeoDataType,
  value: number | undefined,
  selectedMetric: keyof typeof AvailableMetricEnum,
) {
  if (isFloor(room) || value == null) {
    // Floors (rooms with subrooms) will be transparent
    // Rooms with a value of null/undefined will also be transparent
    return defaultColorConfigData
  }
  // We return a default color config for all the other metric type due to this being a prototype.
  // Only Temperature and IAQ based metrics will produce dynamic color configs for each room.
  // Left the other cases in the event we want to expand a particular metrics functionality.
  switch (selectedMetric) {
    case 'TEMP':
      return getTemperatureMetricColorConfigData(value)
    case 'CO2':
      return getCO2ColorConfigData(value)
    case 'HUMIDITY':
      return getHumidityColorConfigData(value)
    case 'PSSQ':
      return getPssqColorConfigData(value)
    case 'RISK':
      return defaultColorConfigData
    case 'MOLD':
      return defaultColorConfigData
    case 'CADR':
      return defaultColorConfigData
    case 'IAQ':
      return getIAQMetricColorConfigData(value)
    case 'VOC':
      return getVOCColorConfigData(value)
    case 'PRESSURE':
      return defaultColorConfigData
    default:
      return defaultColorConfigData
  }
}
