import React, { useContext, useState } from 'react'
import { Skeleton } from '@mui/material'
import { AppContext } from '../../../../app/App.context'
import {
  UvaFixedSimulationParams,
  UvaScenario,
  UvaScenarioEvent,
} from '../../../../app/uva-interfaces'
import ScenarioBuilderTable from './scenario-builder-table/scenario-builder-table.component'
import FixedParamsTable from '../fixed-params-table/fixed-params-table.component'

function generateScenarioId() {
  const maxVal = 100000000
  return Math.floor(Math.random() * maxVal).toString()
}

export interface UvaScenarioBuilderProps {
  fixedParams: UvaFixedSimulationParams | undefined
  updateFixedParams: (fixedParams: UvaFixedSimulationParams) => void
  runSimulation: (scenario: UvaScenario) => Promise<void>
  newSimulation: () => void
}

// eslint-disable-next-line func-style
const ScenarioBuilder: React.FC<UvaScenarioBuilderProps> = (props) => {
  const appContext = useContext(AppContext)
  const defaultScenarioId = generateScenarioId()
  let defaultFixedParams: UvaFixedSimulationParams = {
    roomId: '',
    pathogenId: '',
    maxQuantaDensity: 0,
    infectionRate: 0,
  }
  if (props.fixedParams) {
    defaultFixedParams = props.fixedParams
  }
  let defaultScenario: UvaScenario = {
    id: defaultScenarioId,
    name: `Scenario ${defaultScenarioId}`,
    scenarioEvents: [],
    fixedParams: defaultFixedParams,
  }
  const [activeScenarioId, setActiveScenarioId] = useState<string | null>(
    localStorage.getItem(appContext.activeScenarioIdKey),
  )

  const rawSavedScenarios = localStorage.getItem(appContext.savedScenariosKey)
  if (activeScenarioId && rawSavedScenarios) {
    const savedScenarios = JSON.parse(rawSavedScenarios)
    const activeScenarioIndex = savedScenarios.findIndex(
      (existingScenario: UvaScenario) => existingScenario.id === activeScenarioId,
    )
    defaultScenario = savedScenarios[activeScenarioIndex]
  }
  const [activeScenario, setActiveScenario] = useState<UvaScenario>(defaultScenario)

  function getSavedScenarios(): UvaScenario[] {
    let savedScenarios: UvaScenario[] = []
    const rawSavedScenarios = localStorage.getItem(appContext.savedScenariosKey)
    if (rawSavedScenarios) {
      savedScenarios = JSON.parse(rawSavedScenarios)
    }
    return savedScenarios
  }

  function getScenarioById(id: string): UvaScenario | undefined {
    let scenario: UvaScenario | undefined
    const savedScenarios = getSavedScenarios()
    const savedScenariosArray = savedScenarios.filter((s) => s.id === id)
    if (savedScenariosArray.length === 1) {
      scenario = savedScenariosArray[0]
    } else if (savedScenariosArray.length > 1) {
      throw new Error('Duplicate scenario ids')
    }
    return scenario
  }

  function deleteScenario(scenarioId: string): void {
    const rawSavedScenarios = localStorage.getItem(appContext.savedScenariosKey)
    if (rawSavedScenarios) {
      const savedScenarios = JSON.parse(rawSavedScenarios)
      if (savedScenarios) {
        const scenarioIndex = savedScenarios.findIndex(
          (existingScenario: UvaScenario) => existingScenario.id.toString() === scenarioId,
        )
        savedScenarios.splice(scenarioIndex, 1)
        if (savedScenarios.length === 0) {
          // Set new default scenario with new ID
          const newScenarioId = generateScenarioId()
          defaultScenario.id = newScenarioId
          defaultScenario.name = `Scenario ${newScenarioId}`
          defaultScenario.scenarioEvents = []
          localStorage.setItem(appContext.savedScenariosKey, JSON.stringify([defaultScenario]))
          localStorage.setItem(appContext.activeScenarioIdKey, defaultScenario.id)
          setActiveScenarioId(defaultScenario.id)
        } else {
          localStorage.setItem(appContext.savedScenariosKey, JSON.stringify(savedScenarios))
          // FIXME?: Arbitrarily set activeScenario to first scenario in array
          const activeScenario = savedScenarios[0]
          localStorage.setItem(appContext.activeScenarioIdKey, activeScenario.id)
          setActiveScenarioId(activeScenario.id)
        }
      }
    }
  }

  function resetScenarioBuilder(): void {
    deleteScenario(activeScenario.id)
    props.newSimulation()
  }

  function saveScenario(scenario: UvaScenario): boolean {
    try {
      setActiveScenario(scenario)
      localStorage.setItem(appContext.activeScenarioIdKey, scenario.id)
      localStorage.setItem(appContext.savedScenariosKey, JSON.stringify([scenario]))
    } catch (e) {
      return false
    }
    return true
  }

  function getScenarioEventById(scenarioEventId: string): UvaScenarioEvent | undefined {
    if (!activeScenario || !activeScenario.scenarioEvents) {
      throw new Error('No active scenario or scenario has no scenario events')
    }
    const scenarioEventIndex = activeScenario.scenarioEvents.findIndex(
      (existingScenarioEvent) => existingScenarioEvent.id.toString() === scenarioEventId.toString(),
    )
    return activeScenario.scenarioEvents[scenarioEventIndex]
  }

  function updateScenarioEvent(scenarioEvent: UvaScenarioEvent): boolean {
    if (!activeScenario || !activeScenario.scenarioEvents) {
      throw new Error('No active scenario or scenario has no scenario events')
    }

    const scenarioEventIndex = activeScenario.scenarioEvents.findIndex(
      (existingScenarioEvent) => existingScenarioEvent.id === scenarioEvent.id,
    )

    activeScenario.scenarioEvents[scenarioEventIndex] = scenarioEvent
    const isUpdateSuccess: boolean = saveScenario(activeScenario)

    return isUpdateSuccess
  }

  function addScenarioEvent(scenarioEvent: UvaScenarioEvent): boolean {
    // this should never happen because we initialize with a default even if there are no saved scenarios
    if (!activeScenario || !activeScenario.scenarioEvents) {
      throw new Error('There is either no active scenario or it is missing scenario events')
    }
    const eventOrder: number = activeScenario.scenarioEvents.length + 1
    scenarioEvent.order = eventOrder
    activeScenario.scenarioEvents.push(scenarioEvent)
    const addEventSuccess: boolean = saveScenario(activeScenario)
    return addEventSuccess
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function deleteScenarioEvent(event: any): boolean {
    // return true
    const scenarioEventId: string = event.currentTarget.parentElement?.parentElement?.getAttribute(
      'data-scenario-event-id',
    ) as string
    if (
      activeScenario &&
      activeScenario.scenarioEvents &&
      activeScenario.scenarioEvents.length > 0
    ) {
      const scenarioEventIndex: number = activeScenario.scenarioEvents.findIndex(
        (scenarioEvent: UvaScenarioEvent) => scenarioEvent.id === scenarioEventId,
      )
      activeScenario.scenarioEvents.splice(scenarioEventIndex, 1)
      saveScenario(activeScenario)
      return true
    }
    return false
  }

  return appContext.loading ? (
    <>
      <Skeleton className='uva-skeleton uva-skeleton-panel' variant='rectangular' />
      <Skeleton className='uva-skeleton uva-skeleton-panel' variant='rectangular' />
    </>
  ) : (
    <>
      <ScenarioBuilderTable
        getSavedScenarios={getSavedScenarios}
        getScenarioById={getScenarioById}
        getScenarioEventById={getScenarioEventById}
        saveScenario={saveScenario}
        fixedParams={props.fixedParams}
        activeScenario={activeScenario}
        runSimulation={props.runSimulation}
        addScenarioEvent={addScenarioEvent}
        updateScenarioEvent={updateScenarioEvent}
        deleteScenarioEvent={deleteScenarioEvent}
      ></ScenarioBuilderTable>

      <FixedParamsTable
        newSimulation={() => resetScenarioBuilder()}
        roomId={props.fixedParams?.roomId as string}
        pathogenId={props.fixedParams?.pathogenId as string}
        maxQuantaDensity={props.fixedParams?.maxQuantaDensity as number}
        infectionRate={props.fixedParams?.infectionRate as number}
        key='FixedParamsTable'
      />
    </>
  )
}

export default ScenarioBuilder
