import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl, TextField
} from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { AppContext } from '../../../app/App.context'
import { UvaAlert } from '../../../services/api.models'
import PssqClient from '../../../services/pssq.service'

export interface AlertDialogProps {
  title?: string
  trackId: string
  onClose: () => void
  onSave: (alert: UvaAlert) => void
}

// eslint-disable-next-line func-style
export const AlertDialog: React.FC<AlertDialogProps> = (props) => {
  const appContext = React.useContext(AppContext)
  const [state, setState] = React.useState<{
    nickname: string
    quantaTreshold: string
    debounceSeconds: string
    recipients: string[]
    error: string
    isLoading: boolean
  }>({
    nickname: '',
    quantaTreshold: '',
    debounceSeconds: '',
    recipients: [],
    error: '',
    isLoading: false,
  })
  const errorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // scroll to the error message at the bottom of the form each time it changes
    if (state.error) {
      errorRef.current?.scrollIntoView()
    }
  }, [state.error])

  function handlePositiveNumberChange(value: string, field: string, name: string) {
    const numValue = Number(value)

    if (Number.isNaN(numValue) || numValue < 0) {
      setState({
        ...state,
        error: `${name} must be a positive number`,
      })
      return
    }

    setState({
      ...state,
      [field]: value,
      error: '',
    })
  }

  function handleRecipientsChange(value: string[]) {
    setState((prev) => ({
      ...prev,
      recipients: [...value],
      error: value.some((email) => !email.match(/^\S+@\S+\.\S+$/)) // FIXME: better email regex
        ? 'Recipients&rsquo; email addresses are not valid'
        : '',
    }))
  }

  function handleSave() {
    if (state.isLoading) {
      return
    }

    if (!state.quantaTreshold) {
      setState({ ...state, error: 'Provide value for quanta treshold' })
      return
    }

    const quantaTreshold = Number(state.quantaTreshold)

    if (isNaN(quantaTreshold) || quantaTreshold < 0) {
      setState({ ...state, error: 'Quanta treshold must be a positive number' })
      return
    }

    if (!state.debounceSeconds) {
      setState({ ...state, error: 'Provide value for debounce time' })
      return
    }

    const debounceSeconds = +state.debounceSeconds
    if (isNaN(debounceSeconds) || debounceSeconds < 0) {
      setState({ ...state, error: 'Debounce time must be a positive number' })
      return
    }

    if (!state.recipients.length) {
      setState({ ...state, error: 'At least 1 recipient email address must be provided' })
      return
    }

    if (state.recipients.some((email) => !email.match(/^\S+@\S+\.\S+$/))) {
      setState({ ...state, error: 'Recipients&rsquo; email addresses are not valid' })
      return
    }

    setState({ ...state, error: '', isLoading: true })

    PssqClient.insertAlert({
      track_id: props.trackId,
      quanta_threshold: quantaTreshold,
      nickname: state.nickname,
      debounce_seconds: debounceSeconds,
      recipient_emails: state.recipients,
    })
      .then((alert) => props.onSave(alert))
      .catch((error) => {
        console.error('Error saving alert', alert)
        setState({ ...state, error: error.message || 'Error saving alert', isLoading: false })
      })
  }

  return (
    <Dialog open={true} onClose={() => props.onClose()} id='UvaAlertDialog'>
      <DialogTitle>{props.title || 'New Alert'}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth className='uva-form-control'>
          <TextField
            label='Nickname'
            className='uva-form-control'
            variant='filled'
            value={state.nickname}
            onChange={(event) => setState({ ...state, nickname: event.target.value })}
          />
        </FormControl>

        <FormControl fullWidth className='uva-form-control'>
          <TextField
            label='Quanta Treshold'
            className='uva-form-control'
            variant='filled'
            fullWidth
            value={state.quantaTreshold}
            disabled={state.isLoading}
            onChange={(event) => setState({ ...state, quantaTreshold: event.target.value })}
            onBlur={(event) =>
              handlePositiveNumberChange(event.target.value, 'quantaTreshold', 'quanta treshold')
            }
          />
        </FormControl>

        <FormControl fullWidth className='uva-form-control'>
          <TextField
            label='Debounce Time (seconds)'
            className='uva-form-control'
            variant='filled'
            fullWidth
            value={state.debounceSeconds}
            disabled={state.isLoading}
            onChange={(event) => setState({ ...state, debounceSeconds: event.target.value })}
            onBlur={(event) =>
              handlePositiveNumberChange(event.target.value, 'debounceSeconds', 'debounce time')
            }
          />
        </FormControl>
        <FormControl variant='filled' fullWidth className='uva-form-control'>
          <Autocomplete
            multiple
            id='recipients-autocomplete'
            options={[]}
            value={[...state.recipients]}
            freeSolo
            autoSelect
            onChange={(e, value) => handleRecipientsChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Recipients'
                placeholder='some@email.com'
                value={state.recipients}
              />
            )}
          />
        </FormControl>

        {state.error && (
          <Alert severity='error' ref={errorRef}>
            {state.error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='secondary' onClick={() => props.onClose()}>
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSave}
          loading={state.isLoading}
          variant='contained'
          type='submit'
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
