import { ToggleButton, ToggleButtonGroup } from '@mui/material'

export interface FloorSwitcherProps {
  floors: {
    id: string,
    name?: string,
    ordinal?: number,
  }[],
  selectedFloorId?: string,
  showFloorName?: boolean,
  onSelectFloor?: (floorId: string) => void,
}

// eslint-disable-next-line func-style
export const FloorSwitcher: React.FC<FloorSwitcherProps> = (props: FloorSwitcherProps) => {
  return (
    <ToggleButtonGroup
      orientation='vertical'
      className='floor-switcher'
      value={props.selectedFloorId}
      exclusive
      onChange={(evt, value) => props.onSelectFloor && props.onSelectFloor(value)}>
      {
        props.floors.map(floor => (
          <ToggleButton
            key={`FloorSwitcherLevel${floor.id}`}
            value={floor.id}
            title={floor.name}>
            {
              props.showFloorName ? floor.name : floor.ordinal
            }
          </ToggleButton>
        ))
      }
    </ToggleButtonGroup>
  )
}
