/* eslint no-magic-numbers: 0 */
import { Amplify, Auth, Hub } from 'aws-amplify'
import { UvaUser } from '../../src/app/uva-interfaces'

const AMPLIFY_CONFIG = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${window.location.origin}${process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN_PATH}`,
      redirectSignOut: `${window.location.origin}${process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT_PATH}`,
      responseType: 'code',
    },
  },
}

export type AuthListener = (authenticaled: boolean) => void

class AuthService {
  private isAuthed = false
  private accessToken: string | undefined = undefined
  private authListeners: AuthListener[] = []

  addAuthListener(listener: AuthListener) {
    if (!this.authListeners.includes(listener)) {
      this.authListeners.push(listener)
      setTimeout(() => listener(this.isAuthed), 0)
    }
  }

  removeAuthListener(removedListener: AuthListener) {
    this.authListeners = this.authListeners.filter((listener) => listener !== removedListener)
  }

  setIsAuthed = (authed: boolean): void => {
    this.isAuthed = authed
    if (!authed) {
      this.accessToken = undefined
    }

    // setTimeout to make it async
    setTimeout(
      () =>
        this.authListeners.forEach((listener) => {
          try {
            listener(this.isAuthed)
          } catch (error) {
            console.error('Error in auth listener')
          }
        }),
      0,
    )
  }

  getIsAuthed = () => {
    return this.isAuthed
  }

  setAccessToken = async (): Promise<boolean> => {
    const currentSession = await Auth.currentSession()
    if (currentSession) {
      this.accessToken = currentSession.getAccessToken().getJwtToken()
      return true
    } else {
      this.accessToken = undefined
      return false
    }
  }

  manuallyRefreshSession = async () => {
    console.log('manual refresh token')
    return await Auth.currentAuthenticatedUser({ bypassCache: true })
  }

  getAccessToken = (): string | undefined => {
    return this.accessToken
  }

  destroySession = (): void => {
    this.setIsAuthed(false)
  }

  handleLogin = async (): Promise<void> => {
    await Auth.federatedSignIn()
  }

  handleLogout = async (): Promise<void> => {
    await Auth.signOut()
  }

  getUser = async (): Promise<UvaUser> => {
    return await Auth.currentAuthenticatedUser()
  }

  constructor() {
    Amplify.configure(AMPLIFY_CONFIG)

    Hub.listen('auth', ({ payload: { event } }) => {
      console.log(event)
      switch (event) {
        case 'signIn':
        case 'tokenRefresh':
          this.setAccessToken().then((authorized) => this.setIsAuthed(authorized))
          break
        case 'cognitoHostedUI':
          break
        case 'signOut':
          this.setIsAuthed(false)
          break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          this.setIsAuthed(false)
          break
      }
    })

    // This handles the case when we reload page, user is logged in and his token is in local storage
    this.setAccessToken().then((authorized) => this.setIsAuthed(authorized))
  }
}

const AuthClient = new AuthService()

export default AuthClient
