import React from 'react'
import { Room, Warning } from '@mui/icons-material'

import {
  Box,
  Card,
  Typography,
  CardMedia,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@mui/material'

import type { RoomsNeedingAttentionResponseType } from '../../../services/continuum.service'
import type { UvaRoom as UvaRoomType } from '../../../services/api.models'

type Props = {
  roomsNeedingAttention: RoomsNeedingAttentionResponseType | null
  rooms: UvaRoomType[]
}

const getRoomData = (targetID, list) => {
  return list.find((room) => room.id === targetID)
}

const getAlertSubString = (alertType, alertData) => {
  const valueTrimmed = alertData.value.toString().slice(0, 5)
  const alertMessages = {
    highCO2: 'CO2: High',
    highHumidity: 'Humidity: High',
    highIAQ: 'IAQ Level: High',
    quanta: `${valueTrimmed} quanta/cm³`,
    temp: `Temp: ${valueTrimmed}°F`,
  }

  return alertMessages[alertType] || 'empty'
}

export default function LocationsNeedingAttentionCard({ roomsNeedingAttention, rooms }: Props) {
  const roomDataListFormatted = roomsNeedingAttention
    ? roomsNeedingAttention
      .map((room) => {
        const { name: primaryText } = getRoomData(room.roomId, rooms)
        let alertCount = 0
        let secondaryText = ''
        Object.keys(room.alerts).forEach((key) => {
          const alertState = room.alerts[key]
          if (alertState.alert) {
            alertCount += 1
            secondaryText += `${getAlertSubString(key, alertState)} | `
          }
        })
        const index = secondaryText.lastIndexOf('|')
        if (index !== -1) {
          // we trim off the last ocurrence of '|'
          secondaryText = secondaryText.substring(0, index)
        }
        return {
          id: room.roomId,
          alertCount,
          primaryText,
          secondaryText,
        }
      })
      .sort((a, b) => b.alertCount - a.alertCount)
    : []

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box display='flex' justifyContent='start' alignItems='center'>
          <Typography variant='h5' component='div'>
            Locations Needing Attention
          </Typography>
          <Warning fontSize='large' color='warning' sx={{ marginLeft: 1, paddingBottom: 0.5 }} />
        </Box>
        <Typography variant='body2' color='text.secondary'>
          Locations in an elevated risk state for any reason will show up here. Click them to learn
          more.
        </Typography>
      </CardContent>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        minHeight={300}
        width='100%'
      >
        {roomsNeedingAttention ? (
          <CardMedia sx={{ overflowY: 'auto', maxHeight: 325, width: '100%' }}>
            <List sx={{ width: '100%' }}>
              {
                roomDataListFormatted.length > 0 ?
                  roomDataListFormatted.map((room, index) => {
                    const { primaryText, secondaryText, id } = room
                    return (
                      <ListItem key={id}>
                        <ListItemButton
                          selected={index === 0 ? true : false}
                          href={`/continuum/floors?roomId=${id}`}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <Room />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={primaryText} secondary={secondaryText} />
                        </ListItemButton>
                      </ListItem>
                    )
                  }) :
                  <Typography sx={{ textAlign: 'center' }} variant='h5' color='text.secondary'>
                    No locations needing immediate attention!
                  </Typography>
              }
            </List>
          </CardMedia>
        ) : (
          <CircularProgress sx={{ alignSelf: 'center' }} size={75} />
        )}
      </Box>
    </Card>
  )
}
