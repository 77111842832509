import React from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { UvaRoom } from '../../../../services/api.models'
import DeleteIcon from '@mui/icons-material/Delete'

import { AppContext } from '../../../../app/App.context'

export interface RoomEditDialogProps {
  initialRoomData?: UvaRoom
  onClose: (room?: Partial<UvaRoom>) => void
}

interface AddableTextFieldProps {
  label: string
  value: string | number
  onChange: (e: React.SyntheticEvent) => void
}

// eslint-disable-next-line func-style
const AddableTextField: React.FC<AddableTextFieldProps> = ({ label, value, onChange }) => {
  return (
    <TextField
      type='text'
      variant='filled'
      fullWidth
      label={label}
      defaultValue={value}
      className='uva-form-control'
      data-dsn={value}
      onBlur={onChange}
      key={value}
    />
  )
}

interface AddableTextFieldListProps {
  deviceSerialNumbers?: string[]
  handleAdd: (e: React.SyntheticEvent) => boolean
  handleDelete: (e: React.SyntheticEvent) => boolean
  handleUpdate: (e: React.SyntheticEvent) => boolean
}

// eslint-disable-next-line func-style
const AddableTextFieldList: React.FC<AddableTextFieldListProps> = ({
  deviceSerialNumbers,
  handleAdd,
  handleDelete,
  handleUpdate,
}) => {
  const newDeviceFieldProps: TextFieldProps = {
    variant: 'filled',
    fullWidth: true,
    label: 'Device Serial Number',
    helperText: '',
    error: false,
    key: 'new-dsn',
    id: 'UvaNewDevice',
  }

  const newDeviceField = (
    <TextField
      variant={newDeviceFieldProps.variant}
      fullWidth={newDeviceFieldProps.fullWidth}
      label={newDeviceFieldProps.label}
      key={newDeviceFieldProps.key}
      id={newDeviceFieldProps.id}
    />
  )

  function addDevice(e: React.SyntheticEvent): void {
    const result = handleAdd(e)
    if (!result) {
      newDeviceFieldProps.error = true
      newDeviceFieldProps.helperText = 'Error adding device'
    }
  }

  function deleteDevice(e: React.SyntheticEvent): void {
    const result = handleDelete(e)
    if (!result) {
      newDeviceFieldProps.error = true
      newDeviceFieldProps.helperText = 'Error deleting device'
    }
  }

  function updateDevice(e: React.SyntheticEvent): void {
    const result = handleUpdate(e)
    if (!result) {
      console.error('Failed to update device')
    }
  }

  const children: React.ReactNode[] = []

  if (deviceSerialNumbers) {
    for (let i = 0; i < deviceSerialNumbers.length; i++) {
      const dsn: string = deviceSerialNumbers[i]
      children.push(
        <ListItem key={dsn}>
          <AddableTextField
            label='Device Serial Number'
            value={dsn}
            key={dsn}
            data-dsn={dsn}
            onChange={updateDevice}
          />
          <DeleteIcon className='uva-clickable' data-dsn={dsn} onClick={deleteDevice} />
        </ListItem>,
      )
    }
  }

  children.push(<ListItem key={'new-dsn'}>{newDeviceField}</ListItem>)

  return (
    <>
      <List className='uva-text-field-list'>{children}</List>
      <div className='uva-form-actions'>
        <Button variant='contained' onClick={addDevice}>
          Add Device
        </Button>
      </div>
    </>
  )
}

// eslint-disable-next-line func-style
const RoomEditDialog: React.FC<RoomEditDialogProps> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onBlur' })

  const appContext = React.useContext(AppContext)

  const [state, setState] = React.useState<Partial<UvaRoom>>({
    id: props.initialRoomData?.id,
    name: props.initialRoomData?.name,
    typicalActivity: props.initialRoomData?.typicalActivity,
    volume: props.initialRoomData?.volume,
    maxOccupancy: props.initialRoomData?.maxOccupancy,
    airHandlerFlowRate: props.initialRoomData?.airHandlerFlowRate,
    typicalDuration: props.initialRoomData?.typicalDuration,
    typicalOccupancyInterval: props.initialRoomData?.typicalOccupancyInterval,
    hvacOnTimePercentage: props.initialRoomData?.hvacOnTimePercentage,
    airHandlerPathogenRemovalEfficiency: props.initialRoomData?.airHandlerPathogenRemovalEfficiency,
    deviceSerialNumbers: props.initialRoomData?.deviceSerialNumbers,
    thirdPartyDataSource: props.initialRoomData?.thirdPartyDataSource,
  })

  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  function addDevice(): boolean {
    const newDeviceField = document.getElementById('UvaNewDevice') as HTMLInputElement
    const MIN_DEVICE_LENGTH = 4
    if (newDeviceField?.value && newDeviceField.value.length >= MIN_DEVICE_LENGTH) {
      const serialNumber = newDeviceField.value
      let devices: string[]
      if (state.deviceSerialNumbers) {
        devices = state.deviceSerialNumbers
        devices.push(serialNumber)
      } else {
        devices = [serialNumber]
      }
      newDeviceField.value = ''
      setState({
        ...state,
        deviceSerialNumbers: devices,
      })
    } else {
      return false
    }
    return true
  }

  // FIXME: any
  function removeDevice(e: any): boolean {
    const deleteIcon = e.target.parentElement
    const serialNumber = deleteIcon.dataset.dsn
    if (serialNumber && state.deviceSerialNumbers && state.deviceSerialNumbers.length > 0) {
      const devices = state.deviceSerialNumbers
      const idx = devices.indexOf(serialNumber)
      devices.splice(idx, 1)
      setState({
        ...state,
        deviceSerialNumbers: devices,
      })
      return true
    }
    return false
  }

  function updateDevice(e: any): boolean {
    const fieldContainer = e.target.parentElement.parentElement
    const serialNumber = fieldContainer.dataset.dsn
    if (serialNumber && state.deviceSerialNumbers && state.deviceSerialNumbers?.length > 0) {
      const devices = state.deviceSerialNumbers
      const idx = devices.indexOf(serialNumber)
      devices[idx] = e.target.value
      setState({
        ...state,
        deviceSerialNumbers: devices,
      })
      return true
    }
    return false
  }

  async function submitWrapper(data): Promise<void> {
    const submitObj: UvaRoom = {
      id: state.id,
      name: data.redNameField,
      volume: parseInt(data.redVolumeField),
      maxOccupancy: parseInt(data.redMaxOccupancyField),
      typicalActivity: data.redActivityField,
      typicalDuration: parseInt(data.redTypicalDurationField),
      typicalOccupancyInterval: parseInt(data.redTypicalIntervalField),
      airHandlerFlowRate: parseFloat(data.redAhFlowRateField),
      airHandlerPathogenRemovalEfficiency: parseFloat(data.redAhPathogenRemovalEfficiencyField),
      hvacOnTimePercentage: parseFloat(data.redAhHvacOnTimePercentageField),
      deviceSerialNumbers: state.deviceSerialNumbers,
    }

    if (submitObj && state.thirdPartyDataSource?.kontakt.roomId) {
      submitObj.thirdPartyDataSource = {
        kontakt: {
          roomId: state.thirdPartyDataSource?.kontakt.roomId,
        },
      }
    }
    return await props.onClose(submitObj)
  }

  function upsertRoom(data): void {
    setIsLoading(true)
    submitWrapper(data).then(() => {
      setIsLoading(false)
    })
  }

  return (
    <Dialog open={true} onClose={() => props.onClose()} id='UvaRoomEditDialog'>
      <DialogTitle>Room</DialogTitle>
      <form onSubmit={handleSubmit(upsertRoom)}>
        <DialogContent>
          <fieldset className='uva-fieldset'>
            <legend>General Characteristics</legend>
            <TextField
              id='RedNameField'
              label='Name'
              type='text'
              variant='filled'
              fullWidth
              error={errors.redNameField ? true : false}
              helperText={errors.redNameField ? errors.redNameField.message?.toString() : ''}
              defaultValue={state.name}
              {...register('redNameField', {
                required: { value: true, message: 'Name is required' },
                minLength: {
                  value: 6,
                  message: 'Name must be unique and at least 6 characters long',
                },
              })}
              onChange={(event) =>
                setState({
                  ...state,
                  name: event.target.value,
                })
              }
              className='uva-form-control'
            />
            <TextField
              id='RedVolumeField'
              label='Volume'
              type='text'
              variant='filled'
              error={errors.redVolumeField ? true : false}
              helperText={errors.redVolumeField ? errors.redVolumeField.message?.toString() : ''}
              defaultValue={state.volume?.toString()}
              {...register('redVolumeField', {
                required: { value: true, message: 'Volume is required' },
                min: { value: 1, message: 'Volume must be a positive number' },
                pattern: {
                  value: /^[1-9][0-9]*$/,
                  message: 'Invalid value for Volume',
                },
              })}
              onChange={(event) =>
                setState({
                  ...state,
                  volume: parseFloat(event.target.value),
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    ft<sup>3</sup>
                  </InputAdornment>
                ),
              }}
              className='uva-form-control uva-form-control-inline'
            />
            <TextField
              id='RedMaxOccupancyField'
              label='Max Occupancy'
              type='text'
              variant='filled'
              error={errors.redMaxOccupancyField ? true : false}
              helperText={
                errors.redMaxOccupancyField ? errors.redMaxOccupancyField.message?.toString() : ''
              }
              defaultValue={state.maxOccupancy?.toString()}
              {...register('redMaxOccupancyField', {
                required: { value: true, message: 'Max Occupancy is required' },
                min: { value: 1, message: 'Max Occupancy must be a positive number' },
                pattern: {
                  value: /^[1-9][0-9]*$/,
                  message: 'Invalid value for Max Occupancy',
                },
              })}
              onChange={(event) =>
                setState({
                  ...state,
                  maxOccupancy: parseInt(event.target.value),
                })
              }
              className='uva-form-control uva-form-control-inline'
            />
          </fieldset>
          <fieldset className='uva-fieldset'>
            <legend>Typical Activity</legend>
            <FormControl variant='filled' fullWidth className='uva-form-control'>
              <InputLabel id='activity-label' error={errors.redActivityField ? true : false}>
                Activity
              </InputLabel>
              <Select
                id='RedActivityField'
                labelId='activity-label'
                variant='filled'
                fullWidth
                key='redActivityField'
                error={errors.redActivityField ? true : false}
                label='Activity'
                {...register('redActivityField', {
                  required: { value: true, message: 'Activity is required' },
                })}
                onChange={(event) =>
                  setState({
                    ...state,
                    typicalActivity: event.target.value,
                  })
                }
                value={state.typicalActivity || ''}
              >
                <MenuItem value='' key='RoomActivity-Unselected'>
                  Select One
                </MenuItem>
                {appContext?.activities.map((activity) => (
                  <MenuItem value={activity.id} key={`RoomActivity${activity.id}`}>
                    {activity.primary} - {activity.secondary}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={errors.redActivityField ? true : false}>
                {errors.redActivityField ? errors.redActivityField.message?.toString() : ''}
              </FormHelperText>
            </FormControl>
            <TextField
              id='RedTypicalDurationField'
              label='Duration'
              type='text'
              variant='filled'
              error={errors.redTypicalDurationField ? true : false}
              helperText={
                errors.redTypicalDurationField
                  ? errors.redTypicalDurationField.message?.toString()
                  : ''
              }
              defaultValue={state.typicalDuration?.toString()}
              {...register('redTypicalDurationField', {
                required: { value: true, message: 'Typical Duration is required' },
                min: { value: 0, message: 'Typical Duration must be a non-negative number' },
                pattern: {
                  value: /^[1-9][0-9]*$/,
                  message: 'Invalid value for Typical Duration',
                },
              })}
              onChange={(event) =>
                setState({
                  ...state,
                  typicalDuration: parseInt(event.target.value),
                })
              }
              InputProps={{
                endAdornment: <InputAdornment position='end'>min</InputAdornment>,
              }}
              className='uva-form-control uva-form-control-inline'
            />
            <TextField
              id='RedTypicalIntervalField'
              label='Interval'
              type='text'
              variant='filled'
              error={errors.redTypicalIntervalField ? true : false}
              helperText={
                errors.redTypicalIntervalField
                  ? errors.redTypicalIntervalField.message?.toString()
                  : ''
              }
              defaultValue={state.typicalOccupancyInterval?.toString()}
              {...register('redTypicalIntervalField', {
                required: {
                  value: true,
                  message: 'Typical Interval is required',
                },
                min: {
                  value: 1,
                  message: 'Typical Interval must be a positive integer',
                },
                pattern: {
                  value: /^[1-9][0-9]*$/,
                  message: 'Invalid value for Typical Interval',
                },
              })}
              onChange={(event) =>
                setState({
                  ...state,
                  typicalOccupancyInterval: parseInt(event.target.value),
                })
              }
              InputProps={{
                endAdornment: <InputAdornment position='end'>min</InputAdornment>,
              }}
              className='uva-form-control uva-form-control-inline'
            />
          </fieldset>
          <fieldset className='uva-fieldset'>
            <legend>Air Handler</legend>
            <TextField
              id='RedAhFlowRateField'
              label='Flow Rate'
              type='text'
              variant='filled'
              error={errors.redAhFlowRateField ? true : false}
              helperText={
                errors.redAhFlowRateField ? errors.redAhFlowRateField.message?.toString() : ''
              }
              defaultValue={state.airHandlerFlowRate?.toString()}
              {...register('redAhFlowRateField', {
                required: {
                  value: true,
                  message: 'Air Handler Flow Rate is required',
                },
                min: {
                  value: 0,
                  message: 'Air Handler Flow Rate must be a non-negative number',
                },
                pattern: {
                  value: /^([0-9]*[.])?[0-9]+$/,
                  message: 'Invalid value for Air Handler Flow Rate',
                },
              })}
              onChange={(event) =>
                setState({
                  ...state,
                  airHandlerFlowRate: parseInt(event.target.value),
                })
              }
              InputProps={{
                endAdornment: <InputAdornment position='end'>cfm</InputAdornment>,
              }}
              className='uva-form-control uva-form-control-inline  uva-form-control-inline-sm'
            />
            <TextField
              id='RedAhPathogenRemovalEfficiencyField'
              label='Efficiency %'
              type='text'
              variant='filled'
              error={errors.redAhPathogenRemovalEfficiencyField ? true : false}
              helperText={
                errors.redAhPathogenRemovalEfficiencyField
                  ? errors.redAhPathogenRemovalEfficiencyField.message?.toString()
                  : ''
              }
              defaultValue={state.airHandlerPathogenRemovalEfficiency?.toString()}
              {...register('redAhPathogenRemovalEfficiencyField', {
                required: {
                  value: true,
                  message: 'Pathogen Removal Efficiency is required',
                },
                min: {
                  value: 0,
                  message: 'Pathogen Removal Efficiency must be a non-negative number',
                },
                max: {
                  value: 100,
                  message: 'Pathogen Removal Efficiency must not be greater than 100',
                },
                pattern: {
                  value: /^([0-9]*[.])?[0-9]+$/,
                  message: 'Invalid value for Air Handler Flow Rate',
                },
              })}
              onChange={(event) =>
                setState({
                  ...state,
                  airHandlerPathogenRemovalEfficiency: parseFloat(event.target.value),
                })
              }
              InputProps={{
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              }}
              className='uva-form-control  uva-form-control-inline  uva-form-control-inline-sm'
            />
            <TextField
              id='RedAhHvacOnTimePercentageField'
              label='On Time %'
              type='text'
              variant='filled'
              error={errors.redAhHvacOnTimePercentageField ? true : false}
              helperText={
                errors.redAhHvacOnTimePercentageField
                  ? errors.redAhHvacOnTimePercentageField.message?.toString()
                  : ''
              }
              defaultValue={state.hvacOnTimePercentage?.toString()}
              {...register('redAhHvacOnTimePercentageField', {
                required: {
                  value: true,
                  message: 'HVAC On-Time % is required',
                },
                min: {
                  value: 0,
                  message: 'HVAC On-Time % must be a non-negative number',
                },
                max: {
                  value: 100,
                  message: 'HVAC On-Time % must not be greater than 100',
                },
                pattern: {
                  value: /^([0-9]*[.])?[0-9]+$/,
                  message: 'Invalid value for HVAC On-Time %',
                },
              })}
              onChange={(event) =>
                setState({
                  ...state,
                  hvacOnTimePercentage: parseFloat(event.target.value),
                })
              }
              InputProps={{
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              }}
              className='uva-form-control uva-form-control-inline uva-form-control-inline-sm'
            />
          </fieldset>
          <fieldset>
            <legend>Devices</legend>
            <AddableTextFieldList
              deviceSerialNumbers={state.deviceSerialNumbers}
              handleAdd={addDevice}
              handleDelete={removeDevice}
              handleUpdate={updateDevice}
            />
          </fieldset>
          <fieldset className='uva-fieldset'>
            <legend>Third-Party Data Sources</legend>
            <TextField
              id='RedKontaktRoomId'
              label='Kontakt Room ID'
              type='text'
              variant='filled'
              fullWidth
              error={errors.redKontaktRoomId ? true : false}
              helperText={
                errors.redKontaktRoomId ? errors.redKontaktRoomId.message?.toString() : ''
              }
              defaultValue={state.thirdPartyDataSource?.kontakt.roomId.toString()}
              {...register('redKontaktRoomId')}
              onChange={(event) =>
                setState({
                  ...state,
                  thirdPartyDataSource: {
                    kontakt: {
                      roomId: event.target.value,
                    },
                  },
                })
              }
              className='uva-form-control'
            />
          </fieldset>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='secondary' onClick={() => props.onClose()}>
            Cancel
          </Button>
          <LoadingButton loading={isLoading} variant='contained' type='submit' disabled={!isValid}>
            {props.initialRoomData?.id ? 'Save' : 'Create'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default RoomEditDialog
