/* eslint-disable no-magic-numbers */
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import { EnvironmentalMetrics } from '../../../../services/api.models'
import type { HierarchicalRoomType } from '../room-selector/room-selector.component'
import { TrendingDown, TrendingUp } from '@mui/icons-material'
export interface LocationStatsTableProps {
  environmentalMetrics: EnvironmentalMetrics | null
  selectedRoom: HierarchicalRoomType
}

// hack this with percent shift so that it looks right given uncalibrated sensor
function convertFromCToF(degreesC: number, shiftValue = 0) {
  return (degreesC * 9) / 5 + 32 - shiftValue
}

function getTrendIcon(trend: number) {
  if (trend === 1) {
    return <TrendingUp color='error' />
  } else if (trend === -1) {
    return <TrendingDown color='success' />
  } else {
    return
  }
}

//eslint-disable-next-line func-style
const LocationStatsTable: React.FC<LocationStatsTableProps> = (props) => {
  const cardTitle = `${props.selectedRoom.name} - Current Conditions`

  if (props.selectedRoom && props.selectedRoom.id.includes(',')) {
    return (
      <Card id='UvaLocationStatsTable'>
        {/* <CardHeader title={cardTitle} /> */}
        <CardContent>
          <Typography sx={{ textAlign: 'center' }} variant='h5' color='text.secondary'>
            Select a location to view current conditions
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return !props.environmentalMetrics || !props.selectedRoom ? (
    <Skeleton className='uva-skeleton-panel' variant='rectangular' />
  ) : (
    <Card id='UvaLocationStatsTable'>
      <CardHeader title={cardTitle} />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Trend</TableCell>
              <TableCell>Metric</TableCell>
              <TableCell align='right'>Current Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {!props.environmentalMetrics?.pssq ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  getTrendIcon(props.environmentalMetrics.pssq.trend)
                )}
              </TableCell>
              <TableCell>PSSQ</TableCell>
              <TableCell align='right'>
                {!props.environmentalMetrics?.pssq ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  <>
                    <Typography variant='h6'>{props.environmentalMetrics.pssq.mean.toFixed(4)}</Typography>
                    <Typography variant='body2' color='text.secondary'>quanta/cm³</Typography>
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {!props.environmentalMetrics?.ventilationRate ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  getTrendIcon(props.environmentalMetrics.ventilationRate.trend)
                )}
              </TableCell>
              <TableCell>Ventilation Rate</TableCell>
              <TableCell align='right'>
                {!props.environmentalMetrics?.ventilationRate ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  <>
                    <Typography variant='h6'>{props.environmentalMetrics.ventilationRate.mean.toFixed(1)}</Typography>
                    <Typography variant='body2' color='text.secondary'>cfm</Typography>
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {!props.environmentalMetrics?.ventilationRate ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  getTrendIcon(props.environmentalMetrics.ventilationRate.trend)
                )}
              </TableCell>
              <TableCell>
                CO<sub>2</sub>
              </TableCell>
              <TableCell align='right'>
                {!props.environmentalMetrics?.co2 ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  <>
                    <Typography variant='h6'>{props.environmentalMetrics.co2.mean.toFixed(1)}</Typography>
                    <Typography variant='body2' color='text.secondary'>ppm</Typography>
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {!props.environmentalMetrics?.temp ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  getTrendIcon(props.environmentalMetrics.temp.trend)
                )}
              </TableCell>
              <TableCell>Temp</TableCell>
              <TableCell align='right'>
                {!props.environmentalMetrics?.temp ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  // eslint-disable-next-line no-magic-numbers
                  <>
                    <Typography variant='h6'>{convertFromCToF(props.environmentalMetrics.temp.mean).toFixed(1)}</Typography>
                    <Typography variant='body2' color='text.secondary'>&#8457;</Typography>
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {!props.environmentalMetrics?.humidity ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  getTrendIcon(props.environmentalMetrics.humidity.trend)
                )}
              </TableCell>
              <TableCell>Humidity</TableCell>
              <TableCell align='right'>
                {!props.environmentalMetrics?.humidity ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  <>
                    <Typography variant='h6'>{props.environmentalMetrics.humidity.mean.toFixed(1)}</Typography>
                    <Typography variant='body2' color='text.secondary'>%</Typography>
                  </>
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                {!props.environmentalMetrics?.voc ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  getTrendIcon(props.environmentalMetrics.voc.trend)
                )}
              </TableCell>
              <TableCell>VOC</TableCell>
              <TableCell align='right'>
                {!props.environmentalMetrics?.voc ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  <>
                    <Typography variant='h6'>{props.environmentalMetrics.voc.mean.toFixed(1)}</Typography>
                    <Typography variant='body2' color='text.secondary'>mg/m<sup>3</sup></Typography>
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {!props.environmentalMetrics?.iaq ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  getTrendIcon(props.environmentalMetrics.iaq.trend)
                )}
              </TableCell>
              <TableCell>IAQ</TableCell>
              <TableCell align='right'>
                {!props.environmentalMetrics?.iaq ? (
                  <Skeleton variant='rectangular' className='uva-skeleton' />
                ) : (
                  <>
                    <Typography variant='h6'>{props.environmentalMetrics.iaq.mean.toFixed(1)}</Typography>
                    <Typography variant='body2' color='text.secondary'>ppm</Typography>
                  </>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default LocationStatsTable
