import React, { DragEvent, MouseEvent, useContext, useState } from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Alert,
  AlertTitle,
} from '@mui/material'
import {
  UvaScenarioEvent,
  UvaScenario,
  UvaFixedSimulationParams,
} from '../../../../../app/uva-interfaces'

import { AppContext } from '../../../../../app/App.context'
import ScenarioEventForm from '../scenario-event-form/scenario-event-form.component'

import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import DeleteIcon from '@mui/icons-material/Delete'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import PeopleIcon from '@mui/icons-material/People'
import AvTimerIcon from '@mui/icons-material/AvTimer'
import { UvaScenarioEventFormMode } from '../../../../../services/api.models'

const MAX_ACTIVITY_ID = 100000000
export interface ScenarioBuilderTableProps {
  activeScenario: UvaScenario
  fixedParams: UvaFixedSimulationParams | undefined
  getSavedScenarios: () => UvaScenario[]
  getScenarioById: (scenarioId: string) => UvaScenario | undefined
  getScenarioEventById: (scenarioEventId: string) => UvaScenarioEvent | undefined
  saveScenario: (scenario: UvaScenario) => void
  runSimulation: (scenario: UvaScenario) => Promise<void>
  addScenarioEvent: (scenarioEvent: UvaScenarioEvent) => boolean
  updateScenarioEvent: (scenarioEvent: UvaScenarioEvent) => boolean
  deleteScenarioEvent: (e: MouseEvent<SVGSVGElement>) => boolean
}

// eslint-disable-next-line func-style
const ScenarioBuilderTable: React.FC<ScenarioBuilderTableProps> = (props) => {
  const appContext = useContext(AppContext)
  const simulationRoom = appContext.rooms.find((room) => room.id === props.fixedParams?.roomId)
  const [isPlayButtonActive, setIsPlayButtonActive] = useState<boolean>(
    props.activeScenario.scenarioEvents && props.activeScenario.scenarioEvents?.length > 0
      ? true
      : false,
  )
  const [addEventSuccess, setAddEventSuccess] = useState<boolean | undefined>(undefined)
  const [, setEditEventSuccess] = useState<boolean | undefined>(undefined)
  const [deleteEventSuccess, setDeleteEventSuccess] = useState<boolean | undefined>(undefined)
  const [activeScenarioEvent, setActiveScenarioEvent] = useState<UvaScenarioEvent | undefined>(
    undefined,
  )
  const [showEventForm, setShowEventForm] = useState<boolean>(false)
  const [eventFormMode, setEventFormMode] = useState<UvaScenarioEventFormMode>('Add')
  const [showControls, setShowControls] = useState<boolean>(true)
  const [activeScenario] = useState<UvaScenario | undefined>(props.activeScenario)
  const [scenarioEventToDrag, setScenarioEventToDrag] = useState<UvaScenarioEvent | undefined>(
    undefined,
  )
  const [, setScenarioEventToDrop] = useState<UvaScenarioEvent | undefined>(undefined)

  function toggleEventForm(): void {
    setShowEventForm(!showEventForm)
  }

  async function playHandler() {
    setIsPlayButtonActive(false)
    if (
      activeScenario &&
      activeScenario.scenarioEvents &&
      activeScenario.scenarioEvents.length > 0
    ) {
      props.runSimulation(activeScenario)
    }
    setIsPlayButtonActive(true)
  }

  function addScenarioEvent(scenarioEvent: UvaScenarioEvent): boolean {
    const results = props.addScenarioEvent(scenarioEvent)
    setAddEventSuccess(results)
    setEditEventSuccess(undefined)
    setShowEventForm(!results)
    setShowControls(results)
    setIsPlayButtonActive(results)
    return results
  }

  function updateScenarioEvent(scenarioEvent: UvaScenarioEvent): boolean {
    const results = props.updateScenarioEvent(scenarioEvent)
    setEditEventSuccess(results)
    setDeleteEventSuccess(undefined)
    setAddEventSuccess(undefined)
    setShowEventForm(!results)
    setShowControls(results)
    return results
  }

  function deleteScenarioEvent(event: MouseEvent<SVGSVGElement>): boolean {
    console.log('deleteScenarioEvent')
    const results = props.deleteScenarioEvent(event)
    setAddEventSuccess(undefined)
    setEditEventSuccess(undefined)
    setDeleteEventSuccess(results)
    setShowControls(true)
    setIsPlayButtonActive(
      props.activeScenario.scenarioEvents && props.activeScenario.scenarioEvents?.length > 0
        ? true
        : false,
    )
    return results
  }

  function addHandler(): void {
    setShowControls(false)
    setAddEventSuccess(undefined)
    setEditEventSuccess(undefined)
    setEventFormMode('Add')
    setShowEventForm(true)
  }

  function editHandler(event: MouseEvent<HTMLTableCellElement>): void {
    setShowControls(false)
    setAddEventSuccess(undefined)
    setEditEventSuccess(undefined)
    setEventFormMode('Edit')

    const t = event.target as HTMLTableCellElement
    const p = t.parentElement as HTMLTableRowElement
    const scenarioEventId = p.getAttribute('data-scenario-event-id')
    if (scenarioEventId) {
      const scenarioEvent = props.getScenarioEventById(scenarioEventId)
      if (scenarioEvent) {
        setActiveScenarioEvent(scenarioEvent)
      } else {
        throw new Error('There is no scenario event to edit')
      }
    }
    setShowEventForm(true)
  }

  // https://www.kindacode.com/article/react-typescript-drag-and-drop/
  function dragStartHandler(event: DragEvent<HTMLDivElement>) {
    const t = event.target as HTMLTableRowElement
    const scenarioEventId = t.getAttribute('data-scenario-event-id')
    console.log(`dragging scenarioEvent ${scenarioEventId}`)
    if (props.activeScenario?.scenarioEvents) {
      const scenarioEventIndex = props.activeScenario?.scenarioEvents.findIndex(
        (existingScenarioEvent: UvaScenarioEvent) =>
          existingScenarioEvent?.id?.toString() === scenarioEventId?.toString(),
      )
      setScenarioEventToDrag(props.activeScenario.scenarioEvents[scenarioEventIndex])
    } else {
      throw new Error('There are no current scenario events')
    }
  }

  // This function will be triggered when dropping
  function dropHandler(event: React.DragEvent<HTMLDivElement>) {
    const t = event.target as HTMLTableCellElement
    const p = t.parentElement as HTMLTableRowElement
    const scenarioEventId = p.getAttribute('data-scenario-event-id')
    if (props.activeScenario && props.activeScenario.scenarioEvents && scenarioEventId) {
      if (scenarioEventToDrag) {
        const scenarioDropEventIndex = props.activeScenario.scenarioEvents.findIndex(
          (existingScenarioEvent: UvaScenarioEvent) =>
            existingScenarioEvent?.id?.toString() === scenarioEventId?.toString(),
        )
        const scenarioDragEventIndex = props.activeScenario.scenarioEvents.findIndex(
          (existingScenarioEvent: UvaScenarioEvent) =>
            existingScenarioEvent?.id?.toString() === scenarioEventToDrag.id?.toString(),
        )
        const scenarioEventToDrop = props.activeScenario.scenarioEvents[scenarioDropEventIndex]
        const scenarioEventToDragOrder = scenarioEventToDrag.order
        const scenarioEventToDropOrder = scenarioEventToDrop.order

        scenarioEventToDrag.order = scenarioEventToDropOrder
        scenarioEventToDrop.order = scenarioEventToDragOrder

        props.activeScenario.scenarioEvents[scenarioDragEventIndex] = scenarioEventToDrag
        props.activeScenario.scenarioEvents[scenarioDropEventIndex] = scenarioEventToDrop

        props.activeScenario.scenarioEvents.sort((a: UvaScenarioEvent, b: UvaScenarioEvent) => {
          return a.order - b.order
        })

        // This is unnecessary but forces to table to redraw by changing state
        setScenarioEventToDrop(scenarioEventToDrop)
      } else {
        throw new Error('There are no current scenario events')
      }
    }
  }

  // This makes the third box become droppable
  function allowDrop(event: React.DragEvent<HTMLTableRowElement>) {
    event.preventDefault()
  }

  return (
    <>
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <PeopleIcon />
            </TableCell>
            <TableCell>Activity</TableCell>
            <TableCell>
              <AvTimerIcon />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.activeScenario &&
            props.activeScenario.scenarioEvents &&
            props.activeScenario.scenarioEvents.length > 0 &&
            props.activeScenario.scenarioEvents.map(
              ({ id, activityId, order, occupants, duration }: UvaScenarioEvent) => {
                // Unsure about whether to handle grabbing the name here just for display or better to make it part of the model.
                const activity = appContext.activities.find((a) => a.id === activityId)
                let activityLabel = activityId
                if (activity) {
                  activityLabel = `${activity.primary.toLocaleUpperCase()} - ${
                    activity.secondary
                  } (${activity.enhancementRate})`
                }
                if (id === undefined) {
                  id = Math.floor(Math.random() * MAX_ACTIVITY_ID)
                }

                return (
                  <TableRow
                    key={`ScenarioEvent-${id}`}
                    onDragOver={allowDrop}
                    onDrop={dropHandler}
                    onDragStart={(event) => dragStartHandler(event)}
                    draggable={true}
                    data-scenario-event-id={id}
                    data-event-order={order}
                  >
                    <TableCell>
                      <DragHandleIcon className='uva-drag-handle'></DragHandleIcon>
                    </TableCell>
                    <TableCell onClick={editHandler} className='uva-clickable'>
                      {occupants}
                    </TableCell>
                    <TableCell onClick={editHandler} className='uva-clickable'>
                      {activityLabel}
                    </TableCell>
                    <TableCell onClick={editHandler} className='uva-clickable'>
                      {duration}
                    </TableCell>
                    <TableCell>
                      <DeleteIcon
                        className='uva-delete-icon'
                        onClick={deleteScenarioEvent}
                      ></DeleteIcon>
                    </TableCell>
                  </TableRow>
                )
              },
            )}
        </TableBody>
      </Table>
      {showControls && (
        <div className='uva-form-actions'>
          <Button variant='contained' disabled={!isPlayButtonActive}>
            <PlayArrowIcon onClick={playHandler} />
          </Button>
          <Button onClick={() => addHandler()} variant='contained' className='uva-clickable'>
            Add Event
          </Button>
        </div>
      )}
      {addEventSuccess === false && (
        <Alert
          variant='outlined'
          severity='error'
          className='uva-alert'
          onClose={() => console.log('close')}
        >
          <AlertTitle>Failed to Add Event</AlertTitle>
          <p>There was an error adding the event</p>
        </Alert>
      )}
      {deleteEventSuccess === false && (
        <Alert
          variant='outlined'
          severity='error'
          className='uva-alert'
          onClose={() => console.log('close')}
        >
          <AlertTitle>Failed to Delete Event</AlertTitle>
          <p>There was an error deleting the event</p>
        </Alert>
      )}
      {showEventForm && (
        <ScenarioEventForm
          formMode={eventFormMode}
          activeScenario={props.activeScenario}
          maxOccupants={simulationRoom?.maxOccupancy}
          activeScenarioEvent={activeScenarioEvent}
          closeEventForm={toggleEventForm}
          addScenarioEvent={addScenarioEvent}
          updateScenarioEvent={updateScenarioEvent}
        />
      )}
    </>
  )
}

export default ScenarioBuilderTable
