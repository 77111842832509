import React from 'react'

export interface AppFooterProps {}

// eslint-disable-next-line func-style
const AppFooter: React.FC<AppFooterProps> = () => {
  const year = new Date().getFullYear()
  return (
    <footer id='UvaAppFooter' className='uva-app-footer'>
      Copyright {year.toString()} UV Angel. All Rights Reserved.
    </footer>
  )
}

export default AppFooter
