/* eslint-disable indent */ // circular problem with prettier/eslint on "map" indentation
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Card, Grid, InputLabel, Skeleton, Slider, Tab, Paper } from '@mui/material'
import { format } from 'date-fns'
import * as FileSaver from 'file-saver'
import React from 'react'
import { AppContext } from '../../app/App.context'
import {
  CalcCleanAirDeviceModeOutput,
  CalcCleanAirDeviceModeParams,
  UvaFixedSimulationParams,
  UvaScenario,
  UvaScenarioEvent,
  UvaSimulationParams,
  UvaSimulationMode,
} from '../../app/uva-interfaces'
import SimulationResultsGraph from '../../components/simulation-results-graph/simulation-results-graph.component'
import SimulationResultsTable from '../../components/simulation-results-table/simulation-results-table.component'
import { UvaPathogen, UvaRoom } from '../../services/api.models'
import PssqClient from '../../services/pssq.service'
import FixedParamsPanel from './components/fixed-params-panel/fixed-params-panel.component'
import FixedParamsTable from './components/fixed-params-table/fixed-params-table.component'
import { PssqSimulatorFilterPanelProps } from './components/pssq-simulator-filter-panel/pssq-simulator-filter-panel.component'
import ScenarioBuilder from './components/scenario-builder/scenario-builder.component'
import VariableParamsPanel from './components/variable-params-panel/variable-params-panel.component'

type SimulationRecord = {
  // Request params
  input: {
    occupancy: number
    infectionRate: number
    activityId: string
    // not needed as we have activityId but it eases processing
    activityEnhancementRate: number
  }
  output: Awaited<ReturnType<typeof PssqClient.calcCleanAirDeviceMode>>
}

const DEVICE_ID = 'ffffffffffff'
const MAX_DEVICE_CADR = 115
const DEFAULT_MAX_QUANTA = 3
const DEFAULT_QUANTA_MULTIPLIER = 1.5
const DEFAULT_MAX_OCCUPANCY = 20

const SPEED_STEPS = 100
const MIN_SIMULATION_DELAY = 50
const MAX_SIMULATION_DELAY = 60000
const DEFAULT_SIMULATION_DELAY = 500
const SPEED_MARKS = [
  {
    value: 1,
    label: formatSimulationSpeed(MIN_SIMULATION_DELAY),
  },
  {
    value: SPEED_STEPS,
    label: formatSimulationSpeed(MAX_SIMULATION_DELAY),
  },
]

function formatSimulationSpeed(speed: number) {
  const lowerSpeedThreshold = 1000
  const upperSpeedThreshold = 60000

  if (speed < lowerSpeedThreshold) {
    return `${speed.toFixed(0)}ms`
  } else if (speed < upperSpeedThreshold) {
    return `${(speed / lowerSpeedThreshold).toFixed(0)}s`
  } else {
    return `${(speed / upperSpeedThreshold).toFixed(0)}min`
  }
}

/**
 * Scales a value from 1..100 to 50..60000
 */
function scaleUpSpeed(value: number) {
  const logMin = Math.log(MIN_SIMULATION_DELAY)
  const logMax = Math.log(MAX_SIMULATION_DELAY)
  const logStep = (logMax - logMin) / 100
  return Math.exp(logMin + value * logStep)
}

/**
 * Scales value from 50..60000 to 1..100
 */
function scaleDownSpeed(value: number) {
  const logMin = Math.log(MIN_SIMULATION_DELAY)
  const logMax = Math.log(MAX_SIMULATION_DELAY)

  const logStep = (logMax - logMin) / 100
  return (Math.log(value) - logMin) / logStep
}

interface UvaSimulationState {
  loading: boolean
  simulationMode: UvaSimulationMode
  fixedParams: UvaFixedSimulationParams | undefined
}
/* eslint-disable-next-line func-style*/
const PssqSimulatorPage: React.FC<unknown> = () => {
  const appContext = React.useContext(AppContext)
  const defaultSimulationMode: UvaSimulationMode = 'InteractiveSimulation'
  let simulationMode: UvaSimulationMode | undefined
  const savedSimulationMode = localStorage.getItem(appContext.simulationModeKey)
  if (savedSimulationMode) {
    simulationMode = savedSimulationMode as UvaSimulationMode
  } else {
    simulationMode = defaultSimulationMode
  }

  const [state, setState] = React.useState<UvaSimulationState>({
    loading: false,
    simulationMode: simulationMode as UvaSimulationMode,
    fixedParams: {
      roomId: '',
      pathogenId: '',
      maxQuantaDensity: 0,
      infectionRate: 0,
    },
  })

  function validateFixedParams(): boolean {
    // Check for saved values
    const savedFixedParams = localStorage.getItem(appContext.fixedSimulationParamsKey)
    if (!state.fixedParams && savedFixedParams) {
      state.fixedParams = JSON.parse(savedFixedParams)
    }
    if (
      state.fixedParams &&
      state.fixedParams.roomId &&
      state.fixedParams.infectionRate &&
      state.fixedParams.pathogenId &&
      state.fixedParams.maxQuantaDensity
    ) {
      return true
    }
    return false
  }

  const [fixedParamsPanelVisible, setFixedParamsPanelVisibility] = React.useState<boolean>(
    validateFixedParams(),
  )

  function updateFixedParams(fixedParams: UvaFixedSimulationParams): void {
    setState({
      ...state,
      fixedParams,
    })
    setFixedParamsPanelVisibility(false)
    localStorage.setItem(appContext.fixedSimulationParamsKey, JSON.stringify(fixedParams))
  }

  const [variableParams, setVariableParams] = React.useState<{
    activityId: string
    occupancy: number
    simulationSpeed: number
    nextSimulationExecutionTime: number
    nextFetchTimeoutId: number
  }>({
    activityId: appContext.activities[0]?.id,
    occupancy: 0,
    simulationSpeed: DEFAULT_SIMULATION_DELAY,
    nextSimulationExecutionTime: 0,
    nextFetchTimeoutId: 0,
  })
  const variableParamsRef = React.useRef<typeof variableParams>(variableParams)

  function updateVariableParams(data: typeof variableParams) {
    setVariableParams(data)
    variableParamsRef.current = data
  }

  React.useEffect(() => {
    const rawSimulationMode = localStorage.getItem(
      appContext.simulationModeKey,
    ) as UvaSimulationMode

    const tempSimulationParams: Partial<UvaSimulationState> = {}

    if (rawSimulationMode) {
      tempSimulationParams.simulationMode = rawSimulationMode
    } else {
      tempSimulationParams.simulationMode = defaultSimulationMode
      localStorage.setItem(appContext.simulationModeKey, state.simulationMode)
    }

    const storedSpeed = localStorage.getItem(appContext.simulationSpeedKey)
    if (
      storedSpeed &&
      !Number.isNaN(Number(storedSpeed)) &&
      variableParamsRef.current.simulationSpeed !== Number(storedSpeed)
    ) {
      updateVariableParams({ ...variableParamsRef.current, simulationSpeed: Number(storedSpeed) })
    }

    const rawFixedParams = localStorage.getItem(appContext.fixedSimulationParamsKey)
    let fixedParams: UvaFixedSimulationParams | undefined = undefined
    if (rawFixedParams) {
      fixedParams = JSON.parse(rawFixedParams)
    }
    if (fixedParams) {
      setState({
        ...state,
        fixedParams,
      })
      setFixedParamsPanelVisibility(false)
    } else {
      setFixedParamsPanelVisibility(true)
    }
    setActiveTab(tempSimulationParams.simulationMode as UvaSimulationMode)
  }, [])

  const [currSimData, setCurrSimData] = React.useState<SimulationRecord[]>([])
  const currSimDataRef = React.useRef<SimulationRecord[]>(currSimData)
  const [activeTab, setActiveTab] = React.useState<UvaSimulationMode>(state.simulationMode)

  function handleSimTypeChange(event: React.SyntheticEvent, newMode: UvaSimulationMode) {
    localStorage.setItem(appContext.simulationModeKey, newMode)

    const changeSet: Partial<UvaSimulationParams> = {}

    setActiveTab(newMode)
    localStorage.setItem(appContext.simulationModeKey, newMode)
    setState({
      ...state,
      ...changeSet,
      simulationMode: newMode,
    })
  }

  function updateCurrSimData(data: SimulationRecord[]) {
    currSimDataRef.current = data
    setCurrSimData(data)
  }

  function calcCleanAirDeviceMode(
    params: CalcCleanAirDeviceModeParams,
  ): Promise<CalcCleanAirDeviceModeOutput> {
    return PssqClient.calcCleanAirDeviceMode(params)
  }

  async function runScenarioBuilderSimulation(scenario: UvaScenario): Promise<void> {
    if (
      currSimDataRef &&
      currSimDataRef.current &&
      scenario.fixedParams &&
      scenario.fixedParams.infectionRate &&
      scenario.fixedParams.maxQuantaDensity &&
      scenario.fixedParams.pathogenId &&
      scenario.fixedParams.roomId &&
      scenario.scenarioEvents &&
      scenario.scenarioEvents.length > 0
    ) {
      updateCurrSimData([])

      scenario.scenarioEvents.sort((se1: UvaScenarioEvent, se2: UvaScenarioEvent) =>
        se1.order > se2.order ? 1 : -1,
      )

      for (const scenarioEvent of scenario.scenarioEvents) {
        for (let t = 0; t < scenarioEvent.duration; t++) {
          const activity = appContext.activities.find((a) => a.id === scenarioEvent.activityId)

          if (activity && state.fixedParams) {
            const prev = currSimDataRef?.current?.length
              ? currSimDataRef.current[currSimDataRef.current.length - 1]
              : null

            const lastQuantaDensity = prev ? prev.output.quantaDensity : 0

            const ccadmInput: CalcCleanAirDeviceModeParams = {
              roomId: state.fixedParams.roomId as string,
              pathogenId: state.fixedParams.pathogenId as string,
              activityId: scenarioEvent.activityId as string,
              occupancy: scenarioEvent.occupants as number,
              timeDurationMinutes: 1,
              previousQuantaDensity: lastQuantaDensity,
              targetQuantaDensity: state.fixedParams.maxQuantaDensity as number,
              assumedInfectionRate: (state.fixedParams.infectionRate as number) / 100,
              remediationDeviceId: DEVICE_ID,
              uvaRemediationDevices: [
                {
                  id: DEVICE_ID,
                  currentCADR: prev ? prev.output.deviceInstructions.deviceCADR : 0,
                  // constant
                  maxCADR: MAX_DEVICE_CADR,
                  name: DEVICE_ID,
                },
              ],
            }
            const ccadmOutput = await calcCleanAirDeviceMode(ccadmInput)
            updateCurrSimData([
              ...(currSimDataRef.current ?? []),
              {
                input: {
                  occupancy: scenarioEvent.occupants,
                  infectionRate: state.fixedParams.infectionRate as number,
                  activityId: activity.id,
                  activityEnhancementRate: activity.enhancementRate,
                },
                output: ccadmOutput,
              },
            ])
            // })
          } else {
            throw new Error('No activity for simulation')
          }
        }
      }
    } else {
      throw new Error('Missing required simulation param')
    }
  }

  /**
   * This method will pull 1 next simulation record from pssq backend
   */
  function fetchNextSimulationRecord() {
    if (state.fixedParams && state.fixedParams.pathogenId && state.fixedParams.roomId) {
      const prev = currSimDataRef.current.length
        ? currSimDataRef.current[currSimDataRef.current.length - 1]
        : null
      const activity = appContext.activities.find(
        (a) => a.id === variableParamsRef.current.activityId,
      )
      const occupancy = variableParamsRef.current.occupancy

      if (activity) {
        updateVariableParams({
          ...variableParamsRef.current,
          nextFetchTimeoutId: 0,
        })

        return PssqClient.calcCleanAirDeviceMode({
          activityId: activity.id,
          pathogenId: state.fixedParams.pathogenId,
          roomId: state.fixedParams.roomId,
          previousQuantaDensity: prev ? prev.output.quantaDensity : 0,
          // UI value or room default
          occupancy: occupancy,
          // UI value
          assumedInfectionRate: (state.fixedParams.infectionRate as number) / 100 ?? 0,
          // UI value
          targetQuantaDensity: state.fixedParams.maxQuantaDensity as number,
          timeDurationMinutes: 1,
          remediationDeviceId: DEVICE_ID,
          uvaRemediationDevices: [
            {
              id: DEVICE_ID,
              currentCADR: prev ? prev.output.deviceInstructions.deviceCADR : 0,
              // constant
              maxCADR: MAX_DEVICE_CADR,
              name: DEVICE_ID,
            },
          ],
        }).then((simRes) => {
          updateCurrSimData([
            ...(currSimDataRef.current ?? []),
            {
              input: {
                occupancy: occupancy,
                infectionRate: state.fixedParams?.infectionRate as number,
                activityId: activity.id,
                activityEnhancementRate: activity.enhancementRate,
              },
              output: simRes,
            },
          ])
          // schedule next fetching
          if (variableParamsRef.current.nextSimulationExecutionTime) {
            const timeToNextExecution =
              variableParamsRef.current.nextSimulationExecutionTime > Date.now()
                ? variableParamsRef.current.nextSimulationExecutionTime - Date.now()
                : 0
            updateVariableParams({
              ...variableParamsRef.current,
              nextSimulationExecutionTime:
                Date.now() + timeToNextExecution + variableParamsRef.current.simulationSpeed,
              nextFetchTimeoutId: window.setTimeout(
                () => fetchNextSimulationRecord(),
                timeToNextExecution,
              ),
            })
          }
        })
      } else {
        return Promise.reject('Invalid state, unknown activity')
      }
    }

    return Promise.reject('Invalid state, simulation params not set')
  }
  // eslint-disable-next-line func-style
  const onPathogenEdited: PssqSimulatorFilterPanelProps['pathogenEdited'] = (data) => {
    if (data.id) {
      // update existing pathogen
      PssqClient.updatePathogen(data as UvaPathogen).then(() => {
        if (appContext) {
          const pathogenIdx = appContext.pathogens.findIndex((p) => p.id === data.id)
          const updatedPathogenArr = [...appContext.pathogens]
          if (pathogenIdx >= 0) {
            updatedPathogenArr[pathogenIdx] = data as UvaPathogen
          }
          appContext?.patchContext({
            pathogens: updatedPathogenArr,
          })
        }
      })
    } else {
      // insert a new pathogen
      PssqClient.insertPathogen(data as UvaPathogen).then((inserted) => {
        if (appContext) {
          appContext.patchContext({
            pathogens: [...appContext.pathogens, inserted],
          })
        }
      })
    }
  }

  // eslint-disable-next-line func-style
  const onRoomEdited: PssqSimulatorFilterPanelProps['roomEdited'] = (data) => {
    if (data.id) {
      // update existing room
      PssqClient.updateRoom(data as UvaRoom).then(() => {
        if (appContext) {
          const roomIdx = appContext.rooms.findIndex((r) => r.id === data.id)
          const updatedRoomArr = [...appContext.rooms]
          if (roomIdx >= 0) {
            updatedRoomArr[roomIdx] = data as UvaRoom
          }
          appContext?.patchContext({
            rooms: updatedRoomArr,
          })
        }
      })
    } else {
      // insert a new room
      PssqClient.insertRoom(data as UvaRoom).then((inserted) => {
        if (appContext) {
          appContext.patchContext({
            rooms: [...appContext.rooms, inserted],
          })
        }
      })
    }
  }

  function resetSimulation(data: Required<UvaFixedSimulationParams>) {
    updateFixedParams(data)
    updateVariableParams({
      occupancy: 0,
      activityId: variableParams.activityId,
      nextSimulationExecutionTime: 0,
      simulationSpeed: variableParamsRef.current.simulationSpeed,
      nextFetchTimeoutId: 0,
    })
    updateCurrSimData([])
    setState((prev) => ({
      ...prev,
    }))
  }

  function pauseSimulation() {
    updateVariableParams({
      ...variableParamsRef.current,
      nextSimulationExecutionTime: 0,
    })
  }

  function startSimulation() {
    if (!variableParamsRef.current.activityId) {
      // Do not start simulation if activity not set -> happens when user changes speed before he chooses activity
      return
    }
    updateVariableParams({
      ...variableParamsRef.current,
      nextSimulationExecutionTime: Date.now() + variableParamsRef.current.simulationSpeed,
      nextFetchTimeoutId: 0,
    })
    fetchNextSimulationRecord()
  }

  function toggleSimulation() {
    if (variableParamsRef.current.nextSimulationExecutionTime) {
      pauseSimulation()
    } else {
      startSimulation()
    }
  }

  function changeSimulationSpeed() {
    if (variableParamsRef.current.nextSimulationExecutionTime) {
      // simulation is already started

      if (variableParamsRef.current.nextFetchTimeoutId) {
        // there is no network request in flight
        window.clearTimeout(variableParamsRef.current.nextFetchTimeoutId)
        startSimulation()
      } else {
        // there is a network request in progress right now -> just update nextSimulationExecutionTime
        updateVariableParams({
          ...variableParamsRef.current,
          nextSimulationExecutionTime: Date.now() + variableParamsRef.current.simulationSpeed,
        })
      }
    } else {
      startSimulation()
    }
    localStorage.setItem(
      appContext.simulationSpeedKey,
      variableParamsRef.current.simulationSpeed.toString(),
    )
  }

  function exportData() {
    let csvString = ''
    if (state.fixedParams) {
      const room = appContext.rooms.find((r) => r.id === state.fixedParams?.roomId)
      const pathogen = appContext.pathogens.find((p) => p.id === state.fixedParams?.pathogenId)

      // room info
      csvString +=
        'Room,' +
        Object.entries(room ?? {})
          .map(([name, value]) => `${name}=${value}`)
          .join(',') +
        '\n'
      // pathogen info
      csvString +=
        'Pathogen,' +
        Object.entries(pathogen ?? {})
          .map(([name, value]) => `${name}=${value}`)
          .join(',') +
        '\n'
      // data header
      csvString +=
        'Duration,Occupancy,Infection Rate,activityEnhancementRate,quantaDensity,deviceCADR,requiredCADR,' +
        'overloaded,occupancyOverloadThreshold,airVolume,quantaExhalationRatePerPerson,targetQuantaDensity,' +
        'decay,deposition,currentCADR,minimumDesiredCADR\n'
      // data
      csvString += currSimData
        .map(
          (rec, idx) =>
            `${idx},${rec.input.occupancy},${rec.input.infectionRate},${rec.input.activityEnhancementRate},` +
            `${rec.output.quantaDensity},${rec.output.deviceInstructions.deviceCADR},${rec.output.deviceInstructions.requiredCADR},` +
            `${rec.output.deviceInstructions.overloaded},${rec.output.deviceInstructions.occupancyOverloadThreshold},` +
            `${rec.output.stats.airVolume},${rec.output.stats.quantaExhalationRatePerPerson},${rec.output.stats.targetQuantaDensity},` +
            `${rec.output.stats.environmentalCADR.decay},${rec.output.stats.environmentalCADR.deposition},` +
            `${rec.output.stats.remediationDeviceCADR[0].currentCADR},${rec.output.stats.minimumDesiredCADR}`,
        )
        .join('\n')

      const blob = new Blob([csvString], { type: 'text/csv' })
      const name = `simulation_${format(new Date(), 'yyyyMMdd_HHmmss')}.csv`
      FileSaver.saveAs(blob, name)
    } else {
      throw new Error('No fixed params for scenario')
    }
  }

  function newSimulation() {
    pauseSimulation()
    setFixedParamsPanelVisibility(true)
    updateCurrSimData([])
  }

  function maxDisplayedQuantaLimit() {
    if (currSimData && currSimData.length < 0) {
      return DEFAULT_MAX_QUANTA
    }
    let maxQuantaValue = DEFAULT_MAX_QUANTA + 1 // default to value of 4, so we never show less than this
    currSimData.forEach((data) => {
      if (!data.output) {
        return
      }

      const higherQuantaValue = Math.max(
        data.output.quantaDensity,
        data.output.stats.targetQuantaDensity,
      )
      if (higherQuantaValue > maxQuantaValue) {
        maxQuantaValue = Math.round(higherQuantaValue * DEFAULT_QUANTA_MULTIPLIER)
      }
    })
    return maxQuantaValue
  }

  return (
    <div id='UvaPssqSimulatorPage'>
      <h1 className='uva-page-title'>PSSQ Simulator</h1>

      <Grid container spacing={2} id='uvaSimulationResults'>
        <Grid item xs={12} md={4} xl={3}>
          <TabContext value={activeTab}>
            <Paper elevation={2}>
              <Box>
                {appContext.loading ? (
                  <Skeleton variant='rectangular' className='uva-skeleton-tabs' />
                ) : (
                  <TabList
                    onChange={handleSimTypeChange}
                    aria-label='Simulation Selector'
                    id='UvaSimulationTabList'
                    variant='scrollable'
                  >
                    <Tab label='Interactive Simulation' value='InteractiveSimulation' />
                    <Tab label='Scenario Builder' value='ScenarioBuilder' />
                  </TabList>
                )}
              </Box>
              <TabPanel value='InteractiveSimulation'>
                <Paper elevation={4} sx={{ padding: 2 }}>
                  {appContext.loading ? (
                    <Skeleton
                      variant='rectangular'
                      className='uva-skeleton uva-skeleton-paragraph'
                    />
                  ) : (
                    <p>
                      <strong>Interactive Simulations</strong> allow you to set a series of fixed
                      parameters for a simulation (pathogen, room, infection rate, max quanta
                      density) then start an interactive simulation session where you can change
                      occupancy and activity during the simulation.
                    </p>
                  )}

                  {fixedParamsPanelVisible && (
                    <FixedParamsPanel
                      roomEdited={onRoomEdited}
                      pathogenEdited={onPathogenEdited}
                      updateFixedParams={updateFixedParams}
                      maxQuantaDensity={state.fixedParams?.maxQuantaDensity}
                      infectionRate={state.fixedParams?.infectionRate}
                      pathogenId={state.fixedParams?.pathogenId}
                      roomId={state.fixedParams?.roomId}
                      result={resetSimulation}
                    />
                  )}
                  {!fixedParamsPanelVisible && (
                    <>
                      <VariableParamsPanel
                        activityId={variableParams.activityId}
                        export={exportData}
                        toggle={toggleSimulation}
                        change={(activityId, occupancy) =>
                          updateVariableParams({
                            ...variableParamsRef.current,
                            activityId,
                            occupancy,
                          })
                        }
                        maxOccupants={
                          appContext.rooms.find((r) => r.id === state.fixedParams?.roomId)
                            ?.maxOccupancy ?? 0
                        }
                        simulationRuns={!!variableParamsRef.current.nextSimulationExecutionTime}
                      />
                      <FixedParamsTable
                        newSimulation={newSimulation}
                        roomId={state.fixedParams?.roomId}
                        pathogenId={state.fixedParams?.pathogenId}
                        maxQuantaDensity={state.fixedParams?.maxQuantaDensity}
                        infectionRate={state.fixedParams?.infectionRate}
                        key='FixedParamsTable'
                      />
                    </>
                  )}
                </Paper>
              </TabPanel>
              <TabPanel value='ScenarioBuilder'>
                <Paper elevation={4} sx={{ padding: 2 }}>
                  {appContext.loading ? (
                    <Skeleton
                      variant='rectangular'
                      className='uva-skeleton uva-skeleton-paragraph'
                    />
                  ) : (
                    <p>
                      The <strong>Scenario Builder</strong> allows you to create and save a
                      simulation that consists of fixed values for pathogen, room, infection rate,
                      and max quanta density, then create a series of &ldquo;Events&rdquo; that can
                      be played back in the future.
                    </p>
                  )}

                  {fixedParamsPanelVisible && (
                    <FixedParamsPanel
                      roomEdited={onRoomEdited}
                      pathogenEdited={onPathogenEdited}
                      updateFixedParams={updateFixedParams}
                      maxQuantaDensity={state.fixedParams?.maxQuantaDensity}
                      infectionRate={state.fixedParams?.infectionRate}
                      pathogenId={state.fixedParams?.pathogenId}
                      roomId={state.fixedParams?.roomId}
                      result={resetSimulation}
                    />
                  )}

                  {!fixedParamsPanelVisible && (
                    <ScenarioBuilder
                      fixedParams={state.fixedParams}
                      updateFixedParams={updateFixedParams}
                      runSimulation={runScenarioBuilderSimulation}
                      newSimulation={newSimulation}
                    />
                  )}
                </Paper>
              </TabPanel>
            </Paper>
          </TabContext>
        </Grid>
        <Grid item xs={12} md={8} xl={9}>
          <Paper elevation={2} className='uva-simulation-container'>
            <SimulationResultsGraph
              results={
                state.loading
                  ? undefined
                  : currSimData.map((rec, idx) => ({
                      id: idx,
                      quanta: rec.output.quantaDensity,
                      occupants: rec.input.occupancy,
                      targetQuantaDensity: rec.output.stats.targetQuantaDensity,
                      activityRate: rec.input.activityEnhancementRate,
                      deviceCADR: rec.output.deviceInstructions.deviceCADR,
                    }))
              }
              limits={
                state.loading
                  ? undefined
                  : {
                      quantaMax: maxDisplayedQuantaLimit(),
                      occupancyMax:
                        appContext.rooms.find((room) => room.id === state.fixedParams?.roomId)
                          ?.maxOccupancy || DEFAULT_MAX_OCCUPANCY,
                      deviceCadrMax: MAX_DEVICE_CADR, // todo: pass this in from a device at some point...
                    }
              }
            ></SimulationResultsGraph>
            {state.simulationMode === 'InteractiveSimulation' && (
              <div className='simulation-speed-wrapper'>
                {appContext.loading ? (
                  <Skeleton className='uva-skeleton' variant='rectangular' />
                ) : (
                  <>
                    <InputLabel id='speed-label'>Simulation Speed</InputLabel>
                    <Slider
                      aria-labelledby='speed-label'
                      step={1}
                      min={0}
                      max={SPEED_STEPS}
                      valueLabelDisplay='auto'
                      marks={SPEED_MARKS}
                      value={scaleDownSpeed(variableParamsRef.current.simulationSpeed)}
                      scale={scaleUpSpeed}
                      valueLabelFormat={formatSimulationSpeed}
                      onChange={(e, newSpeed) => {
                        const scaledUpSpeed = scaleUpSpeed(newSpeed as number)
                        if (scaledUpSpeed !== variableParamsRef.current.simulationSpeed) {
                          updateVariableParams({
                            ...variableParamsRef.current,
                            simulationSpeed: scaledUpSpeed,
                          })
                        }
                      }}
                      onChangeCommitted={changeSimulationSpeed}
                    />
                  </>
                )}
              </div>
            )}
            <Card>
              <SimulationResultsTable
                results={
                  state.loading
                    ? undefined
                    : currSimData
                        .map((rec, idx) => ({
                          id: idx,
                          quanta: rec.output.quantaDensity,
                          occupants: rec.input.occupancy,
                          activityRate: rec.input.activityEnhancementRate,
                          deviceCADR: rec.output.deviceInstructions.deviceCADR,
                        }))
                        .reverse()
                }
              />
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default PssqSimulatorPage
