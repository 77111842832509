import { Alert, FormHelperText, InputLabel, Link, MenuItem, Select, Snackbar } from '@mui/material'
import React, { useContext, useState } from 'react'
import { AppContext } from '../../app/App.context'
import RoomEditDialog from '../../pages/pssq-simulator/components/room-edit-dialog/room-edit-dialog.component'
import { UvaRoom } from '../../services/api.models'
import PssqClient from '../../services/pssq.service'

export interface RoomPickerProps {
  // currently selected value of room (must be id of a room in appContext)
  value: string
  // handler for onChange events
  onChange?: (newValue: string) => void
  // handler for onBlur events
  onBlur?: (event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  // error message
  error?: {
    message: string
  }
  // if true then buttons for creating/editing rooms will be shown
  enableEditing?: boolean
  // label for the control
  label?: string
  // label for edit room button
  editRoomLabel?: string
  // label for new room button
  createRoomLabel?: boolean
  // if true then room picker is disabled/read-only
  disabled?: boolean

  rooms: UvaRoom[]
}

// eslint-disable-next-line func-style
export const RoomPicker: React.FC<RoomPickerProps> = ({
  label = 'Room',
  editRoomLabel = 'Edit Room',
  createRoomLabel = 'New Room',
  value,
  onChange,
  onBlur,
  enableEditing = true,
  error,
  disabled,
  rooms
}) => {
  const appContext = useContext(AppContext)
  const [state, setState] = useState<{
    createDialogOpen: boolean
    editDialogOpen: boolean
    toastError: string
  }>({
    createDialogOpen: false,
    editDialogOpen: false,
    toastError: '',
  })

  function onEditRoom() {
    setState({ ...state, editDialogOpen: true })
  }
  function onCreateRoom() {
    setState({ ...state, createDialogOpen: true })
  }
  async function onEditDialogClose(room?: Partial<UvaRoom>): Promise<void> {
    if (!room) {
      // user cancelled dialog
      setState({ ...state, editDialogOpen: false })
      return
    }
    let updated
    try {
      updated = await PssqClient.updateRoom(room as UvaRoom)
      if (appContext && room.id) {
        const roomIdx = rooms.findIndex((r) => r.id === room.id)
        const updatedRoomArr = [...rooms]
        if (roomIdx >= 0) {
          updatedRoomArr[roomIdx] = room as UvaRoom
        }
        appContext.patchContext({
          rooms: updatedRoomArr,
        })
        setState({ ...state, editDialogOpen: false })
      }
    } catch (e) {
      console.error(e)
      setState({ ...state, toastError: 'Error editing the room' })
    }
    return updated
  }

  async function onCreateDialogClose(room?: Partial<UvaRoom>): Promise<void> {
    if (!room) {
      // user cancelled dialog
      setState({ ...state, createDialogOpen: false })
      return
    }

    let inserted

    try {
      inserted = await PssqClient.insertRoom(room as UvaRoom)
      if (appContext) {
        appContext.patchContext({
          rooms: [...rooms, inserted],
        })
        setState({ ...state, createDialogOpen: false })
        onChange && onChange(inserted.id)
      }
    } catch (error) {
      console.error('Error creating a new room', error)
      setState({ ...state, toastError: 'Error creating a new room' })
    }
  }

  function onToastClose() {
    setState({ ...state, toastError: '' })
  }

  return (
    <>
      <InputLabel id='room-label' error={!!error}>
        {label}
      </InputLabel>
      <Select
        id='room-filter'
        labelId='room-label'
        label={label}
        className='uva-form-control'
        variant='filled'
        onChange={(event) => onChange && onChange(event.target.value)}
        onBlur={(event) => onBlur && onBlur(event)}
        error={!!error}
        value={value}
        disabled={disabled}
      >
        <MenuItem value='' key='Room-undefined'>
          Select One
        </MenuItem>
        {rooms.map((room) => (
          <MenuItem value={room.id} key={`Room${room.id}`}>
            {room.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      {enableEditing && (
        <div className='uva-entity-picker-actions'>
          {value ? (
            <Link href='#' onClick={onEditRoom}>
              {' '}
              {editRoomLabel}{' '}
            </Link>
          ) : (
            <span></span>
          )}
          <Link href='#' onClick={onCreateRoom}>
            {' '}
            {createRoomLabel}{' '}
          </Link>
        </div>
      )}
      {state.editDialogOpen && (
        <RoomEditDialog
          initialRoomData={appContext?.rooms.find((room) => room.id === value)}
          onClose={onEditDialogClose}
        />
      )}
      {state.createDialogOpen && <RoomEditDialog onClose={onCreateDialogClose} />}
      {/* FIXME: Errors should appear in the form that generated them. And should never appear on lower-left corner of screen. */}
      <Snackbar open={!!state.toastError} autoHideDuration={6000} onClose={onToastClose}>
        <Alert onClose={onToastClose} severity='error' sx={{ width: '100%' }}>
          {state.toastError}
        </Alert>
      </Snackbar>
    </>
  )
}
